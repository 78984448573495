import { IconButton, Tooltip } from '@mui/material';
import Button, { TYPES } from 'components/common/Button';
import TooltipComponent from 'components/common/Tooltip';
import { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { BiInfoCircle } from 'react-icons/bi';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { SCENARIO_TYPES } from 'utils/constants';
import { TQueryDetail } from 'utils/model';

type Tprops = {
    query: TQueryDetail;
    onEdit: Function;
    onDelete: Function;
    index: number;
    type: String;
    edit: boolean;
};
const QueryList = (props: Tprops) => {
    const { query, onEdit, onDelete, index, type, edit } = props;
    const [selectedIndex,setIndex] = useState(-1);
    useEffect(()=>{
        setIndex(-1)
    },[query])
    return (
        <div>
            {index == 0 && (
                <div className="heading-container-query">
                    <h5 className="name-column">NAME</h5>
                    <div className="right-seperator" />
                    <h5 className="date-column">TYPE</h5>
                    <div className="right-seperator" />
                    <h5 className="date-column">FETCH QUERY</h5>
                    <div className="right-seperator" />
                    {type == SCENARIO_TYPES.FIX && (
                        <>
                            <h5 className="date-column">UPDATE QUERY</h5>
                            <div className="right-seperator" />
                        </>
                    )}
                    <h5 className="actions-column">ACTIONS</h5>
                </div>
            )}

            <div className={`${edit && selectedIndex==index?'background-highlight':''} list-item-query`} >
                <div className="name-column">{query.name}</div>
                <div className="date-column">{query.type}</div>
                <div className="date-column" style={{marginTop:'-5px'}}>
                    Query <TooltipComponent title={query.fetchQuery} icon={<BiInfoCircle size={13} color={edit && selectedIndex==index?'#e0e0e0':'#6b6a68'}/>}/>
                </div>
                {type == SCENARIO_TYPES.FIX && (
                    <div className="date-column">{query.updateQuery}</div>
                )}
                <div className="actions-column">
                    {onDelete ? (
                        <div className="ctrl-btn delete" onClick={() => onDelete(index)}>
                            <AiOutlineDelete size={20}  color={edit && selectedIndex==index?'#e0e0e0':'#6b6a68'}title={'Delete'}/>
                        </div>
                    ) : null}
                    {onEdit ? (
                        <div
                            className="ctrl-btn"
                            onClick={() => {
                                onEdit(index);
                                setIndex(index)
                            }}>
                            <AiOutlineEdit size={20} color={edit && selectedIndex==index?'#e0e0e0':'#6b6a68'} title={'Edit'} />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default QueryList;
