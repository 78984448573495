import DiffComponent from "components/common/DiffComponent";

type TProps = {
    checkItem: any,
    scenarioId: number
}

const FixResponseCard = (props: TProps) => {
    const { checkItem, scenarioId } = props;


    return <div className="fix-diff-container">
        <h2>{checkItem.name}</h2>
        {checkItem.dataLoader
            ? <div>Loading...</div>
            : <DiffComponent
                previousData={checkItem.previousData}
                currentData={checkItem.currentData}
                idField={'id'}
            />}
    </div>
}

export default FixResponseCard;