import { MdArrowDropDown, MdDeleteOutline, MdOutlineRefresh } from 'react-icons/md';
import Button, { TYPES } from 'components/common/Button';
import Select, { FIELD_TYPES as SELECT_FIELD_TYPES } from 'components/common/Select';
import CreateDashboard from './CreateDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { setReloadRequired, setSelectedSnapshot } from 'store/reducers/consoleDashboardSlice';
import { SNAPSHOT_DB_LATEST } from 'utils/constants';
import {
    getConsoleDashboardWrapper,
    getConsoleDashboardSnapshotWrapper,
    getConsoleDashboardDeleteWrapper,
    getConsoleDashboardonRefresh
} from 'store/actions';
import ConfirmDashboardDelete from './ConfirmDashboardDelete';
import { useEffect, useState } from 'react';
import EditDashboard from './EditDashboard';
import { getRole } from 'utils/helperFunctions';
import DashboardSelection from 'components/common/Navigator/DashboardSelection';
import ButtonDropdown from 'components/common/ButtonDropdown';

type TProps = {
    selectedDashboard: any;
    onDashboardChange: Function;
};
const HeaderSection = (props: TProps) => {
    const { selectedDashboard } = props;
    const currentSnapshotList = useSelector(
        (state: any) => state.consoleDashboard.currentSnapshotList
    );
    const selectedsnapshot = useSelector((state: any) => state.consoleDashboard.selectedsnapshot);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const dispatch = useDispatch();
    const userRole = getRole();

    const onSnapshotChange = async (value: any) => {
        dispatch(setSelectedSnapshot(value));
        if (value.executedAt === SNAPSHOT_DB_LATEST) {
            dispatch(setReloadRequired(true));
        } else {
            await dispatch(getConsoleDashboardSnapshotWrapper({ dashboardRunId: value.id }));
        }
    };

    const onDeleteExecution = async () => {
        await dispatch(
            getConsoleDashboardDeleteWrapper({ dashboardId: selectedDashboard.id, refresh: false })
        );
        setShowDeleteDialog(false);
    };

    const refresh = async () => {
        await dispatch(
            getConsoleDashboardonRefresh({ dashboardId: selectedDashboard.id})
        );
    };


    return (
        
            <div className="header">
                
                    <div className="left-component">
                    {selectedDashboard && userRole !== 'VIEWER' ? (<> <div className="selection-div">
                            <DashboardSelection />
                        </div>
                        <div className="action-button-container">
                            <ConfirmDashboardDelete
                                name={selectedDashboard.name}
                                show={showDeleteDialog}
                                onClose={() => setShowDeleteDialog(false)}
                                onExecute={onDeleteExecution}
                            />
                            <EditDashboard />
                            <Button
                                buttonText={<MdDeleteOutline color="#a70909c9" />}
                                title={'Delete'}
                                onClick={() => setShowDeleteDialog(true)}
                                type={TYPES.ICON}
                                width={'35px'}
                            />
                            {userRole !== 'VIEWER' && (
                                <div>
                                    <CreateDashboard />
                                </div>
                            )}
                        </div>
                    </>):null}
                    {/* ${userRole !== 'VIEWER'?'viewer-snapshot-div':'viewer-snapshot-div'} */}
                    <div className={`${userRole !== 'VIEWER'?'':'sub-div-snapshot'} viewer-snapshot-div sub-header`}>
                    <ButtonDropdown
                        menuItems={currentSnapshotList}
                        selectedMenuItems={selectedsnapshot}
                        handleSelect={(e: any) => onSnapshotChange(e)}
                        label="executedAt"
                        buttonLabel={
                            <div className='snapshot-label'>
                                {selectedsnapshot?.executedAt}
                                <MdArrowDropDown size={22} />
                            </div>
                        }
                    />
                     <Button
                            buttonText={<MdOutlineRefresh color="#3b3b3b" size={21} />}
                            title={'Refresh'}
                            onClick={() => {
                                refresh();
                            }}
                            type={TYPES.ICON}
                            width={'35px'}
                            classList={['refresh-button']}
                        />
                </div>
            </div>
            </div>
        
    );
};

export default HeaderSection;
