import './App.scss';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import Login from 'containers/Login';
import PageNotFound from 'containers/PageNotFound';
import Console from 'containers/Console';
import components from 'containers/Console/NavConfig';
import ExceptioDetails from 'components/console/ExceptionDetails';
import ExecuteInvestigation from 'components/console/ExecuteInvestigation';
import ExecuteFix from 'components/console/ExecuteFix';
import { Fragment, useEffect } from 'react';
import TrailDiff from 'components/console/TrailDiffComponent';
import Loader from 'components/common/Loader';
import { useSelector } from 'react-redux';
import { TUserDetails } from 'utils/model';
import PrivateRoute from './PrivateRoute';
import { getAuth } from 'firebase/auth';
import { updateAuthSessionDetails } from 'store/actions/authentication';
import { setAuthStatus, updateAuthState } from 'store/reducers/AuthenticationSlice';
import { useDispatch } from 'react-redux';
import QueryDetail from 'components/console/QueryDetail';


const App = () => {
    const showLoader = useSelector((state: any) => state.loader.show);
    const authDetails: TUserDetails = useSelector((state: any) => state.auth);
    const auth = getAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        setInterval(() => {
            auth.onAuthStateChanged(async (user: any) => {
                const updatedPayload = {
                    ...authDetails,
                    authToken: await user.getIdToken(),
                    uid: user.uid,
                    isAuthenticated: true,
                    email: user.email
                };
                updateAuthSessionDetails(updatedPayload);
                dispatch(setAuthStatus(updatedPayload));
                dispatch(updateAuthState(updatedPayload));
            });
        }, 600000);
    });

  
    return (
        <div className="App">
            <Loader show={showLoader} />
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    {/* TODO: Enable dashboard and issues page here */}
                    {/* <Route path="dashboard" element={<Dashboard />} />
                    <Route path="issues" element={<Issues />} /> */}
                    <Route path="console" element={<Console />}>
                        {
                            <Fragment>
                                {components.map((item) => {
                                    return (
                                        <Route
                                            key={item.hash}
                                            path={item.hash}
                                            element={<PrivateRoute>{item.component}</PrivateRoute>}
                                        />
                                    );
                                })}
                            </Fragment>
                        }
                        <Route
                            path="exception-details/:id/*"
                            element={
                                <PrivateRoute>
                                    <ExceptioDetails />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="exception-detail/:scenarioId/:queryId"
                            element={
                                <PrivateRoute>
                                    <QueryDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="execute-investigation/:id"
                            element={
                                <PrivateRoute>
                                    <ExecuteInvestigation />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="execute-fix/:id"
                            element={
                                <PrivateRoute>
                                    <ExecuteFix />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="trail/diff/:id"
                            element={
                                <PrivateRoute>
                                    <TrailDiff />
                                </PrivateRoute>
                            }
                        />
                        <Route index element={<Navigate to={components[0].hash} />} />
                    </Route>
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Router>
        </div>
    );
};

export default App;
