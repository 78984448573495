import Button, { TYPES } from "components/common/Button";
import { TDashboardData, TDBQueryData, TDBScenarioData } from "utils/model";

type TProps = {
    formData: TDashboardData;
    onSubmit: Function;
    onBack: Function
    error: string;
    isUpdate?:boolean;
}
const Confirmation = (props: TProps) => {
    const { formData, onSubmit, onBack, error,isUpdate } = props;

    return <div className={`stage-3-confirm ${error?'has-error':null}`}>
       {error? <div className="error">{error}</div>:null}
        <div className="summary-section">
            <div className="data-row">
                <div className="label">{"Dashboard Name"}</div>
                <div className="value">{formData.name}</div>
            </div>
            <div className="data-row">
                <div className="label">{"Description"}</div>
                <div className="value">{formData.description}</div>
            </div>
            <div className="data-row">
                <div className="label">{"Schedule Hours"}</div>
                <div className="value">{formData.scheduleHours}</div>
            </div>
            <div className="data-row">
                <div className="label">{"proxy Id"}</div>
                <div className="value">{formData.proxyId}</div>
            </div>
            <div className="data-row">
                <div className="label">{"Scenarios"}</div>
                <div className="value">{
                    formData.scenarios.map((scenario: TDBScenarioData) => {
                        return <div className="scenario-section">
                            <div className="scenario-title">{scenario.title}</div>
                            <div className="query-list">
                                <div className="query-section head">
                                    <div className="query-name">QUERY</div>
                                    <div>DATASOURCE</div>
                                </div>
                                {
                                    scenario.queries.map((query: any,index) => {
                                        return  <div className="query-section">
                                                <div className="query-name">{query.name}</div>
                                                <div>{query.datasourceName}</div>
                                                </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }</div>
            </div>
        </div>
        <div className="button-container">
            <Button
                buttonText={isUpdate?'Save Dashboard':'Create Dashboard'}
                onClick={()=>onSubmit()}
                classList={['add-update-btn']}
            />
            <Button
                buttonText={'Back'}
                onClick={() => onBack(2)}
                classList={['add-update-btn']}
                type={TYPES.PRIMARY_OUTLINED}
            />
        </div>
    </div>
}

export default Confirmation;