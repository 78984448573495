import TooltipComponent from "components/common/Tooltip";
import { BiInfoCircle } from "react-icons/bi";
import './style.scss'

type TProps={
    values:any[];
    width:string;
}

const QueryDetailsTable=(props:TProps)=>{
    const {values, width} = props;

    return(<div className='table-query-display' style={{width:width}}>
        <table>
                <thead className="table-query-display-thead">
                    <th>NAME</th>
                    <th>TYPE</th>
                    <th>FETCH QUERY</th>
                    <th>UPDATE QUERY</th>
                    <th>FETCH VARIABLE</th>
                    <th>UPDATE VARIABLE</th>
                    <th>METHOD TYPE</th>
                    <th>RESPONSE PATH</th>
                    <th>QUERY SUMMARY SQL</th>
                    <th>RESPONSE TYPE</th>
                    <th>PAYLOAD</th>
                    <th>DYNAMIC</th>
                </thead>
                <tbody>
                    {
                        values.map((i:any)=>{
                        
                            let dynamic =<div>Start Period - {i['startPeriod']} <br/>
                                End Period - {i['endPeriod']} <br/>
                                Start Period Unit - {i['startPeriodUnit']}<br/>
                                End Period Unit - {i['endPeriodUnit']}<br/>
                                Period Start Key - {i['periodStartKey']}<br/>
                                Period End Key - {i['periodEndKey']}<br/>
                                TimeFormat- {i['timeFormat']} <br/>
                                Start Time- {i['startTime']} <br/>
                                Start Time Key- {i['startTimeKey']} <br/>
                                Start TimeFormat- {i['startTimeFormat']} <br/></div>
                            return <tr style={{textAlign:'center'}}>
                                <td>{i['name']}</td>
                                <td>{i['type']}</td> 
                                <td>{i['fetchQuery']? <>Query <TooltipComponent title={i['fetchQuery']} icon={<BiInfoCircle  size={13} color={'#6b6a68'}/>}/></>:'Nil'}</td>
                                <td>{i['updateQuery']? <>Query <TooltipComponent title={i['updateQuery']} icon={<BiInfoCircle  size={13} color={'#6b6a68'}/>}/></>:'Nil'}</td>
                                <td>{i['fetchVariable']||'Nil'}</td>
                                <td>{i['updateVariable']||'Nil'}</td>
                                <td>{i['methodType']||'Nil'}</td>
                                <td>{i['responsePath']?<>Data <TooltipComponent title={i['responsePath']} icon={<BiInfoCircle  size={13} color={'#6b6a68'}/>}/></>:'Nil'}</td>
                                <td>{i['querySummarySQL']?<>Data <TooltipComponent title={i['querySummarySQL']} icon={<BiInfoCircle  size={13} color={'#6b6a68'}/>}/></>:'Nil'}</td>
                                <td>{i['responseType']||'Nil'}</td>
                                <td>{i['payload']?<>Data<TooltipComponent title={i['payload']} icon={<BiInfoCircle  size={12} color={'#6b6a68'}/>}/></>:'Nil'}</td>
                                <td>{i['isDynamic']?<>Data<TooltipComponent title={dynamic} icon={<BiInfoCircle  size={12} color={'#6b6a68'}/>}/></>:'Nil'}</td>
                         
                            </tr>
                        })
                    }</tbody>
            </table>
    </div>)

}

export default QueryDetailsTable