import './style.scss';

type TProps = {
    label: string|JSX.Element,
    onChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement>,
    value: string,
    error?: string,
    required?:boolean
}
const TextField = (props: TProps) => {
    const { label, value, onChangeHandler, error, required } = props;
    return <div className='text-field'>
        {label ? <label className={`label ${required?"required":''}`}>{label}</label> : null}
        <div className='input-wrapper'>
            <textarea value={value} onChange={onChangeHandler} className={error ? "has-error" : ''} required={required} />
            {error ? <small className="error">{error}</small> : null}
        </div>
    </div>
}

export default TextField;