import { createSlice } from '@reduxjs/toolkit';
import { stat } from 'fs';
import _ from 'lodash';
import { DATASOURCE_TYPE, SCENARIO_TYPES } from 'utils/constants';
import { getMapFromArray } from 'utils/helperFunctions';
import { getScenarioDetailsById, saveScenarioUpdates, getReadQueryExecutionData, getWriteQueryExecutionData, getSnapshotData } from '../actions';

const initialState = {
    loaders: {}
};

const reducers = {
    updateFetchVariableData: (state: any, action: any) => {
        const { queryId, fetchVariableData, scenarioId, checkId } = action.payload;
        const CheckIndex = _.findIndex(state[scenarioId].checks, { id: checkId });
        const queryIndex = _.findIndex(state[scenarioId].checks[CheckIndex].queries, { id: queryId });
        state[scenarioId].checks[CheckIndex].queries[queryIndex].fetchVariableData = fetchVariableData;
    },
    updateUpdateVariableData: (state: any, action: any) => {
        const { queryId, updateVariableData, scenarioId, checkId } = action.payload;
        const CheckIndex = _.findIndex(state[scenarioId].checks, { id: checkId });
        const queryIndex = _.findIndex(state[scenarioId].checks[CheckIndex].queries, { id: queryId });
        state[scenarioId].checks[CheckIndex].queries[queryIndex].update.request = updateVariableData.request;
        delete updateVariableData.request;
        state[scenarioId].checks[CheckIndex].queries[queryIndex].update.updateVariableData = updateVariableData;
    },
    setScenarioDetailsLoader: (state: any, action: any) => {
        const { id } = action.payload;
        state.loaders[id] = true;
    },
    resetScenarioDetailsLoader: (state: any, action: any) => {
        const { id } = action.payload;
        state.loaders[id] = false;
    },
    setQueryLoader: (state: any, action: any) => {
        const { scenarioId, checkId } = action.payload;
        const checkIndex = _.findIndex(state[scenarioId].checks, { id: checkId })
        state[scenarioId].checks[checkIndex].dataLoader = true;
        return state;
    },
    resetQueryLoader: (state: any, action: any) => {
        const { scenarioId, checkId } = action.payload;
        const checkIndex = _.findIndex(state[scenarioId].checks, { id: checkId })
        state[scenarioId].checks[checkIndex].dataLoader = false;
        return state;
    },
};

const extraReducers = {
    [getScenarioDetailsById.fulfilled]: (state: any, action: any) => {
        const { data } = action.payload;
        if (data && !state[data.id]) {
            state[data.id] = data
            state.loaders[data.id] = false;
        }
        else if (data && state[data.id]) {
            state[data.id] = {
                ...data, checks: data.checks.map((checkItem: any) => {
                    const check = _.find(state[data.id].checks, { id: checkItem.id });
                    return {
                        ...checkItem, executedQuery: check?.executedString,
                        currentData: check?.currentData,
                        executedTime: check?.executedTime,
                        snapshotType: check?.snapshotType,
                        error: check?.error
                    }

                })
            }
        }
    },
    [getScenarioDetailsById.rejected]: (state: any, action: any) => {
        state.loaders[action.payload.id] = false;
    },
    [getReadQueryExecutionData.fulfilled]: (state: any, action: any) => {
        const { data, scenarioId, checkId, message } = action.payload;
        const checkIndex = _.findIndex(state[scenarioId].checks, { id: checkId });
        state[scenarioId].checks[checkIndex] = {
            ...state[scenarioId].checks[checkIndex],
            executedQuery: data.executedString,
            currentData: data.currentData,
            executedTime: data.executedTime,
            snapshotType: data.snapshotType,
            error: data.error??message
        }
        return state;
    },
    [getSnapshotData.fulfilled]: (state: any, action: any) => {
        const { data, scenarioId, checkId } = action.payload;
        const checkIndex = _.findIndex(state[scenarioId].checks, { id: checkId });
        state[scenarioId].checks[checkIndex] = {
            ...state[scenarioId].checks[checkIndex],
            executedQuery: data.executedString,
            currentData: data.currentData,
            executedTime: data.executedTime,
            snapshotType: data.snapshotType,
            error: data.error
        }
        return state;
    },
    [getWriteQueryExecutionData.fulfilled]: (state: any, action: any) => {
        const { data, scenarioId, checkId } = action.payload;
        const checkIndex = _.findIndex(state[scenarioId].checks, { id: checkId });

        state[scenarioId].checks[checkIndex] = {
            ...state[scenarioId].checks[checkIndex],
            executedQuery: data.executedQuery,
            executedTime: data.executedTime,
            currentData: data.currentData,
            previousData: data.previousData,
            error: data.error
        }
        return state;
    },
    [saveScenarioUpdates.fulfilled]: (state: any, action: any) => {
        const { scenarioId, data, linkedFixScenario, linkedInvestigationScenario, checks } = action.payload;
        let newState = _.cloneDeep(state);
        if (newState[scenarioId]) {
            newState[scenarioId] = {
                ...newState[scenarioId],
                ...(linkedFixScenario ? { linkedFixScenario } : {}),
                ...(linkedInvestigationScenario ? { linkedInvestigationScenario } : {}),
                ...(checks ? {
                    checks: checks.map((checkItem: any) => {
                        return {
                            ...checkItem,
                            currentData: [],
                            previousData: [],
                            executedQuery: '',
                            queries: checkItem.queries?.map((queryItem: any) => {
                                return {
                                    ...queryItem,
                                    fetchVariableData: !_.isEmpty(queryItem.fetchVariables)
                                        ? getMapFromArray(queryItem.fetchVariables.split(','))
                                        : {},
                                    update: {
                                        updateVariableData: !_.isEmpty(queryItem.updateVariables)
                                            ? getMapFromArray(queryItem.updateVariables.split(','))
                                            : {},
                                        ...(queryItem.type === DATASOURCE_TYPE.API && newState[scenarioId].scenarioType === SCENARIO_TYPES.FIX && { request: '' })
                                    }
                                }
                            })
                        };
                    })
                } : {})
            };
        }
        //updating if there is any linked scenarios with given id
        //TODO: FIX below code, linked scenario comes under checks
        newState = _.reduce(newState, (acc, scenario: any) => {
            const newScenario = _.cloneDeep(scenario);
            if (scenario.linkedInvestigationScenario && scenario.linkedInvestigationScenario.id === scenarioId) {
                newScenario.linkedInvestigationScenario = data;
            }
            if (scenario.linkedFixScenario && scenario.linkedFixScenario.id === scenarioId) {
                newScenario.linkedFixScenario = data;
            }
            return { ...acc, [scenario.id]: newScenario };

        }, { loaders: newState.loaders })
        return newState;
    }
};

export const scenarioDetailsSlice = createSlice({
    name: 'scenarioDetails',
    initialState,
    reducers,
    extraReducers
});

export const { updateFetchVariableData, updateUpdateVariableData, setScenarioDetailsLoader, resetScenarioDetailsLoader, setQueryLoader, resetQueryLoader } = scenarioDetailsSlice.actions;

export default scenarioDetailsSlice.reducer;
