import InputField, {FIELD_TYPES} from "components/common/InputField";
import { TRange } from "utils/model";
type TProps = {
    rangeData: TRange;
    onChange: Function;
}
const RangeCollector = (props: TProps) => {
    const { rangeData, onChange } = props;
    
    const onChangeHandler = (key: keyof TRange, value: number)=>{
        onChange({...rangeData, [key]:value});
    }
    return <div>
        <InputField
            value={rangeData.startValue}
            onChangeHandler={(e: any) => onChangeHandler("startValue", e.target.value)}
            type={"number"}
            label="Start value:"
            fieldType={FIELD_TYPES.TYPE_2}
        />
        <InputField
            value={rangeData.endValue}
            onChangeHandler={(e: any) => onChangeHandler("endValue", e.target.value)}
            type={"number"}
            label="End value:"
            fieldType={FIELD_TYPES.TYPE_2}
        />
    </div>
}

export default RangeCollector;