import InfoCard, { CARDTYPES } from 'components/common/InfoCard';
import InputField, { FIELD_TYPES } from 'components/common/InputField';
import Select, { FIELD_TYPES as SELECT_FIELD_TYPES } from 'components/common/Select';
import TextField from 'components/common/TextField';
import { useState } from 'react';
import Switch from 'components/common/Switch';
import { BiInfoCircle } from 'react-icons/bi';
import {
    DATASOURCE_TYPE,
    EXTRA_DS,
    METHOD_TYPE,
    PERIOD_UNIT,
    RESPONSE_TYPE,
    SCENARIO_TYPES
} from 'utils/constants';
import TooltipComponent from 'components/common/Tooltip';
import { CLOUD_DETAILS, END_PERIOD, END_PERIOD_UNIT, IS_DYNAMIC, PAYLOAD, PERIOD_END_KEY, PERIOD_START_KEY, QUERY_SUMMARY_SQL, RESPONSE_PATH, START_PERIOD, START_PERIOD_UNIT, START_TIME, START_TIME_FORMAT, START_TIME_KEY, TIMEFORMAT } from 'utils/info';

type TProps = {
    index: number;
    onChangeQuery: Function;
    query: any;
    type: String;
    errorObjQuery: any;
};

const AddQuery = (props: TProps) => {
    const { index, onChangeQuery, query, type, errorObjQuery } = props;

    return (
        <div>
            <InputField
                label="Query Name"
                value={query.name}
                onChangeHandler={(e) => onChangeQuery('name', e.target.value, index)}
                type={'text'}
                fieldType={FIELD_TYPES.TYPE_1}
                error={errorObjQuery.name}
                required={true}
            />
            <Select
                label={<div className='row-display'>Query type <div className={'req'}>*</div> <TooltipComponent  title={CLOUD_DETAILS}  icon={<BiInfoCircle size={12} className='margin-alignment'  color={'#6b6a68'}/>}/> </div>}
                value={query.type}
                onChangeHandler={(e: any, value: any) => onChangeQuery('type', value, index)}
                fieldType={SELECT_FIELD_TYPES.TYPE_1}
                options={
                    type != SCENARIO_TYPES.EXCEPTION
                        ? [...Object.keys(DATASOURCE_TYPE), ...Object.keys(EXTRA_DS)]
                        : [...Object.keys(DATASOURCE_TYPE)]
                }
                error={errorObjQuery.type}
            />
            <TextField
                label="Fetch Query/API"
                value={query.fetchQuery}
                onChangeHandler={(e) => onChangeQuery('fetchQuery', e.target.value, index)}
                required={true}
                error={errorObjQuery.fetchQuery}
            />
            {type == SCENARIO_TYPES.FIX ? (
                <TextField
                    label="Update Query/API"
                    value={query.updateQuery}
                    onChangeHandler={(e) => onChangeQuery('updateQuery', e.target.value, index)}
                    required={true}
                    error={errorObjQuery.updateQuery}
                />
            ) : (
                <></>
            )}

            {query.type == 'API' && (
                <Select
                    label="Method type"
                    value={query.methodType}
                    onChangeHandler={(e: any, value: any) =>
                        onChangeQuery('methodType', value, index)
                    }
                    fieldType={SELECT_FIELD_TYPES.TYPE_1}
                    options={[...Object.keys(METHOD_TYPE)]}
                />
            )}
            <InputField
                label={<div>Response Path <TooltipComponent title={RESPONSE_PATH} icon={<BiInfoCircle size={13} color={'#6b6a68'}/>}/></div>}
                value={query.responsePath}
                onChangeHandler={(e) => onChangeQuery('responsePath', e.target.value, index)}
                type={'text'}
                fieldType={FIELD_TYPES.TYPE_1}
            />
            <TextField
                label={
                    <InfoCard
                        label={'Query Summary SQL'}
                        message={'Info : SummaryValue - Dashboard summary variable'}
                        icon={<TooltipComponent title={QUERY_SUMMARY_SQL} icon={<BiInfoCircle size={12} className='margin-alignment' color={'#6b6a68'}/>}/>}
                        type={CARDTYPES.INFO}
                    />
                }
                value={query.querySummarySQL}
                onChangeHandler={(e) => onChangeQuery('querySummarySQL', e.target.value, index)}
            />
            <Select
                label="Response type"
                value={query.responseType}
                onChangeHandler={(e: any, value: any) =>
                    onChangeQuery('responseType', value, index)
                }
                fieldType={SELECT_FIELD_TYPES.TYPE_1}
                options={[...Object.keys(RESPONSE_TYPE)]}
                required={true}
                error={errorObjQuery.responseType}
            />
            <TextField
                label={<div className='row-display'>Payload <div className={(query.type==DATASOURCE_TYPE.CLOUD_WATCH_METRICS || query.type==DATASOURCE_TYPE.CLOUD_WATCH_QUERY_INSIGHTS)?'req':'not-req'}>*</div> <TooltipComponent  title={PAYLOAD}  icon={<BiInfoCircle size={12} className='margin-alignment'  color={'#6b6a68'}/>}/> </div>}
                value={query.payload}
                onChangeHandler={(e) => onChangeQuery('payload', e.target.value, index)}
                error={errorObjQuery.payload}
            />
            <Switch
                value={query.isDynamic}
                onChangeHandler={(e: any) => {
                    onChangeQuery('isDynamic', e.target.checked);
                }}
                label={<div>isDynamic <TooltipComponent title={IS_DYNAMIC} icon={<BiInfoCircle size={12}  color={'#6b6a68'}/>}/> </div>}
            />
            {query.isDynamic && (
                <>
                    <InputField
                        label={<div className='row-display'>Start Period <div className='req'>*</div><TooltipComponent title={START_PERIOD} icon={<BiInfoCircle size={12} className='margin-alignment' color={'#6b6a68'}/>}/> </div>}
                        value={query.startPeriod}
                        onChangeHandler={(e) => onChangeQuery('startPeriod', e.target.value, index)}
                        type={'number'}
                        fieldType={FIELD_TYPES.TYPE_1}
                        error={errorObjQuery.startPeriod}
                    />
                    <InputField
                        label={<div className='row-display'>End Period <div className='req'>*</div><TooltipComponent title={END_PERIOD} icon={<BiInfoCircle size={12} className='margin-alignment' color={'#6b6a68'}/>}/> </div>}
                        value={query.endPeriod}
                        onChangeHandler={(e) => onChangeQuery('endPeriod', e.target.value, index)}
                        type={'number'}
                        fieldType={FIELD_TYPES.TYPE_1}
                        error={errorObjQuery.endPeriod}
                    />
                    <Select
                        label={<div className='row-display'>Start Period Unit <div className='req'>*</div><TooltipComponent title={START_PERIOD_UNIT} icon={<BiInfoCircle size={12} className='margin-alignment'  color={'#6b6a68'}/>}/> </div>}
                        value={query.startPeriodUnit}
                        onChangeHandler={(e: any, value: any) =>
                            onChangeQuery('startPeriodUnit', value, index)
                        }
                        fieldType={SELECT_FIELD_TYPES.TYPE_1}
                        options={[...Object.keys(PERIOD_UNIT)]}
                        error={errorObjQuery.startPeriodUnit}
                    />
                    <Select
                        label={<div className='row-display'>End Period Unit <div className='req'>*</div><TooltipComponent title={END_PERIOD_UNIT} icon={<BiInfoCircle size={12} className='margin-alignment' color={'#6b6a68'}/>}/> </div>}
                        value={query.endPeriodUnit}
                        onChangeHandler={(e: any, value: any) =>
                            onChangeQuery('endPeriodUnit', value, index)
                        }
                        fieldType={SELECT_FIELD_TYPES.TYPE_1}
                        options={[...Object.keys(PERIOD_UNIT)]}
                        error={errorObjQuery.endPeriodUnit}
                    />
                    <InputField
                        label={<div className='row-display'>Period Start key <div className='req'>*</div><TooltipComponent title={PERIOD_START_KEY} icon={<BiInfoCircle size={12} className='margin-alignment' color={'#6b6a68'}/>}/> </div>}
                        value={query.periodStartKey}
                        onChangeHandler={(e) =>
                            onChangeQuery('periodStartKey', e.target.value, index)
                        }
                        type={'text'}
                        fieldType={FIELD_TYPES.TYPE_1}
                        error={errorObjQuery.periodStartKey}
                    />
                    <InputField
                        label={<div className='row-display'>Period End key <div className='req'>*</div><TooltipComponent title={PERIOD_END_KEY} icon={<BiInfoCircle size={12} className='margin-alignment' color={'#6b6a68'}/>}/> </div>}
                        value={query.periodEndKey}
                        onChangeHandler={(e) =>
                            onChangeQuery('periodEndKey', e.target.value, index)
                        }
                        type={'text'}
                        fieldType={FIELD_TYPES.TYPE_1}
                        error={errorObjQuery.periodEndKey}
                    />
                    <InputField
                        label={<div className='row-display'>Time Format <div className='req'>*</div><TooltipComponent title={TIMEFORMAT} icon={<BiInfoCircle size={12} className='margin-alignment' color={'#6b6a68'}/>}/> </div>}
                        value={query.timeFormat}
                        onChangeHandler={(e) =>
                            onChangeQuery('timeFormat', e.target.value, index)
                        }
                        type={'text'}
                        fieldType={FIELD_TYPES.TYPE_1}
                        error={errorObjQuery.timeFormat}
                    />
                    <InputField
                        label={<div>Start Time <TooltipComponent title={START_TIME} icon={<BiInfoCircle size={12}  color={'#6b6a68'}/>}/> </div>}
                        value={query.startTime}
                        onChangeHandler={(e) =>
                            onChangeQuery('startTime', e.target.value, index)
                        }
                        placeholder='Ex: ${startDate}T07:00:00+05:30'
                        type={'text'}
                        fieldType={FIELD_TYPES.TYPE_1}
                    />
                    <InputField
                        label={<div>Start Time Key<TooltipComponent title={START_TIME_KEY} icon={<BiInfoCircle size={12}  color={'#6b6a68'}/>}/> </div>}
                        value={query.startTimeKey }
                        onChangeHandler={(e) =>
                            onChangeQuery('startTimeKey', e.target.value, index)
                        }
                        type={'text'}
                        placeholder='Ex: startDate'
                        fieldType={FIELD_TYPES.TYPE_1}
                    />
                    <InputField
                        label={<div>Start TimeFormat<TooltipComponent title={START_TIME_FORMAT} icon={<BiInfoCircle size={12}  color={'#6b6a68'}/>}/> </div>}
                        value={query.startTimeFormat }
                        onChangeHandler={(e) =>
                            onChangeQuery('startTimeFormat', e.target.value, index)
                        }
                        placeholder={'Ex: yyyy-MM-dd'}
                        type={'text'}
                        fieldType={FIELD_TYPES.TYPE_1}
                    />
                </>
            )}
        </div>
    );
};

export default AddQuery;
