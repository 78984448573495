import './style.scss';
import { TComponent } from './types';
import { NavLink } from 'react-router-dom';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { MouseEventHandler } from 'react';



type TProps = {
    components: TComponent[];
    basePath: string;
    onLogout?: MouseEventHandler<HTMLDivElement>;
}
const Controller = ({ components, basePath, onLogout }: TProps) => {
    
    return (
        <div className="controller-container">
            <div className="controller-link-wrapper">
                <div className="li-container">
                    {
                        components.map((component: TComponent) => {
                            const { icon } = component;
                            return <NavLink key={component.hash} to={`${basePath}/${component.hash}`} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                               <div className="li-item">
                                    {icon}
                                    <div className='label'>{component.hash}</div>
                                </div>
                                </NavLink>
                        })
                    }
                </div>
            </div>
            {onLogout ? <div className='extra-conatiner'>
                <div className="extra-btn" onClick={onLogout}>
                    <RiLogoutBoxLine size={20} color={'#000000a6'} />
                    <div className='label'>logout</div>
                </div>
            </div> : null}
        </div>
    );
};

export default Controller;
