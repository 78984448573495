
import { useEffect } from 'react';
import { TColorConfig } from 'utils/model';
import CreateColorScheme from './CreateColorScheme';
import './style.scss';
import SummaryColorScheme from './SummaryColorScheme';

type TProps = {
    colorConfigList: TColorConfig[];
    setColorConfigList: Function;
    error?: string;
};

const ConfigureColorScheme = (props: TProps) => {
    const { colorConfigList, setColorConfigList, error } = props;

    useEffect(() => {
        const updatedColorConfigList: TColorConfig[] = getUpdatedColorConfigList(colorConfigList);
        setColorConfigList(updatedColorConfigList);
    }, []);

    const getUpdatedColorConfigList = (data: any[]) => {
        return data.map((item) => {
            return { ...item, editMode: false };
        });
    };

    const updateMode = (index: number, mode: boolean) => {
        const updatedList: TColorConfig[] = [...colorConfigList];
        updatedList[index].editMode = mode;
        setColorConfigList(updatedList);
    };

    const onEdit = (index: number) => {
        updateMode(index, true);
    };

    const onAddOrUpdate = (newDataObj: TColorConfig, index: number) => {
        const updatedDataObj: TColorConfig = { ...newDataObj, editMode: false };
        if (index !== undefined) {
            const updatedList: TColorConfig[] = [...colorConfigList];
            updatedList[index] = updatedDataObj;
            setColorConfigList(updatedList);
        } else {
            setColorConfigList([...colorConfigList, updatedDataObj]);
        }
    };

    const onDelete = (index: number) => {
        const updatedQueryList: TColorConfig[] = [...colorConfigList];
        updatedQueryList.splice(index, 1);
        setColorConfigList(updatedQueryList);
    };

    return (
        <div className="configure-color-schema">
            <div className="title-container">
                <div className="title">Configure Color Schema</div>
                {error ? <div className="error">{error}</div> : null}
            </div>
            <CreateColorScheme onSubmit={onAddOrUpdate} />
            {colorConfigList?.map((item: TColorConfig, index: number) => {
                return item.editMode ? (
                    <CreateColorScheme
                        key={index}
                        data={item}
                        index={index}
                        onSubmit={onAddOrUpdate}
                    />
                ) : (
                    <SummaryColorScheme
                        key={index}
                        data={item}
                        index={index}
                        onDelete={onDelete}
                        onEdit={onEdit}
                    />
                );
            })}
        </div>
    );
};

export default ConfigureColorScheme;
