import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import './style.scss';

type TProps = {
    message?: string;
}
const FlashMessage = (props: TProps) => {
    const { message } = props;
    const [show, setShow] = useState(false);
    const messageRef:any = useRef(null);
    useEffect(() => {
        setShow(true);
        setTimeout(() => {
            if(messageRef){
                messageRef?.current?.classList.add('hide-pop-up');
            }
            setTimeout(()=>setShow(false),1000);
        },3000);
    }, [message])

    return show && !_.isEmpty(message)? <div ref={messageRef} className="flash-message">{message}</div> : null;
}

export default FlashMessage;