import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message: ''
};

const reducers = {
    setError: (state: any, action: any) => {
        state.message = action.payload;
    },
    resetError: (state: any) => {
        state.message = '';
    }
};

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers
});

export const { setError, resetError } = errorSlice.actions;

export default errorSlice.reducer;