import Button, { TYPES } from "components/common/Button";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { SCENARIO_TYPES } from "utils/constants";
import { TDashboardData, TDBScenarioData, TQueryData, TQueryDetail } from "utils/model";
import CreateorUpdateDashboard from "../CreateUpdateDashboard";

const INITIAL_DATA: TDashboardData = {
    // id?: '',
    name: '',
    description: '',
    scheduleHours: [],
    distributionTarget: '',
    proxyId: '',
    scenarios: []
};

const EditDashboard = () => {
    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const [formData, setFormData] = useState<TDashboardData>(INITIAL_DATA)
    const [showUpdateForm, setShowUpdateForm] = useState<boolean>(false);
    const fixDetails = useSelector((state: any) => state.scenarios[SCENARIO_TYPES.FIX]);
    const investigationDetails = useSelector(
        (state: any) => state.scenarios[SCENARIO_TYPES.INVESTIGATION]
    );
    const exceptionDetails = useSelector((state: any) => state.scenarios[SCENARIO_TYPES.EXCEPTION]);
 
    
    useEffect(()=>{
        generateFormData();
    },[selectedDashboard, exceptionDetails, investigationDetails, fixDetails]);

    const generateFormData = ()=>{
        let tempFormData = INITIAL_DATA;
        tempFormData.id = selectedDashboard.id;
        tempFormData.description = '';
        tempFormData.distributionTarget=selectedDashboard.distributionTarget;
        tempFormData.name = selectedDashboard.name;
        tempFormData.proxyId = selectedDashboard.proxyId;
        tempFormData.scheduleHours = selectedDashboard.scheduleHours.split(',');
        const scenarioIdList = _.uniq(selectedDashboard.dashboardQueries.map((item:any)=>item.scenarioId));
        const scenarioDBList:TDBScenarioData[] = [];
        const scenarioList = [...exceptionDetails, ...investigationDetails, ...fixDetails];
        for(const scenarioId of scenarioIdList){
            const scenario = _.find(scenarioList,{id: scenarioId});
            if(scenario){
                const queryDsMap = scenario.checks?.flatMap((checkItem: TQueryData) => {
                    return checkItem.queries.map((queryItem: any) => {
                        const dbQuery = _.find(selectedDashboard.dashboardQueries, { queryId: queryItem.id, scenarioId: scenarioId });
                        if (dbQuery) {
                            return {
                                ...dbQuery, name: queryItem.name, type:queryItem.type
                            }
                        }
                        return {
                            queryId: queryItem.id,
                            name: queryItem.name,
                            datasourceName: '',
                            type:queryItem.type
                        }
                    })

                });
                
                const scenarioListItem: TDBScenarioData = {
                    id: scenario.id,
                    title: scenario.title,
                    queries: queryDsMap
                }
                scenarioDBList.push(scenarioListItem);
            }
        }
        tempFormData.scenarios = scenarioDBList;
        setFormData(tempFormData);

    }
    return <React.Fragment>
        <Button
            buttonText={<MdEdit color="#706b6b"/>}
            title={'Edit'}
            onClick={() => setShowUpdateForm(true)}
            type={TYPES.ICON}
            width={'35px'}
        />
        <CreateorUpdateDashboard  formData={formData} show={showUpdateForm} onClose={()=>setShowUpdateForm(false)} isUpdate={true}/>
    </React.Fragment>
}
export default EditDashboard;