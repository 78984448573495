import Summary from './Summary';
import CreateOrUpdate from './CreateOrUpdate';
import { TQueryData, TQueryDetail } from 'utils/model';
import React, { useEffect, useState } from 'react';
import { SCENARIO_TYPES, SUMMARY_OPERATION_TYPES } from 'utils/constants';
import SearchData from './SearchData';
import Button, { TYPES } from 'components/common/Button';
import { copyToClipboardAPI, pasteFromClipboardAPI } from 'utils/helperFunctions';
import FlashMessage from 'components/common/FlashMessage';
import _ from 'lodash';
import { FaCopy, FaEdit, FaPaste } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';



type TProps = {
    queryList: TQueryData[];
    setQueryList: Function;
    goBack: Function;
    datasourcesNameIdList: string[];
    type: SCENARIO_TYPES;
};

const INTIAL_DATA_OBJ: TQueryData = {
    name: '',
    reportType: '',
    colorConfig: [],
    cacheDuration: 0,
    editMode: true,
    notes: '',
    linkedInvestigationScenarioId: null,
    linkedFixScenarioId: null,
    operationType: SUMMARY_OPERATION_TYPES.COUNT,
    columnName: '',
    summaryTemplate:'',
    summarySQL:'select count(*) as summaryValue from :table',
    dataInSummary: false,
    validationCriteria:'',
    queries:[]
};
const DataExtraction = (props: TProps) => {
    const { queryList, setQueryList, datasourcesNameIdList, type, goBack } = props;
    const [showSummary, setShowSummary] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editSaved,setEditSaved] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [currentQuery, setCurrentQuery] = useState<TQueryData>(INTIAL_DATA_OBJ);
    const [formdata, setFormData] = useState<TQueryData>(INTIAL_DATA_OBJ);
    const [deletion, setDeletion] = useState(false);
    const [paste, setPaste] = useState(false);
    const [message, setMessage] = useState('');
 

    useEffect(() => {
        const updatedQueryList: TQueryData[] = getUpdatedQueryList(queryList);
        setQueryList(updatedQueryList);
        if (queryList?.length > 0) {
            setShowSummary(true);
            SetCurrentQueryData(0);
        }
    }, []);

    const SetCurrentQueryData = (keyIndex: number) => {
        queryList.map((item: any, key: number) => {
            key == keyIndex && setCurrentQuery(item);
        });
        setShowSummary(true);
    };

    const getUpdatedQueryList = (data: any[]) => {
        return data?.map((item) => {
            return { ...item, editMode: false };
        });
    };
    const updateMode = (index: number, mode: boolean) => {
        index==-1 && index++;
        const updatedList: TQueryData[] = [...queryList];
        updatedList.filter((x) => (x.editMode == true ? (x.editMode = false) : ''));

        updatedList[index]
            ? (updatedList[index].editMode = mode)
            : (updatedList[selectedIndex - 1].editMode = mode);
        setCurrentQuery(updatedList[index]);
        setQueryList(updatedList);
    };

    const onEdit = (index: number) => {
        setEditSaved(false);
        updateMode(index, true);
        setShowSummary(false);
        setEdit(true);
    };

    const onAddOrUpdate = (newDataObj: TQueryData, index: number) => {
        const updatedDataObj: TQueryData = { ...newDataObj, editMode: false };
        if (index !== undefined || edit) {
            const updatedList: TQueryData[] = [...queryList];
            !edit
                ? (updatedList[index] = updatedDataObj)
                : (updatedList[selectedIndex] = updatedDataObj);
            setQueryList(updatedList);
            setShowSummary(true);
        } else {
            setQueryList([...queryList, updatedDataObj]);
            setShowSummary(true);
        }
        setCurrentQuery(updatedDataObj);
        !edit && setSelectedIndex(queryList.length);
        setPaste(false);
        edit && setEditSaved(true);
    };

    const onDelete = (index: number) => {
        index==-1 && index++;
        const updatedQueryList: TQueryData[] = [...queryList];
        updatedQueryList.splice(index, 1);
        setQueryList(updatedQueryList);
        setSelectedIndex(0);
        setCurrentQuery(updatedQueryList[0]);
        setDeletion(true);
    };

    const goToPrevious = (index: number) => {
        goBack(index);
    };

    const createScenario = () => {
        setShowSummary(false);
        setEdit(false);
    };

    const CopyData = () => {
        let dataToBeCopied: string = '';
        let response: string = '';
        Object.entries(queryList).map(async ([key, value]: any) => {
            if (key == selectedIndex) {
                dataToBeCopied = JSON.stringify(value);
                response = await copyToClipboardAPI(dataToBeCopied);
                setMessage(response);
                setTimeout(() => {
                    setMessage('');
                }, 4000);
            }
        });
    };

    const pasteData = async () => {
        let pasteData = await pasteFromClipboardAPI();
        let dataToBeCopied2 = JSON.parse(pasteData);
        dataToBeCopied2.summarySQL=dataToBeCopied2.summarySQL?dataToBeCopied2.summarySQL==''?formdata.summarySQL:dataToBeCopied2.summarySQL:formdata.summarySQL;
        if (typeof dataToBeCopied2.colorConfig != 'string') {
            dataToBeCopied2.colorConfig = JSON.stringify(dataToBeCopied2.colorConfig);
        }
        dataToBeCopied2.colorConfig = JSON.parse(dataToBeCopied2.colorConfig)
        delete dataToBeCopied2.scenarioId;
        setFormData(dataToBeCopied2);
        deleteInfo(dataToBeCopied2);
        setPaste(true);
    };

    const deleteInfo = (data: any) => {
        if (!edit) {
            delete data.id;
        } else {
            data.id = currentQuery.id;
            data.editMode = true;
        }
        setFormData(data);
    };

    const SelectedData = (key: number) => {
        SetCurrentQueryData(key);
        setSelectedIndex(key);
        setDeletion(false);
        setPaste(false);
    };

    return (
        <div className="de-main-container">
            <div className="data-extraction-container">
                <FlashMessage message={message} />
                <SearchData
                    createScenario={createScenario}
                    queryList={queryList}
                    SelectedData={SelectedData}
                    newMethod={!showSummary && !edit}
                    deletedIndex={deletion}
                    edit={editSaved}
                />
                {/* For edit */}
                <div className="query-container">
                    <div className="copy-button-container">
                        <Button
                            buttonText={<FaEdit />}
                            title={'Edit'}
                            onClick={() => onEdit(selectedIndex)}
                            type={TYPES.ICON}
                            width={'35px'}
                            classList={['float-right']}
                        />

                        <Button
                            buttonText={<MdDelete />}
                            title={'Delete'}
                            onClick={() => onDelete(selectedIndex)}
                            type={TYPES.ICON}
                            width={'35px'}
                            classList={['float-right']}
                        />
                        {showSummary ? <React.Fragment><Button
                            buttonText={<FaCopy />}
                            title={'Copy'}
                            onClick={() => {
                                CopyData();
                            }}
                            width={'35px'}
                            type={TYPES.ICON}
                            classList={['float-none']}
                        /></React.Fragment> : null}
                        <Button
                            buttonText={<FaPaste />}
                            title={'Paste'}
                            onClick={() => {
                                pasteData();
                            }}
                            disabled={_.isEmpty(formdata) || showSummary}
                            width={'35px'}
                            type={TYPES.ICON}
                            classList={['float-none']}
                        />
                    </div>
                    {queryList?.map((item: TQueryData, index: number) => {
                        if (!showSummary && edit && item.editMode && !paste) {
                            selectedIndex !== index && setSelectedIndex(index);
                            return (
                                <CreateOrUpdate
                                    key={index}
                                    type={type}
                                    onSubmit={onAddOrUpdate}
                                    dataObject={item}
                                    index={index}
                                    datasourcesNameIdList={datasourcesNameIdList}
                                    onBack={goToPrevious}
                                />
                            );
                        } else return null;
                    })}

                    {!showSummary && paste && (
                        <CreateOrUpdate
                            type={type}
                            onSubmit={onAddOrUpdate}
                            onBack={goToPrevious}
                            datasourcesNameIdList={datasourcesNameIdList}
                            pasteData={formdata}
                        />
                    )}
                    {!showSummary && !edit && !paste ? (
                        <CreateOrUpdate
                            type={type}
                            onSubmit={onAddOrUpdate}
                            onBack={goToPrevious}
                            datasourcesNameIdList={datasourcesNameIdList}
                        />
                    ) : (
                        showSummary && (
                            <Summary
                                key={selectedIndex}
                                data={currentQuery}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                index={selectedIndex}
                                onBack={goToPrevious}
                                onNext={goToPrevious}
                            />
                        )
                    )}
                </div>
                {/* edit mode */}
            </div>
        </div>
    );
};

export default DataExtraction;
