import Button, { TYPES } from 'components/common/Button';
import DynamicGrid from 'components/common/DynamicGrid';
import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLOR_SCHEMA_TYPE, COMPARISON_TYPES, EXTRA_DS, PIN_OPTIONS } from 'utils/constants';
import { getValueReplacedString } from 'utils/helperFunctions';
import { TQueryData } from 'utils/model';
import BtnCellRenderer from '../QueryResponseComponent/BtnCellRenderer';
import './style.scss';

type TProps = {
    scenarioId: number;
    exceptionData?:any;
    exceptionScenarioId?: number;
    exceptionCheckId?: number;
};

function InvestigationDetails(props: TProps) {
    const { scenarioId, exceptionData, exceptionScenarioId, exceptionCheckId } = props;
    const scenarioDetails = useSelector((state: any) => state.scenarioDetails[scenarioId]);
    const navigate = useNavigate();
    const locationData: any = useLocation();

    const getCustomConfig = useCallback((checkItem:TQueryData)=>{
        const customConfig = [];
        if(checkItem.linkedInvestigationScenarioId){
            customConfig.push({
                field: 'investigation',
                cellRenderer: BtnCellRenderer,
                cellRendererParams: {
                    label: 'Investigate',
                    clicked: (rowData:any)=> {
                        const state = {
                            refData: exceptionData??rowData,
                                exceptionScenarioId: exceptionScenarioId,
                                exceptionCheckId: exceptionCheckId
                          };
                      navigate(`/console/execute-fix/${checkItem.linkedInvestigationScenarioId}`,{state})
                    },
                  },
            })
        }
        if(checkItem.linkedFixScenarioId){
            customConfig.push(
                {
                    field: 'fix',
                    cellRenderer: BtnCellRenderer,
                    cellRendererParams: {
                        label: 'Fix',
                        clicked: (rowData:any)=> {
                            const state = {
                                refData: exceptionData??rowData,
                                exceptionScenarioId: exceptionScenarioId,
                                exceptionCheckId: exceptionCheckId,
                                isDashboardEnabled:  locationData.state?.isDashboardEnabled,
                                selectedDashboard: locationData.state?.isDashboardEnabled? locationData.state?.selectedDashboard: undefined,
                                selectedsnapshot: locationData.state?.isDashboardEnabled? locationData.state?.selectedsnapshot: undefined
                              };
                         navigate(`/console/execute-fix/${checkItem.linkedFixScenarioId}`,{state})
                        },
                      },
                }
            )
        }
        
         return customConfig;
    },[])

    const getColorConfig = (index: number) => {
        return _.isEmpty(scenarioDetails.checks[index].colorConfig)
            ? []
            : typeof scenarioDetails.checks[index].colorConfig === 'string'
                ? JSON.parse(scenarioDetails.checks[index].colorConfig)
                : scenarioDetails.checks[index].colorConfig;
    }

    const getColorClassSetterObject = (colorItem: any) => {
        return {
            [colorItem.colorClass]: (params: any) => {
                if (typeof colorItem.value === 'string'
                    && typeof params.value === 'number') {
                    if (colorItem.comparisonType === COMPARISON_TYPES.GREATER_THAN) {
                        return params.value > parseInt(colorItem.value)
                    } else if (colorItem.comparisonType === COMPARISON_TYPES.GREATER_THAN_EQUAL) {
                        return params.value >= parseInt(colorItem.value)
                    } else if (colorItem.comparisonType === COMPARISON_TYPES.LESS_THAN) {
                        return params.value < parseInt(colorItem.value)
                    } else if (colorItem.comparisonType === COMPARISON_TYPES.LESS_THAN_EQUAL) {
                        return params.value <= parseInt(colorItem.value)
                    } else if (colorItem.comparisonType === COMPARISON_TYPES.NOT_EQUAL) {
                        return params.value != parseInt(colorItem.value)
                    }
                }
                return `${params.value}` === colorItem.value
            }
        }
    }
    const getVaueTypeCellRule = (fieldItem: any) => {
        return _.reduce(fieldItem.colorScheme, (innerAcc, colorItem: any) => {
            return {
                ...innerAcc, ...getColorClassSetterObject(colorItem)
            }
        }, {})
    }
    const getColumnTypeCellRule = (fieldItem: any) => {
        return {
            [fieldItem.colorClass]: () => true
        }
    }

    const getRangeTypeCellRule = (fieldItem: any) => {
        return {
            [fieldItem.colorClass]: (params: any) => {
                const start: number = parseInt(fieldItem.range.startValue);
                const end: number = parseInt(fieldItem.range.endValue)
                if (typeof start == 'number' && typeof end == 'number' && typeof params.value == 'number') {
                    return start <= params.value && params.value < end
                }
                return false;
            }
        }
    }
    const getFieldWiseCellRules = useCallback((index: number) => {
        const colorConfig = getColorConfig(index);
        const cellRuleObj = _.reduce(colorConfig, (acc: Object, fieldItem: any) => {
            return {
                ...acc,
                [fieldItem.columnName]: (fieldItem.type == COLOR_SCHEMA_TYPE.COLUMN)
                    ? getColumnTypeCellRule(fieldItem)
                    : (fieldItem.type == COLOR_SCHEMA_TYPE.VALUE)
                        ? getVaueTypeCellRule(fieldItem)
                        : (fieldItem.type == COLOR_SCHEMA_TYPE.COLUMN_RANGE)
                            ? getRangeTypeCellRule(fieldItem)
                            : {}
            }
        }, {})
        return cellRuleObj;
    }, []);

    const getFieldConfig = useCallback((index: number) => {
        const colorConfig = getColorConfig(index);
        const fieldConfig = _.reduce(colorConfig, (acc: Object, fieldItem: any) => {
            return {
                ...acc, [fieldItem.columnName]: {
                    pinned: fieldItem.pinned && fieldItem.pinned === PIN_OPTIONS.ENABLE ? 'left' : undefined
                }
            }
        }, {})
        return fieldConfig;
    }, [])
    
    const getDetails = (checkItem: any) => {
        return <div className="query-meta">
            <div className="section">
                <label>Executed string: </label>
                <div className="content">
                    {checkItem.executedQuery}
                </div>
            </div>
            {checkItem.notes ? <div className="section">
                <label>Notes: </label>
                <pre className="content">{checkItem.notes}</pre>
            </div> : null}
        </div>
    }

    const getExternalQueryForm = (checkItem:any) => {
        const updatedUrl = getValueReplacedString(checkItem.queries[0]?.fetchQuery,checkItem.queries[0].fetchVariableData);
        const onLinkOpen = () => {
            if (checkItem != null && window !== null) { window.open(updatedUrl, '_blank')?.focus(); }
        }
        return <div className='external-link'>
            <div className='head-section'>{checkItem.name}</div>
            <div className='ext-content'>
                <div className='message'>On Clicking open button page will be redirected to below link</div>
                <div className='link'>{updatedUrl}</div>
                <Button buttonText={"Open"} onClick={onLinkOpen} type={TYPES.SMALL_GREEN} />
            </div>
        </div>
    }
    
    const getSortedChecks = ()=>{
        const externalUrlChecks = _.filter(scenarioDetails.checks,{reportType: EXTRA_DS.EXTERNAL_URL});
        const extraChecks = _.filter(scenarioDetails.checks,(checkItem:TQueryData)=>checkItem.reportType !== EXTRA_DS.EXTERNAL_URL);
        return [...externalUrlChecks, ... extraChecks];
    }

    return (
        <div className="investigation-details">
            <h2>Query Execution Response</h2>
            
            {
                getSortedChecks().map((checkItem:any, index:number)=>{
                    if(checkItem.dataLoader){
                        return <div>Loading...</div>
                    }
                    if (checkItem.reportType === EXTRA_DS.EXTERNAL_URL) {
                        return getExternalQueryForm(checkItem);
                    };
                    if (checkItem.currentData?.dataType === "jsonml")
                        return <div className="multi-container">
                            <div className="multi-table-meta">
                                <h3 className="title">{checkItem.name}</h3>
                                {getDetails(checkItem)}
                            </div>
                            {
                                checkItem.currentData?.data?.map((dataItem: any) => <DynamicGrid
                                    data={dataItem}
                                    fieldWiseCellRules={getFieldWiseCellRules(index)}
                                    fieldConfig={getFieldConfig(index)}
                                    // copyToClipBoard={(data: any[]) => copyToClipBoard(data, index)}
                                    title={checkItem.name}
                                />)
                            }
                        </div>
                    return <div>
                        {checkItem.dataLoader ? <div>Loading...</div> : <div><DynamicGrid
                            data={checkItem.currentData}
                            title={checkItem.name}
                            customColumns={getCustomConfig(checkItem)}
                            showTitleSection={true}
                            details={getDetails(checkItem)}
                        /></div>}
                    </div>
                })
            }
        </div>
    );
}

export default InvestigationDetails;
