import ComponentWrapper from '../ComponentWrapper';
import { useLocation, useParams } from 'react-router-dom';
import ExecuteForm from '../Execute';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {  getReadQueryExecutionData, getScenarioDetailsByIdWrapper, getWriteQueryExecutionData } from 'store/actions';
import FixDetails from '../FixDetails';
import { resetQueryLoader, setQueryLoader, setScenarioDetailsLoader } from 'store/reducers/scenarioDetailsSlice';
import { resetError, setError } from 'store/reducers/ErrorSlice';
import { TQueryData } from 'utils/model';
import ReferenceData from '../ReferenceData';
import './style.scss';
import { EXTRA_DS, USER_ROLES } from 'utils/constants';
import ConfirmExecution from './ConfirmExecution';
import { clearQueryCacheAPI } from 'apis';
import _ from 'lodash';
import { getFilteredQueryDSMapList, getRole } from 'utils/helperFunctions';
import React from 'react';
import { setFixComplete } from 'store/reducers/AuditTrailSlice';

type TProps = {};

function ExecuteFix(props: TProps) {
    const { id }: any = useParams();
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isInExecution, setIsInExecution] = useState(false);
    const [isExecutedOnce, setIsExecutedOnce] = useState(false);
    const [collapseRef,setCollapseRef] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [executionDetails, setExecutionDetails] = useState<any>({});
    const [isValidScenario,setIsValidScenario] = useState(true);
    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const locationData: any = useLocation();

    const scenarioDetails = useSelector((state: any) => state.scenarioDetails[id]);
    const isDataLoading = useSelector((state:any)=>state.scenarioDetails.loaders[id]);
    const initialSetup = async () => {
        if (id) {
            dispatch(setScenarioDetailsLoader({id}))
            await dispatch(getScenarioDetailsByIdWrapper(id));
            setIsLoaded(true);
        }
    };
    useEffect(() => {
        initialSetup();
    }, []);
    
    const getExecutionDetails = () => {
        const scenarioQueries = _.filter(selectedDashboard.dashboardQueries, { scenarioId: parseInt(id) });
        if (scenarioQueries.length === 0) {
            setIsValidScenario(false);
            return;
        }
        setIsValidScenario(true);
    }

    useEffect(() => {
        if (getRole() === USER_ROLES.VIEWER && selectedDashboard) {
            getExecutionDetails();
        }
    }, [selectedDashboard])

    const loadQueryData = async () => {
        try {
            dispatch(resetError());
            const querypromiseList = scenarioDetails.checks.map((checkItem: TQueryData)=>{
                if (checkItem.reportType === EXTRA_DS.EXTERNAL_URL) {
                    return null
                };
                return new Promise(async (resolve: any, reject: any) => {
                    try {
                        dispatch(setQueryLoader({ checkId: checkItem.id, scenarioId: id }));
                        const isDashboardEnabled = locationData.state?.isDashboardEnabled??false;
                        const payload = {
                            checkId: checkItem.id,
                            scenarioId: id,
                            isDashboardEnabled:  locationData.state?.isDashboardEnabled,
                            proxyId: isDashboardEnabled? locationData.state.selectedDashboard.proxyId:executionDetails.proxyId,
                            dashboardId:locationData.state?.isDashboardEnabled?locationData.state.selectedDashboard.id:null,
                            selectedsnapshot: locationData.state?.isDashboardEnabled?locationData.state.selectedsnapshot:null,
                            queryRequestList: 
                            !locationData.state?.isDashboardEnabled
                            ? getFilteredQueryDSMapList(checkItem.queries,executionDetails.queryDsMap)
                            : getFilteredQueryDSMapList(checkItem.queries,locationData.state.selectedDashboard.dashboardQueries)
                        }
                        await dispatch(getWriteQueryExecutionData(payload))
                        dispatch(resetQueryLoader({ checkId: checkItem.id, scenarioId: id }))
                        resolve(true);
                    } catch (e) {
                        reject(e);
                    }
                })
            });
           await  Promise.allSettled(querypromiseList);
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        }
        finally {
        }

    }
    const refreshExceptionQueryData = async ()=>{
        if(locationData && locationData.state){
            const {exceptionScenarioId, exceptionQueryId} = locationData.state;
            if(exceptionScenarioId && exceptionQueryId){
                //TODO: Add code to refresh all latest snapshot after fix
                // await clearQueryCacheAPI(exceptionQueryId);
                await dispatch(getReadQueryExecutionData({ queryId: exceptionQueryId, scenarioId: exceptionScenarioId }));
            }
        }
    }
    const onExecute = async ()=>{
        setShowConfirmation(false);
        setIsExecutedOnce(true);
        setIsInExecution(true);
        await loadQueryData();
        await refreshExceptionQueryData();
        setIsInExecution(false);
        setCollapseRef(true);
        dispatch(setFixComplete(true))
    }
    const onExecuteFormConfirm = (details:any)=> {
        setExecutionDetails(details);
        setShowConfirmation(true)
    }
    return (
        <ComponentWrapper title="Execute Fix Scenario">
            {isLoaded && scenarioDetails ? (
                <div className='execute-fix'>
                    {
                        isValidScenario
                            ? <React.Fragment>
                                <ConfirmExecution show={showConfirmation} onClose={() => setShowConfirmation(false)} onExecute={onExecute} />
                                <div className='execute-fix-form-section'>
                                    <ExecuteForm data={scenarioDetails} onExecute={onExecuteFormConfirm} scenarioId={id} />
                                    {
                                        isExecutedOnce
                                            ? isInExecution
                                                ? <div>Loading execution response...</div>
                                                : <FixDetails scenarioId={id} />
                                            : null
                                    }
                                </div>
                                {locationData?.state?.refData ? <ReferenceData collapse={collapseRef} data={locationData?.state?.refData} /> : null}
                            </React.Fragment>
                            : <h2>Invalid Scenario for selected dashboard</h2>
                    }
                </div>
            ) : (
                isLoaded && !isDataLoading ? <div>Something went wrong</div> : <div>Loading...</div>
            )}
        </ComponentWrapper>
    );
}

export default ExecuteFix;