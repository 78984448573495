import InputField from "components/common/InputField";
import Select from "components/common/Select";
import { useEffect } from "react";
import { COLOR_CLASSES } from "utils/constants";
import { TValueColorSchema } from "utils/model";
import _ from 'lodash';
import { AiOutlineClose } from "react-icons/ai";
import {COMPARISON_TYPES} from 'utils/constants';

const INITIAL_COLOR_SCHEMA: TValueColorSchema = {
    value: "",
    valueType: "string",
    colorClass: "",
    comparisonType: COMPARISON_TYPES.EQUALS
}

type TProps = {
    list: TValueColorSchema[];
    setList: Function;
}
const ValueBasedColorSelector = (props: TProps) => {
    const { list, setList } = props;
    useEffect(() => {
            setList([...list,{...INITIAL_COLOR_SCHEMA}]);
    }, [])

    const onChange = (index:number,key: keyof TValueColorSchema, value:string)=>{
        const updatedlist = _.cloneDeep(list);
        updatedlist[index][key] = value;
        if(index === updatedlist.length-1){
            updatedlist.push({...INITIAL_COLOR_SCHEMA});
        }
        setList(updatedlist);
    }

    const onDelete = (index:number)=>{
        const updatedlist = _.cloneDeep(list);
        updatedlist.splice(index,1);
        setList(updatedlist);
    }
//TODO: Add validation

    return <div className="value-based-color-selector">
        <div className="head-row">
            <div className="col-1">Value</div>
            <div className="col-1">Color</div>
            <div className="col-1">Comparison type</div>
        </div>
        {
            list.map((listItem: TValueColorSchema, index:number) => {
                return <div className="list-row">
                    <InputField
                        value={listItem.value}
                        onChangeHandler={(e:any) => onChange(index,"value",e.target.value)}
                        type={"text"}
                        classList={["col-1"]}
                        />
                    <Select
                        value={listItem.colorClass}
                        onChangeHandler={(e: any, value: string) => onChange(index,"colorClass",value)}
                        options={COLOR_CLASSES}
                        classList={["col-1"]}
                    />
                    <Select
                        value={listItem.comparisonType}
                        onChangeHandler={(e: any, value: string) => onChange(index,"comparisonType",value)}
                        options={Object.values(COMPARISON_TYPES)}
                        classList={["col-1"]}
                    />
                    {
                        index !== list.length-1?<div className="col-2" onClick={()=>onDelete(index)}>
                            <AiOutlineClose/>
                        </div>:null
                    }
                </div>
            })
        }
    </div>
}

export default ValueBasedColorSelector;