import React, { useEffect, useState } from 'react';
import Button, { TYPES } from 'components/common/Button';
import { TDashboardData, TScenarioData } from 'utils/model';
import _ from 'lodash';
import './style.scss';
import CreateorUpdateDashboard from '../CreateUpdateDashboard';
// import CreateorUpdate from '../CreateUpdate';
// import  from '../CreateUpdateDashboard';

type TProps = {
};

const INITIAL_DATA: TDashboardData = {
    // id?: '',
    name: '',
    description: '',
    scheduleHours: [],
    distributionTarget: '',
    proxyId: '',
    scenarios: []
};
const CreateDashboard = (props: TProps) => {
    const { } = props;
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState<TDashboardData>(INITIAL_DATA);

    useEffect(() => {
        setFormData(INITIAL_DATA);
    }, []);


    const onClose = () => {
        setShow(false);
    };

    return (
        <div className="create-scenario">
            <Button
                buttonText={'Create'}
                onClick={() => {setShow(true)}}
                type={TYPES.PRIMARY}
                classList={['float-right']}
            />

            <CreateorUpdateDashboard
                formData={formData}
                show={show}
                onClose={onClose}
                />
        </div>
    );
};

export default CreateDashboard;
