/**
 * Purpose -> Generate a form that takes value against each key
 * Props -> data object with current value, onChangeHandler (that passes updated object)
 */

import InputField, { FIELD_TYPES } from '../InputField';
import "./style.scss";

type TProps = {
    title: string;
    data: Object;
    onChangeHandler: Function;
};

const DynamicForm = (props: TProps) => {
    const { data, onChangeHandler, title } = props;

    const onValueChange = (key: string, newValue: string) => {
        onChangeHandler({ ...data, [key]: newValue });
    };
    return (
        <div className='dynamic-form'>
            <div className='form-title'>{title}</div>
            {Object.entries(data).map(([key, value]) => {
                return (
                    <div className="key-value-row" key={key}>
                        <InputField
                            label={key}
                            value={value}
                            onChangeHandler={(e) => onValueChange(key, e.target.value)}
                            type="text"
                            fieldType={FIELD_TYPES.TYPE_2}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default DynamicForm;
