export const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const scheduleTimeList = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];

export const summaryOrder = ['name', 'id', 'reportType','summaryTemplate','summarySQL','dataInSummary','linkedInvestigationScenarioId','linkedFixScenarioId','colorConfig','validationCriteria','queries'];

export const SLA_STATUSES = ['critical', 'high', 'medium', 'low'];
export const SLA_TYPES = ['within SLA', '2xSLA', '>2xSLA'];

export enum SCENARIO_TYPES {
    FIX = 'FIX',
    INVESTIGATION = "INVESTIGATION",
    EXCEPTION = "EXCEPTION"
}

export enum DATASOURCE_TYPE {
    API= "API",
    SQL="SQL",
    CLOUD_WATCH_METRICS="CLOUD_WATCH_METRICS",
    CLOUD_WATCH_QUERY_INSIGHTS="CLOUD_WATCH_QUERY_INSIGHTS"
    // remote="remote"
}

export enum METHOD_TYPE {
    GET="GET", POST="POST", PUT="PUT", DELETE="DELETE"
}

export enum RESPONSE_TYPE {
    JSON_OBJECT="JSON_OBJECT", JSON_ARRAY="JSON_ARRAY"
    //  ,CSV="CSV", VALUE="VALUE"
}

export enum PERIOD_UNIT {
    SECONDS="SECONDS",MINUTES="MINUTES",HOURS="HOURS",DAYS="DAYS",YEARS="YEARS"
    //  ,CSV="CSV", VALUE="VALUE"
}

export enum CHECK_TYPE {
    LIST= "LIST",
    KEY_VALUE="KEY_VALUE"
    // remote="remote"
}

export enum CHANNNEL_TYPE {
    SLACK= "SLACK"
    // EMAIL="EMAIL"
    
}
export enum EXTRA_DS {
    EXTERNAL_URL = "EXTERNAL_URL"
}
export const DB_TYPES = ["MYSQL","POSTGRES"];
export const AUTH_STORAGE_KEY = "auth";

export enum COLOR_SCHEMA_TYPE {
    COLUMN = "COLUMN",
    VALUE = "VALUE",
    COLUMN_RANGE = "COLUMN_RANGE"
}

export const COLOR_CLASSES = [
    "red",
    "light-red",
    "blue",
    "light-blue",

]

export enum COMPARISON_TYPES {
    EQUALS = "EQUALS",
    LESS_THAN = "LESS_THAN",
    LESS_THAN_EQUAL = "LESS_THAN_EQUAL",
    GREATER_THAN = "GREATER_THAN",
    GREATER_THAN_EQUAL = "GREATER_THAN_EQUAL",
    NOT_EQUAL = "NOT_EQUAL"
}

export enum PIN_OPTIONS {
    ENABLE = "ENABLE",
    DISABLE = "DISABLE"
}

export enum SUMMARY_OPERATION_TYPES {
    COUNT = 'COUNT',
    SUM = "SUM"
}

export const LOCAL_PROXY = "quince-rr-local";

export const SNAPSHOT_DB_LATEST = "LATEST";

export const EDITOR_SCREENS = ['datasource','distribution']
export enum USER_ROLES {
    EDITOR = "EDITOR",
    VIEWER = "VIEWER"
}

export const COLUMNDETAILS_DASHBOARD= [
    {
        accessorKey: 'scenarioName', 
        header: 'SCENARIO NAME',
        enableGrouping: true
    },
    {
        accessorKey: 'queryName',
        header: 'QUERY NAME',
        enableGrouping: false,
        enablePinning:false
    },
    {
        accessorKey: 'summary', 
        header: 'SUMMARY',
        enableGrouping: false
    },
    {
        accessorKey: 'updatedAt', 
        header: 'UPDATED AT',
        enableGrouping: false
    },
    {
        accessorKey: 'view', 
        header: 'ACTION',
        enableGrouping: false
    }
  ]


  export const COLUMNDETAILS_SCENARIOS= [
    {
        accessorKey: 'name', 
        header: 'NAME',
        enableGrouping: false,
        enablePinning:false,
        enableFiltering:false
    },
    {
        accessorKey: 'namedesc', 
        header: 'namedesc',
        enableGrouping: false,
        enablePinning:false,
        enableHiding:true
    },
    {
        accessorKey: 'date',
        header: 'date',
        enableGrouping: false,
        enablePinning:false
    },
    {
        accessorKey: 'createdDate',
        header: 'CREATED ON',
        enableGrouping: false,
        enablePinning:false
    },
    {
        accessorKey: 'actions', 
        header: 'ACTIONS',
        enableGrouping: false,
        enablePinning:false
    },
    
  ]


  
  export const COLUMNDETAILS_AUDIT= [
    {
        accessorKey: 'id', 
        header: 'ID',
        enableGrouping: false,
        enablePinning:false,
        enableFiltering:false
    },
    {
        accessorKey: 'checkId', 
        header: 'CHECK ID',
        enableGrouping: false,
        enablePinning:false,
        enableHiding:true
    },
    {
        accessorKey: 'scenarioId',
        header: 'SCENARIO ID',
        enableGrouping: false,
        enablePinning:false
    },
    {
        accessorKey: 'executionId',
        header: 'EXECUTION ID',
        enableGrouping: false,
        enablePinning:false
    },
    {
        accessorKey: 'timeStamp', 
        header: 'TIME STAMP',
        enableGrouping: false,
        enablePinning:false,
        enableColumnFilter:false
    },
    {
        accessorKey: 'status', 
        header: 'STATUS',
        enableGrouping: false,
        enablePinning:false,
        enableColumnFilter:false
    },
    {
        accessorKey: 'state', 
        header: 'state',
        enableGrouping: false,
        enablePinning:false
    },
    {
        accessorKey: 'action', 
        header: 'DETAILS',
        enableGrouping: false,
        enablePinning:false,
        enableColumnFilter:false
    },

    
  ]