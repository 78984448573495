import InputField from "components/common/InputField";
import Modal from "components/common/Modal"
import { useState } from "react";
type TProps = {
    show: boolean;
    onSnapshotSave: Function;
    queryDetails: any;
    onClose: Function;
}
const SaveSnapshotDialog = (props: TProps) => {
    const { show, onSnapshotSave, queryDetails, onClose } = props;
    const [snapshotName, setSnapshotName] = useState<string>("");
    const [error, setError] = useState<string>("");

    const onSnapshotNameChangeHandler = (e: any) => {
        setSnapshotName(e.target.value);
        setError("");
    }

    const onSave = () => {
        if (snapshotName.length) {
            onSnapshotSave(snapshotName);
        } else {
            setError("Invalid name")
        }
    }
    return <Modal title="Save snapshot"
        show={show}
        onClose={onClose}
        onSubmit={onSave}
        submitText={"Save"}
        showButton={true}>
        <div className="save-snapshot">
            <div className="summary">
                <div className="details"><span className="label">Query name: </span><span className="content">{queryDetails.name}</span></div>
                <div className="details"><span className="label">Executed query: </span><span className="content">{queryDetails.executedQuery}</span></div>
                <div className="details"><span className="label">Executed time: </span><span className="content">{queryDetails.executedTime}</span></div>
            </div>
            <InputField
                label="Save snapshot as:"
                type="text"
                onChangeHandler={onSnapshotNameChangeHandler}
                value={snapshotName}
                classList={["save-field"]}
                error={error}
            />
        </div>
    </Modal>
}

export default SaveSnapshotDialog;