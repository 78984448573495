import { getRole } from "utils/helperFunctions";
import CreateDashboard from "./CreateDashboard";

const EmptyDashboard = ()=>{
    return <div className="empty-db">
        <p>Create new dashboard</p>
        {getRole()=== 'VIEWER'?null:<div><CreateDashboard /></div>}
    </div>
}

export default EmptyDashboard;