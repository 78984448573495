import sampleData from './SampleData.json'; //TODO: Replace with actual data
import TrailItem from './TrailItem';
import './style.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuditTrailsWrapper } from 'store/actions';
import _ from 'lodash';
import TabularView from 'components/common/Table';
import { COLUMNDETAILS_AUDIT } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import Button, { TYPES } from 'components/common/Button';
import TooltipComponent from 'components/common/Tooltip';
import { BiError, BiErrorCircle } from 'react-icons/bi';


type TProps = {};
const INITIAL_DATA=[{
    id: '', queryId: '',
    scenarioId:'',executionId:'', 
    status:'', timeStamp:''
}]
const AuditTrails = (props: TProps) => {
    const auditTrails = useSelector((state:any)=>state.auditTrails.data);
    const dataLoaded = useSelector((state:any)=>state.auditTrails.isFixCompleted);
    const [row,setRow] = useState(INITIAL_DATA);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        if(dataLoaded)
        { 
            dispatch(getAuditTrailsWrapper())
        }
    },[auditTrails])

    const onViewDiffClick = (id:number) => {
        navigate('../trail/diff/' + id);
    };

    useEffect(() => {
        setRow(INITIAL_DATA);
        
        let obj: any = {};
         let x=  auditTrails.map((item: any, index: number) => {
            if(!_.isEmpty(item))
                {
                let date= item.timestamp.split(',')
                obj={}
                obj['id'] = item.id
                obj['checkId'] = item.checkId
                obj['scenarioId'] = item.scenarioId
                obj['executionId'] = item.executionId
                obj['date'] = item.timestamp
                obj['timeStamp'] = <div>{date[0]} {date[1]}<div style={{color:'#919191', fontSize:'12px'}}>{date[2]}</div></div>
                obj['status'] = item.status=='FAILURE'?<div className='status red'>{item.status}</div>:<div className='status green'>{item.status}</div>
                obj['state'] = item.status.toLowerCase()
                obj['action']= <div className='view-button'>
                {item.status=='SUCCESS' ? (
                    <Button
                        buttonText="View Details"
                        onClick={()=>onViewDiffClick(item.id)}
                        type={TYPES.BLACK_OUTLINED}
                    />
                ) : <>Error<TooltipComponent title={<div style={{width:'200px'}}>{item.errorMessage}</div>} icon={<BiErrorCircle size={17} color={'red'}/>}/></>}
                </div>
                return obj;
                }
               else return null
        });
        var newArray = x.filter((value:any) => Object.keys(value).length !== 0);
        setRow(newArray);
       
    },[auditTrails]);

    return (
        <div className="audit-trails">
            <div className="trail-container">
                {row && <TabularView filterRequired={true} columns={COLUMNDETAILS_AUDIT} rows={row} columnToHide={'state'} width={window.innerWidth>=1680?100:50} tablewidth={'87vw'} />}
            </div>
        </div>
    );
};

export default AuditTrails;
