import { MouseEventHandler } from 'react';
import './style.scss';

type TProps = {
    buttonText: string|JSX.Element;
    onClick: MouseEventHandler;
    classList?: string[];
    type?: TYPES;
    disabled?: boolean;
    width?: string;
    title?:string;
};

const Button = (props: TProps) => {
    const { buttonText, onClick, type = TYPES.PRIMARY, classList = [], disabled, width, title } = props;
    
    return (
        <button
            onClick={onClick}
            className={`button ${type} ${classList.join(' ')} ${disabled?'disabled-btn':''}`}
            disabled={disabled}
            data-title={title}
            style={{
                width: width,
                minWidth: width
            }}>
            {buttonText}
        </button>
    );
};

export enum TYPES {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERIARY = 'teriary',
    RED_THEME = 'red-theme',
    GREEN_THEME = 'green-theme',
    PRIMARY_OUTLINED = 'primary-outlined',
    PRIMARY_NOT_OUTLINED = 'primary-not-outlined',
    BLACK_OUTLINED='black-outlined',
    ICON = 'icon-label',
    SMALL_GREEN = 'small-green-theme'
}

export default Button;
