import {DATASOURCE_TYPE, DB_TYPES} from 'utils/constants';

const credentialConfig = {
    [DATASOURCE_TYPE.API]:{
        url: '',
        key: ''
    },
    [DATASOURCE_TYPE.SQL]:{
        username: '',
        password: '',
        jdbcUrl: '',
        dbType: DB_TYPES[0]
    },
    [DATASOURCE_TYPE.CLOUD_WATCH_METRICS]:'',
    [DATASOURCE_TYPE.CLOUD_WATCH_QUERY_INSIGHTS]:''
    // [DATASOURCE_TYPE.remote]:{
    //    readOnly: true,
    //    datasourceName: ''
    // }
}

export default credentialConfig;