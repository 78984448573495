import { TQueryData } from 'utils/model';
import _ from 'lodash';
import Button, { TYPES } from 'components/common/Button';
import { useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { EXTRA_DS, summaryOrder } from 'utils/constants';
import TooltipComponent from 'components/common/Tooltip';
import { BiInfoCircle } from 'react-icons/bi';
import QueryDetailsTable from '../QueryDetailsTable';

type TProps = {
    index: number;
    data: TQueryData;
    onEdit: Function;
    onDelete: Function;
    onBack: Function;
    onNext: Function;
};

const Summary = (props: TProps) => {
    const { data, onEdit, index, onDelete, onBack, onNext } = props;
    let dataCloned: any = _.cloneDeep(data);
    useEffect(() => {
        dataCloned = _.cloneDeep(data);
    });

    const getJSXValueComponent = (key: string, value: any) => {
     
        if (key === 'colorConfig' && typeof value !== 'string') {
            return JSON.stringify(value);
        } else if (key === 'linkedInvestigationScenarioId' || key === 'linkedFixScenarioId') {
            if(value === null){
                return 'Nil'
            }
            else if (typeof value === 'object') {
                return value.id;
            }
            return value;
        }
        else if (Array.isArray(value)) {
            return JSON.stringify(value)
        } else if (typeof value == 'object') {
            return JSON.stringify(value)
        } else if (typeof value == 'string' && _.isEmpty(value)) {
            return 'Nil';
        }
        if(typeof value === 'boolean'){
            return value+'';
        }
        return value;
    }

    const getKeyValueComponent = (key: string, value: any) => {
        if (key == 'editMode') return null;

        if (key == 'currentData') return null;
        return (
            <div className="key-value-wrapper">
                <div className="key">{_.startCase(key)}</div>
                <div className="value">
                    {getJSXValueComponent(key,value)}
                </div>
            </div>
        );
    };
    const getQueryDetails=(values:any)=>{
        return <div><span className="label query">Queries</span>
        <QueryDetailsTable values={values} width={'95%'}/>
       </div>
     }
    return (
        <div className="de-summary">
            <div className="data-container">
                {data && summaryOrder?.map((item: any, key) => {
                    return dataCloned[item] || typeof dataCloned[item] === 'boolean'
                        ? item!='queries'?getKeyValueComponent(item, dataCloned[item]):getQueryDetails(dataCloned['queries'])
                        : null;
                })}
            </div>

            <div className="button-container">
                <Button
                    buttonText={'Next'}
                    onClick={() => onNext(3)}
                    classList={['add-update-btn']}
                    type={TYPES.PRIMARY_OUTLINED}
                />
                <Button
                    buttonText={'Save Extraction Method'}
                    onClick={() => { }}
                    classList={['add-update-btn']}
                    type={TYPES.PRIMARY}
                    disabled={true}
                />
                <Button
                    buttonText={'Back'}
                    onClick={() => onBack(1)}
                    classList={['add-update-btn']}
                    type={TYPES.PRIMARY_OUTLINED}
                />
            </div>
        </div>
    );
};

export default Summary;
