import _ from 'lodash';
import DynamicForm from 'components/common/DynamicForm';
import Button, { TYPES } from 'components/common/Button';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateFetchVariableData,
    updateUpdateVariableData
} from 'store/reducers/scenarioDetailsSlice';
import { DATASOURCE_TYPE, LOCAL_PROXY, SCENARIO_TYPES, USER_ROLES } from 'utils/constants';
import React, { useEffect, useRef, useState } from 'react';
import { RiArrowUpSLine } from 'react-icons/ri';
import { TQueryData, TQueryDetail } from 'utils/model';
import { useLocation } from 'react-router-dom';
import { fetchProxyDSList, fetchProxyList } from 'apis';
import Select, { FIELD_TYPES } from 'components/common/Select';
import { getRole } from 'utils/helperFunctions';

type TProps = {
    data: any;
    onExecute: Function;
    scenarioId: number;
};

const ExecuteForm = (props: TProps) => {
    const { data, onExecute, scenarioId } = props;
    const [isCollpased, setIscollapsed] = useState(false);
    const dispatch = useDispatch();
    const executeFormRef: any = useRef(null);
    const executeContainerRef: any = useRef(null);
    const [formData, setFormData] = useState<any>({});
    const [proxyId, setProxyId] = useState(LOCAL_PROXY);
    const [queryDSMap, setQueryDSMap] = useState<any>([]);
    const [proxyList, setProxyList] = useState<String[]>([LOCAL_PROXY]);
    const [loading, setLoading] = useState<boolean>(false);
    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const selectedsnapshot = useSelector((state: any) => state.consoleDashboard.selectedsnapshot);
    const locationData: any = useLocation();
    const [validDSList, setValidDSList] = useState<String[]>([]);
    const [errorObj, setErrorObj] = useState<any>({});
    const datasources = useSelector((state: any) => state.datasource.datasources);

    const loadProxyList = async () => {
        try {
            setLoading(true);
            const response = await fetchProxyList();
            setProxyList([...proxyList, ...(response.data ?? [])]);
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    const updateInitialFormData = () => {
        let tempQueryFormData: any = {};
        data.checks.forEach((checkItem:TQueryData)=>{
            checkItem.queries.forEach((queryItem: any) => {
                tempQueryFormData = { ...tempQueryFormData, ...queryItem.fetchVariableData };
                if (
                    data.scenarioType === SCENARIO_TYPES.FIX &&
                    (!_.isEmpty(queryItem.update.updateVariableData) ||
                        queryItem.type === DATASOURCE_TYPE.API)
                ) {
                    tempQueryFormData = {
                        ...tempQueryFormData,
                        ...queryItem.update.updateVariableData,
                        ...(queryItem.type === DATASOURCE_TYPE.API && {
                            request: queryItem.update.request
                        })
                    };
                }
            });
        });
        for (let key in tempQueryFormData) {
            tempQueryFormData[key] = '';
        }
        setFormData(tempQueryFormData);
    };

    const setInitialExecutionData = () => {
        if (locationData.state?.isDashboardEnabled || getRole() === USER_ROLES.VIEWER) {
            setProxyId(selectedDashboard.proxyId);
            setQueryDSMap(_.filter(selectedDashboard.dashboardQueries,{scenarioId: data.id}));
        } else {
            loadProxyList();
            const initialDSMap = data.checks.flatMap((checkItem: TQueryData) => {
                return checkItem.queries.map((queryItem:any)=>{
                    return {
                        queryId: queryItem.id,
                        datasourceName: '',
                        type:queryItem.type
                    };
                })
            });
            setQueryDSMap(initialDSMap);
        }
    };
    
    useEffect(() => {
        if (selectedDashboard) {
            updateInitialFormData();
            setInitialExecutionData();
        }
    }, [selectedDashboard, data.checks]);

    const updateDatasourceList = async () => {
        if (proxyId === LOCAL_PROXY) {
            setValidDSList(datasources.map((dsItem: any) => dsItem.title));
        } else {
            try {
                const response = await fetchProxyDSList(proxyId);
                setValidDSList(
                    response.data ? response.data.map((dsItem: any) => dsItem.name) : []
                );
            } catch (e) {
                setValidDSList([]);
            }
        }
    };
    useEffect(() => {
        updateDatasourceList();
    }, [proxyId, datasources]);

    const getFormDataComponent = () => {
        if (_.isEmpty(formData)) {
            return null;
        }
        return (
            <div className="form-input">
                <DynamicForm
                    title={'Enter Variable values'}
                    data={formData}
                    onChangeHandler={setFormData}
                />
            </div>
        );
    };
    const setDatasourceName = (queryId: number, value: string, type:string) => {
        const updateDSMap = _.cloneDeep(queryDSMap);
        const dsIndex = _.findIndex(updateDSMap, { queryId });
        updateDSMap[dsIndex].datasourceName = value;
        updateDSMap[dsIndex].type = type;
        setQueryDSMap(updateDSMap);
    };

    const getDatasourceSelectComponent = (queryItem: any, type:string) => {
        let dataSourceRequired:boolean = true;
        if(type!=DATASOURCE_TYPE.API && type!=DATASOURCE_TYPE.SQL)
        {
        dataSourceRequired= false;
        }
        else 
        {  
        dataSourceRequired= true;
        }
        const dsObject = _.find(queryDSMap, { queryId: queryItem.id });
        if (!dsObject) return null;
        return (
            <div>
                <Select
                    label="Select datasource"
                    value={dsObject.datasourceName}
                    onChangeHandler={(e: any, value: any) => setDatasourceName(queryItem.id, value, type)}
                    options={validDSList}
                    fieldType={FIELD_TYPES.TYPE_1}
                    width={'250px'}
                    required={dataSourceRequired}
                    error={errorObj[queryItem.id] ? 'Invalid dataSource' : ''}
                    disabled={!dataSourceRequired || locationData.state?.isDashboardEnabled || getRole() === USER_ROLES.VIEWER}
                />
            </div>
        );
    };

    const getQueryListComponent = () => {
        return (
            <div className="query-list">
                <ul>
                    {data.checks.flatMap((checkItem: TQueryData) => {
                        return checkItem.queries.map((queryItem:TQueryDetail)=>{
                            return (
                                <li>
                                    <div className="title">{queryItem.name}</div>
                                    <p>
                                        <span className="label">Fetch query: </span>
                                        <span>{queryItem.fetchQuery}</span>
                                    </p>
                                    {data.scenarioType === SCENARIO_TYPES.FIX ? (
                                        <p>
                                            <span className="label">Update query: </span>
                                            <span>{queryItem.updateQuery}</span>
                                        </p>
                                    ) : null}
                                    {getDatasourceSelectComponent(queryItem, queryItem.type)}
                                </li>
                            );
                        })
                    })}
                </ul>
            </div>
        );
    };
    const updateVariableValues = async () => {
        for(let checkItem of data.checks){
            for (let queryItem of checkItem.queries) {
                if (!_.isEmpty(queryItem.fetchVariableData)) {
                    onFetchVariableUpdate(queryItem, checkItem.id);
                }
                if (
                    data.scenarioType === SCENARIO_TYPES.FIX &&
                    (!_.isEmpty(queryItem.update.updateVariableData) ||
                        queryItem.type === DATASOURCE_TYPE.API)
                ) {
                    onUpdateVariableUpdate(queryItem, checkItem.id);
                }
            }
        }
    };
    const onFetchVariableUpdate = (queryItem: any, checkId: number) => {
        let tempFetchVariableData = _.clone(queryItem.fetchVariableData);
        for (let key of _.keys(queryItem.fetchVariableData)) {
            tempFetchVariableData[key] = formData[key];
        }
        dispatch(
            updateFetchVariableData({
                queryId: queryItem.id,
                fetchVariableData: tempFetchVariableData,
                scenarioId,
                checkId
            })
        );
    };

    const onUpdateVariableUpdate = (queryItem: any, checkId: number) => {
        let tempUpdateVariableData = _.cloneDeep(queryItem.update.updateVariableData);
        for (let key of _.keys(queryItem.update.updateVariableData)) {
            tempUpdateVariableData[key] = formData[key];
        }
        if (queryItem.type === DATASOURCE_TYPE.API) {
            tempUpdateVariableData['request'] = formData['request'];
        }
        dispatch(
            updateUpdateVariableData({
                queryId: queryItem.id,
                updateVariableData: tempUpdateVariableData,
                scenarioId,
                checkId
            })
        );
    };

    const collapseFormData = () => {
        if (executeFormRef.current) {
            executeFormRef.current.style.height = '0%';
            executeContainerRef.current.style.display = 'contents';
            setIscollapsed(true);
        }
    };

    const expandFormData = () => {
        if (executeFormRef.current) {
            executeFormRef.current.style.height = '100%';
            executeContainerRef.current.style.display = 'block';
            setIscollapsed(false);
        }
    };

    const onFormExecution = async () => {
        let isValid = true;
        const Obj: any = {};
        for (const qIndex in queryDSMap) {
            const qItem = queryDSMap[qIndex];
            if (queryDSMap[qIndex].datasourceName == '' && (queryDSMap[qIndex].type==DATASOURCE_TYPE.API || queryDSMap[qIndex].type==DATASOURCE_TYPE.SQL)) {
                isValid = false;
                if (Obj[qItem.queryId]) {
                    Obj[qItem.queryId][qItem.datasourceName] = 'Invalid datasource';
                } else {
                    Obj[qItem.queryId] = {};
                    Obj[qItem.queryId][qItem.datasourceName] = 'Invalid datasource';
                }
            }
        }
        if (isValid) {
            await updateVariableValues();
            onExecute({ proxyId, queryDsMap: queryDSMap });
            collapseFormData();
        } else {
            setErrorObj(Obj);
        }
    };
    return (
        <div className="execute" ref={executeContainerRef}>
            <div className="form-wrapper" ref={executeFormRef}>
                <div className="head-section">
                    <h2>{data.title}</h2>
                    <div onClick={collapseFormData} className="collapse-btn">
                        collapse <RiArrowUpSLine />
                    </div>
                </div>
                <div className="form-content">
                    <div className="left-section">
                        <div className="proxy-container">
                            <Select
                                label="Proxy ID"
                                value={proxyId}
                                onChangeHandler={(e: any, value: any) => setProxyId(value)}
                                options={proxyList}
                                fieldType={FIELD_TYPES.TYPE_1}
                                required={true}
                                disabled={locationData.state?.isDashboardEnabled || getRole() === USER_ROLES.VIEWER}
                            />
                        </div>
                        {getFormDataComponent()}
                    </div>
                    {getQueryListComponent()}
                </div>
                <div className={`execute-btn-container`}>
                    <Button
                        buttonText="Execute"
                        onClick={() => onFormExecution()}
                        classList={['float-right']}
                    />
                </div>
            </div>
            {isCollpased ? (
                <div
                    className={`execute-btn-container ${isCollpased ? 'collpased' : ''}`}
                    onClick={isCollpased ? expandFormData : undefined}>
                    <div className="collapsed-title">{data.title}</div>
                    <Button
                        buttonText="Execute"
                        onClick={() => onFormExecution()}
                        classList={['float-right']}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default ExecuteForm;