

import {TDashboardData, TDBQueryData, TDBScenarioData } from 'utils/model';

import Modal from 'components/common/Modal';
import _ from 'lodash';
import './style.scss';
import Wizard from 'components/common/Wizard';
import BasicInfo from './BasicInfo';
import ScenarioAndDs from './ScenarioDs';
import Confirmation from './Confirmation';
import { useEffect, useState } from 'react';
import { createDashboard, updateDashboard } from 'apis';
import { useDispatch, useSelector } from 'react-redux';
import { getConsoleDashboardonRefresh, getConsoleDashboardWrapper, getDashboardListWrapper, getDistributionWrapper } from 'store/actions';
import { setDashboardList, setSelectedDashboard } from 'store/reducers/consoleDashboardSlice';

type TProps = {
    formData: TDashboardData;
    onClose: Function;
    show: boolean;
    isUpdate?: boolean;

};

const INITIAL_DATA: TDashboardData = {
    // id?: '',
    name: '',
    description: '',
    scheduleHours: [],
    distributionTarget: '',
    proxyId: '',
    scenarios: []
   
};

const CreateorUpdateDashboard = (props: TProps) => {
    const dashboardList = useSelector((state: any) => state.consoleDashboard.dashboardList);
    const { formData, show, onClose, isUpdate } = props;
    const [createData, setCreateData] = useState<TDashboardData>(INITIAL_DATA);
    const [stage,setStage] = useState(1);
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    const setFormDetails = (detail: any) => {
        const updatedData = { ...createData, ...detail };
        setCreateData(updatedData);
        setStage(stage + 1);
    };

    const onSubmit = async ()=>{
        setError('');
        try{
            const isEmptyDbList = dashboardList.length === 0;
            const request:any = getDBCreateOrUpdate(createData);
            const apiFunction = isUpdate? updateDashboard :createDashboard;
            const response:any = await apiFunction(request);
            if(response.data.success == false){
                throw response.data.message;
            }else{
               
                if(isEmptyDbList){dispatch(setDashboardList({data:response.data.data,type:'new'})); dispatch(setSelectedDashboard(response.data.data)); 
             }
                else if(isUpdate){dispatch(setDashboardList({data:response.data.data,type:'update'}));dispatch(setSelectedDashboard(response.data.data));
            }
                else {dispatch(setDashboardList({data:response.data.data,type:'new'}));dispatch(setSelectedDashboard(response.data.data))}
                await dispatch(
                    getConsoleDashboardonRefresh({ dashboardId: response.data.data.id})
                );
                
            }
            onClose();
        }catch(e){
            setError("Failed to save Dashboard");
        }
    }
    useEffect(() => {
        if (show) {
            setCreateData(formData);
            setError('');
            setStage(1)
        };
    }, [show]);
    
    const gotoPrevious = (index: number) => {
        setStage(index);
    };

    let stages: any[] = [
        {
            label: 'Basic Info',
            component: (
               <BasicInfo formData={createData} onBaseSave={setFormDetails}/>
            )
        },

        {
            label: 'Scenario & Datasource',
            component: (
               <ScenarioAndDs formData={createData} onScenarioSave={setFormDetails} gotoPrevious={gotoPrevious}/>
            )
        },

        {
            label: 'Confirmation',
            component: (
               <Confirmation formData={createData} onSubmit={onSubmit} onBack={gotoPrevious} error={error} isUpdate={isUpdate}/>
            )
        }
    ];
    return (
        <div className="create-scenario">
            
        <Modal
            show={show}
            onClose={onClose}
            title={isUpdate?'Update Dashboard':'Create New Dashboard'}
            onSubmit={onSubmit}
            submitText={''}
            fullSize={true}
            showButton={false}>
            <div className="de-container">
                <Wizard steps={stages} stage={stage}></Wizard>
            </div>
        </Modal>
    </div>
    );
};

export default CreateorUpdateDashboard;

const getDBCreateOrUpdate = (formData: TDashboardData) => {
    const request = {
        id: formData.id??null,
        tenantId: null,
        distributionId: null,
        distributionTarget:formData.distributionTarget,
        proxyId: formData.proxyId,
        name: formData.name,
        scheduleHours: Array.isArray(formData.scheduleHours) ? formData.scheduleHours.join(',') : formData.scheduleHours,
        dashboardQueries: formData.scenarios.flatMap((scenario: TDBScenarioData) => {
            return scenario.queries.flatMap((query: any) => {
                    return {
                        id: query.id,
                        scenarioId: scenario.id,
                        queryId: query.queryId,
                        datasourceName: query.datasourceName,
                        type: query.type
                    }
                
            })
        })
    }
    return request;
}


