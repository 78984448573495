import SummaryItemList from 'components/common/SummaryItemList';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addDistribution,
    getDistributionWrapper,
    removeDistribution,
    updateDistributionChannel
} from 'store/actions';
import _ from 'lodash';
import './style.scss';
import Dialog from 'components/common/Dialog';
import { getRole } from 'utils/helperFunctions';
import CreateDistribution from './CreateDistribution';
import { TDistribution } from 'utils/model';
import { CHANNNEL_TYPE, COLUMNDETAILS_SCENARIOS } from 'utils/constants';
import credentialConfig from './credentialConfig';
import { setReloadRequired } from 'store/reducers/consoleDashboardSlice';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import TabularView from 'components/common/Table';
type TProps = {
    data: any[];
};
const INITIAL_DATA = {
    name: '',
    channelType: CHANNNEL_TYPE.SLACK,
    channelDetails: credentialConfig[CHANNNEL_TYPE.SLACK]
};
const Distribution = (props: TProps) => {
    const data = useSelector((state: any) => state.distribution.distribution);
    const [showDialog, setShowDialog] = useState(false);
    const [deleteIndex, setIndex] = useState(0);
    const [editIndex, setEditIndex] = useState(-1);
    const [editData, setEditData] = useState<TDistribution>(INITIAL_DATA);
    const [row,setRow] = useState(INITIAL_DATA);
    const dispatch = useDispatch();

    const loadData = async () => {
        await dispatch(getDistributionWrapper());
    };

    useEffect(() => {
        if(!data)
        loadData();
    }, []);

    const onSubmit = () => {
        setShowDialog(false);
        const { id } = data[deleteIndex];
        dispatch(removeDistribution(id));
        dispatch(setReloadRequired(true));
    };

    const onClose = () => {
        setShowDialog(false);
    };

    const OpenDialog = (index: number) => {
        setShowDialog(true);
        setIndex(index);
    };

    const onEdit = (index: any) => {
        setEditIndex(index);
        setEditData(data[index]);
    };

    const onAddorUpdate = async (formData: TDistribution) => {
        if (editIndex != -1) {
            await dispatch(updateDistributionChannel(formData));
            dispatch(setReloadRequired(true));
        } else {
            await dispatch(addDistribution(formData));
        }
        setEditIndex(-1);
        setEditData(INITIAL_DATA);
    };

    const closeModal=()=>{
        setEditIndex(-1);
        setEditData(INITIAL_DATA);
    }

    useEffect(() => {
        setRow(INITIAL_DATA);
        let obj: any = {};
         let x=  data.map((item: any, index: number) => {
            if(!_.isEmpty(item))
                {
                let date= item.createdAt?.split(', ')
                obj={}
                obj['name'] = `${item.name}`
                obj['createdDate'] = item.createdAt?<><div>{date[0]}, {date[1]}</div><div className='date'>{date[2]}</div></>:'NA',
                obj['date'] = item.createdAt
                obj['actions']= <div className="action-column-list">
                <div className="delete-list" >
                <AiOutlineDelete size={20}  onClick={() =>{OpenDialog(index)}}/></div> 
                <AiOutlineEdit size={20} onClick={() => {onEdit(index)}} />
                </div>
                return obj;
                }
               else return null
        });
        var newArray = x.filter((value:any) => Object.keys(value).length !== 0);
        setRow(newArray);
    },[data]);

    return (
        <div>
            <Dialog
                show={showDialog}
                onCancel={onClose}
                onSubmit={onSubmit}
                message={`Are you sure you want to delete channel-  ${
                    showDialog ? data[deleteIndex].name : ''
                } ?`}
                title={'Delete DataSource'}></Dialog>
            {editIndex == -1 ? (
                <CreateDistribution submit={onAddorUpdate} closeModal={closeModal}/>
            ) : (
                <CreateDistribution data={editData} submit={onAddorUpdate} closeModal={closeModal}/>
            )}
              {data && row.name!='' && <TabularView filterRequired={false}  columns={COLUMNDETAILS_SCENARIOS} rows={row} columnToHide={'namedesc'} />}
        </div>
    );
};

export default Distribution;
