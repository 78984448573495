import Button, { TYPES } from '../Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './style.scss';

type TProps = {
    title: string;
    show: boolean;
    onCancel: Function;
    onSubmit: Function;
    message: string;
};

const Dialogbox = (props: TProps) => {
    const { show, onCancel, title, message, onSubmit } = props;
    return show ? (
        <div>
            <Dialog
                className="container"
                open={show}
                onClose={() => onCancel()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

                <div className="dialog-container">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={(e) => onCancel()}
                            buttonText={'Cancel'}
                            type={TYPES.TERIARY}
                        />
                        <Button
                            onClick={(e) => onSubmit()}
                            buttonText={'Delete'}
                            type={TYPES.RED_THEME}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    ) : null;
};

export default Dialogbox;
