let cloudWatch = JSON.stringify({
    start: '${startDate}',
    end: '${endDate}',
    period: 86400,
    metricName: 'ApproximateAgeOfOldestMessage',
    stat: 'Sum',
    nameSpace: 'AWS/SQS',
    id: 'exo',
    dimension: {
        QueueName: 'oms_refundEvent'
    }
});

let cloudWatchInsights = JSON.stringify({
    start: '${startDate}',
    end: '${endDate}',
    limit: 100,
    logGroupName: ['/ecs/adminPortalServices']
});

export const RESPONSE_PATH = (
    <div>
        If the response type is JSON_OBJECT user can set a path to required data here. <br />
        The format of response path Format: /path/to/data.
        <br />
        <br /> Ex: /data/orders
    </div>
);

export const SUMMARY_SQL = (
    <div>
        This is similar to summary SQL under checks. This used to generate value for each query
        under KV report.There can be two types of expression.
        <br />
        1. SQL Query This is a normal sql query which returns summaryValue as a column .
        <br />
        Ex: select count(*) as summaryValue from :table;
        <br />
        This is used for cases where the query Response type is JSON_ARRAY.
        <br />
        <br />
        2. JS Expression - These are used for the cases where the response type is JSON_OBJECT.
        <br />
        Ex: summaryValue = value_1 - value_2;
        <br />
        Here in this example value_1 and value_2 are keys in JSON response.
    </div>
);

export const VALIDATION_CRITERIA = (
    <div>
        This should be a JS expression that returns a boolean value based on values in summaryJson.
        <br />
        <br />
        Ex 1: summaryValue == 0
        <br />
        <br />
        Ex 2: summaryValue == 0 && oms_count == 0
    </div>
);

export const QUERY_SUMMARY_SQL = (
    <div>
        This is similar to summary SQL under checks. This used to generate value for each query
        under KV report.There can be two types of expression.
        <br />
        1. SQL Query This is a normal sql query which returns summaryValue as a column .
        <br />
        Ex: select count(*) as summaryValue from :table;
        <br />
        This is used for cases where the query Response type is JSON_ARRAY.
        <br />
        <br />
        2. JS Expression - These are used for the cases where the response type is JSON_OBJECT.
        <br />
        Ex: summaryValue = value_1 - value_2;
        <br />
        Here in this example value_1 and value_2 are keys in JSON response.
    </div>
);

export const IS_DYNAMIC = (
    <div>It is used in case we need to pass some date range in a query or payload. </div>
);

export const START_PERIOD = (
    <div>
        It is to create a start date time object. Value will be the number and unit is based on
        start period unit value. <br />
        If we give 3 days as a period value, the start date will be the current date minus 3 days.{' '}
        <br /> We can give negative values as well.
    </div>
);

export const END_PERIOD = (
    <div>
        It is to create an end date time object. Value will be the number and unit is based on end
        period unit value. <br />
        If we give 3 days as a period value, the end date will be the current date minus 3 days.{' '}
        <br />
        We can give negative values as well.
    </div>
);

export const START_PERIOD_UNIT = (
    <div>
        Start period unit is the unit for the start period. <br />
        It can be SECONDS/ MINUTES /DAYS/ YEARS/ HOURS. <br /> By default it will be DAYS
    </div>
);

export const END_PERIOD_UNIT = (
    <div>
        End period unit is the unit for the end period. <br />
        It can be SECONDS/ MINUTES /DAYS/ YEARS/ HOURS. <br /> By default it will be DAYS
    </div>
);

export const PERIOD_START_KEY = (
    <div>
        Start period key is the variable name that needs to be replaced with the generated start
        date time string in query or payload. <br />
        <br /> Ex:
        https://lastbrand.myshopify.com/admin/api/2023-01/orders.json?created_at_max= ${'startDate'}
        &created_at_min=${'endDate'}. <br />
        Here in this example startDate is the start period key. Server will replace the start period
        key with the actual value and execute the query. Same way we can use variables in payload as
        well.
    </div>
);

export const PERIOD_END_KEY = (
    <div>
        End period key is the variable name that needs to be replaced with the generated End date
        time string in query or payload. <br />
        <br />
        Ex: https://lastbrand.myshopify.com/admin/api/2023-01/orders.json?created_at_max=$
        {'startDate'}& created_at_min=${'endDate'}. <br /> Here in this example endDate is the end
        period key. Server will replace the end period key with the actual value and execute the
        query. Same way we can use variables in payload as well.
    </div>
);

export const TIMEFORMAT = (
    <div>
        Time format is the format that we will be using to generate start date and end date strings.{' '}
        <br />
        <br />
        Ex : dd'T'HH:mm:ss'Z'
    </div>
);

export const START_TIME = (
    <div>
        By default when we generate a start or end dateTIme object we will use current time. If a
        user needs custom time like 2 days minus from 7pm today we can use this field. <br /> <br />
        Ex: ${'startDate'} T08:00:00+05:30
    </div>
);

export const START_TIME_KEY = (
    <div>
        Start time key is the variable name where we have to replace it with a dynamic date in
        specified format. <br />
        <br />
        Ex: ${'startDate'}T08:00:00+05:30. <br />
        Here in this example startDate is the start time key.
    </div>
);

export const START_TIME_FORMAT = (
    <div>
        Start time format is the format of date string that needs to be generated to replace the
        start time key in start time object. <br />
        <br />
        Ex:Start time: ${'startDate'} T08:00:00+05:30, Start time key: startDate, Start time
        format: yyyy-MM-dd, Final result: 2023-04-06T08:00:00+05:30. <br />
        Date value in the above result is based on the executing date of the string.
    </div>
);

export const CLOUD_DETAILS = (
    <div>
        CLOUD WATCH METRICS <br />
        It does not have any query. Users can configure using the payload field.
        <br />
        <br />
        CLOUD WATCH INSIGHTS <br />
        It is configured by adding a query in the fetch query field and the rest of the
        configuration in the payload field similar to cloud watch metrics <br />
        <br />
        Ex: Fetch query: fields @timestamp, @message, @logStream, @log | filter @message like
        /Allocating order through IAS/ | parse @message "Allocating order through IAS *" as orderId
        | sort @timestamp desc # | limit 20
    </div>
);

export const PAYLOAD = (
    <div>
        Payload field takes only JSON formatted data.
        <br />CLOUD WATCH METRICS<br/>
        Sample Config {cloudWatch}<br/>
        <br />
        Start and end date can be configured using dynamic options or can be hard coded as well.<br/> Period value is in seconds. <br/>Id can
        be any string without or it can be empty. But Id key is mandatory.<br/> Fetch Query can be set
        to any value. If there is no value to add “-” or “NA” value.
        <br /><br/>CLOUD WATCH INSIGHTS<br/>Sample Config {cloudWatchInsights}<br/><br/>
        Start and end date can be configured using dynamic options or can be hard coded as well.
    </div>
);

