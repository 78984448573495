import Button, { TYPES } from 'components/common/Button';
import TooltipComponent from 'components/common/Tooltip';
import _ from 'lodash';
import React from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { summaryOrder } from 'utils/constants';
import QueryDetailsTable from './QueryDetailsTable';

type TProps = {
    data: any;
    onSave: Function;
    onBack: Function;
    updateStage: Function;
};

const Confirmation = (props: TProps) => {
    const { data, onSave, onBack, updateStage } = props;
    
    const getQueryValue = (key: string, value: any)=>{
       
         if (key === 'colorConfig' || key==='queries' && typeof value !== 'string') {
            return JSON.stringify(value);
        } else if (key === 'linkedInvestigationScenarioId' || key === 'linkedFixScenarioId') {
            if(value === null){
                return 'Nil'
            }
            else if (typeof value === 'object') {
                return value.id;
            }
            return value;
        }
        else if (Array.isArray(value)) {
            return value.join(', ');
        } else if (typeof value == 'object') {
            return JSON.stringify(value)
        } 
       else if (typeof value == 'string' && _.isEmpty(value)) {
            return 'Nil';
        }
        else  if( key =='dataInSummary')
        {
            return value==false?'false':'true'
        }
       
        return value
    }

    const getQueryDetails=(values:any)=>{
       return <div><span className="label query">&nbsp;&nbsp;Queries</span>
       <QueryDetailsTable values={values} width={'65.4vw'} />
       </div>
    }

    return (
        <div className="confirmation-outer-container">
            <div className="confirmation-container">
                <div className='scenario-summary'>
                    <div className='basic-details'>
                        <div className="col">
                            <div className="label">Title</div>
                            <div className='content'>{data['title']}</div>
                        </div>
                        <div className="col">
                            <div className="label">Scenario Type</div>
                            <div className='content'>{data['scenarioType']}</div>
                        </div>
                        {data['id'] != undefined && (
                            <React.Fragment>
                                <div className="col">
                                    <div className="label">Id</div>
                                    <div className='content'>{data['id']}</div>
                                </div>
                                <div className="col">
                                    <div className="label">User Id</div>
                                    <div className='content'>{data['userId']}</div>
                                </div>
                                <div className="col">
                                    <div className="label">
                                        Execution Frequency In Minute
                                    </div>
                                    <div className='content'>{data['executionFrequencyInMinute']}</div>
                                </div>
                                <div className="col">
                                    <div className="label">Created Date</div>
                                    <div className='content'>{data['createdDate']}</div>
                                </div>
                                <div className="col">
                                    <div className="label">Updated Date</div>
                                    <div className='content'>{data['updatedDate']}</div>
                                </div>
                                <div className="col">
                                    <div className="label">Schedule</div>
                                    <div className='content'>{data['schedule'].toString()}</div>
                                </div>
                            </React.Fragment>
                        )}
                        <div className="col">
                            <div className="label">Description</div>
                            <div className='content description'>{data['description']}</div>
                        </div>
                    </div>
                    <div className='queries'>
                        <div className='label'>Checks ({data['checks'].length})</div>
                        {data['checks'].map((item: any, key: number) => {
                            return <div className="query-data-table">
                               <div className='div-1'> {summaryOrder.map((i: any, subkey) => {
                                   return(
                                    i!='queries' ? 
                                     <div className='col' >
                                        <div className='label'>{_.startCase(i)}</div>
                                        <div className='content'>{getQueryValue(i,item[i])}</div>
                                    </div>:  null  )

                                })}</div>
                                <div className='query'>{getQueryDetails(item['queries'])}</div>
                            </div>
                        })}
                    </div>
                </div>

                <div className="button-container">
                    <Button
                        buttonText="Save"
                        onClick={() => {
                            onSave();
                            updateStage();
                        }}
                        type={TYPES.PRIMARY_OUTLINED}
                        classList={['float-right']}
                    />
                     <Button
                        buttonText="Back"
                        onClick={() => onBack(2)}
                        type={TYPES.PRIMARY_OUTLINED}
                        classList={['float-right']}
                    />
                </div>
            </div>
        </div>
    );
};

export default Confirmation;
