import './style.scss';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { COLOR_SCHEMA_TYPE, COMPARISON_TYPES, PIN_OPTIONS } from 'utils/constants';
import { copyToClipboardAPI, getClipboardTextForGrid } from 'utils/helperFunctions';
import FlashMessage from 'components/common/FlashMessage';
import ResponseCard from './ResponseCard';
import { useDispatch } from 'react-redux';

type TProps = {
    data: any[];
    scenarioId: number;
    isClearCacheEnabled: boolean;
    itemSelected?: number;
    isSnapshotEnabled?: boolean;
    executionDetails?:any;
    disableCache?:boolean;
    singleQuery?:boolean;
};

const QueryResponseComponent = (props: TProps) => {
    const { data, scenarioId, isClearCacheEnabled, itemSelected,isSnapshotEnabled,executionDetails, disableCache,singleQuery } = props;
    const [message, setMessage] = useState<string>('');
    const dispatch = useDispatch();
    const scollToRef = useRef<any>([]);
    const [item, setItem] = useState(itemSelected);
    let count = 1;

   
    const getColorConfig = (index: number) => {
        return _.isEmpty(data[index].colorConfig)
            ? []
            : typeof data[index].colorConfig === 'string'
            ? JSON.parse(data[index].colorConfig)
            : data[index].colorConfig;
    };

    const getColorClassSetterObject = (colorItem: any) => {
        return {
            [colorItem.colorClass]: (params: any) => {
                if (typeof colorItem.value === 'string' && typeof params.value === 'number') {
                    if (colorItem.comparisonType === COMPARISON_TYPES.GREATER_THAN) {
                        return params.value > parseInt(colorItem.value);
                    } else if (colorItem.comparisonType === COMPARISON_TYPES.GREATER_THAN_EQUAL) {
                        return params.value >= parseInt(colorItem.value);
                    } else if (colorItem.comparisonType === COMPARISON_TYPES.LESS_THAN) {
                        return params.value < parseInt(colorItem.value);
                    } else if (colorItem.comparisonType === COMPARISON_TYPES.LESS_THAN_EQUAL) {
                        return params.value <= parseInt(colorItem.value);
                    } else if (colorItem.comparisonType === COMPARISON_TYPES.NOT_EQUAL) {
                        return params.value != parseInt(colorItem.value);
                    }
                }
                return `${params.value}` === colorItem.value;
            }
        };
    };
    const getVaueTypeCellRule = (fieldItem: any) => {
        return _.reduce(
            fieldItem.colorScheme,
            (innerAcc, colorItem: any) => {
                return {
                    ...innerAcc,
                    ...getColorClassSetterObject(colorItem)
                };
            },
            {}
        );
    };
    const getColumnTypeCellRule = (fieldItem: any) => {
        return {
            [fieldItem.colorClass]: () => true
        };
    };

    const getRangeTypeCellRule = (fieldItem: any) => {
        return {
            [fieldItem.colorClass]: (params: any) => {
                const start: number = parseInt(fieldItem.range.startValue);
                const end: number = parseInt(fieldItem.range.endValue);
                if (
                    typeof start == 'number' &&
                    typeof end == 'number' &&
                    typeof params.value == 'number'
                ) {
                    return start <= params.value && params.value < end;
                }
                return false;
            }
        };
    };
    const getFieldWiseCellRules = useCallback((index: number) => {
        const colorConfig = getColorConfig(index);
        const cellRuleObj = _.reduce(
            colorConfig,
            (acc: Object, fieldItem: any) => {
                return {
                    ...acc,
                    [fieldItem.columnName]:
                        fieldItem.type == COLOR_SCHEMA_TYPE.COLUMN
                            ? getColumnTypeCellRule(fieldItem)
                            : fieldItem.type == COLOR_SCHEMA_TYPE.VALUE
                            ? getVaueTypeCellRule(fieldItem)
                            : fieldItem.type == COLOR_SCHEMA_TYPE.COLUMN_RANGE
                            ? getRangeTypeCellRule(fieldItem)
                            : {}
                };
            },
            {}
        );
        return cellRuleObj;
    }, []);

    const getFieldConfig = useCallback((index: number) => {
        const colorConfig = getColorConfig(index);
        const fieldConfig = _.reduce(
            colorConfig,
            (acc: Object, fieldItem: any) => {
                return {
                    ...acc,
                    [fieldItem.columnName]: {
                        pinned:
                            fieldItem.pinned && fieldItem.pinned === PIN_OPTIONS.ENABLE
                                ? 'left'
                                : undefined
                    }
                };
            },
            {}
        );
        return fieldConfig;
    }, []);

    const copyToClipBoard = async (gridData: any[], index: number) => {
        let dataString = getClipboardTextForGrid(gridData);
        dataString =
            data[index].name +
            '\n' +
            'Executed url:\t' +
            data[index].executedQuery +
            '\n\n' +
            dataString;
        const response: string = await copyToClipboardAPI(dataString);
        setMessage(response);
        setTimeout(() => {
            setMessage('');
        }, 4000);
    };

    const getItem = () => {
        if (item?.toString() != '' && item && count < 15) {
            scollToRef.current[item]?.scrollIntoView({ behaviour: 'smooth' });
            count++;
        }
    };
    useEffect(() => {
        setInterval(() => getItem(), 50);
    }, [itemSelected]);

    //add get field rules function
    return (
        <div className="query-response-section">
            <h4>Query response section</h4>
            {data.length && singleQuery==undefined? (
                <React.Fragment>
                    {data.map((item, index) => {
                        return (
                            <div
                                key={item.id}
                                className="query-response-item"
                                ref={(e) => (scollToRef.current[index] = e)}>
                                <FlashMessage message={message} />
                                <ResponseCard
                                    checkItem={item}
                                    index={index}
                                    copyToClipBoard={copyToClipBoard}
                                    getFieldConfig={getFieldConfig}
                                    getFieldWiseCellRules={getFieldWiseCellRules}
                                    scenarioId={scenarioId}
                                    isClearCacheEnabled={isClearCacheEnabled ?? false}
                                    isSnapshotEnabled={isSnapshotEnabled??false}
                                    executionDetails={executionDetails}
                                    disableCache={disableCache}
                                />
                            </div>
                        );
                    })}
                </React.Fragment>
            ) : (!_.isEmpty(data)?
                <div
                className="query-response-item">
                <FlashMessage message={message} />
                <ResponseCard
                    checkItem={data[0]}
                    index={0}
                    copyToClipBoard={copyToClipBoard}
                    getFieldConfig={getFieldConfig}
                    getFieldWiseCellRules={getFieldWiseCellRules}
                    scenarioId={scenarioId}
                    isClearCacheEnabled={isClearCacheEnabled ?? false}
                    isSnapshotEnabled={isSnapshotEnabled??false}
                    executionDetails={executionDetails}
                    disableCache={disableCache}
                />
            </div> :
                <div>No Queries configured</div>
            )}
           
        </div>
    );
};

export default QueryResponseComponent;
