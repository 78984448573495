import { Checkbox } from "@mui/material";
import { ChangeEvent } from "react";
import './style.scss';

type TProps = {
    value: boolean;
    onChangeHandler: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    label: string
}

const Index = (props: TProps) => {
    const { value, onChangeHandler, label } = props;

    return (
        <div className="checkbox-container">
            <label>{label}</label>
            <Checkbox
                checked={value}
                onChange={onChangeHandler}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </div>
    );
}

export default Index;