import { createSlice } from '@reduxjs/toolkit';
import {getAuditTrails} from 'store/actions';
import { parseJson } from 'utils/helperFunctions';

const initialState = {
    data: [],
    isFixCompleted:false
};

const reducers = {
    setFixComplete: (state: any, action: any) => {
        state.isFixCompleted = action.payload;
    },
};

const extraReducers: any = {
    [getAuditTrails.fulfilled]: (state: any, action: any) => {

        state.data = action.payload.data.map((trailItem: any) => {
            const updatedData = trailItem.updatedData
                ? typeof trailItem.updatedData == 'string'
                    ? parseJson(trailItem.updatedData)
                    : trailItem.updatedData : [];
            const previousData = trailItem.previousData
                ? typeof trailItem.previousData == 'string'
                    ? parseJson(trailItem.previousData)
                    : trailItem.previousData : [];
            return { ...trailItem, previousData, updatedData }
        });
        state.isFixCompleted=false;
        return state;
    }
};

export const AuditTrailSlice = createSlice({
    name: 'auditTrails',
    initialState,
    reducers,
    extraReducers
});

export const {setFixComplete} = AuditTrailSlice.actions;
export default AuditTrailSlice.reducer;