import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthStatus, updateAuthState } from 'store/reducers/AuthenticationSlice';
import './style.scss';
import { useSelector } from 'react-redux';
import { TUserDetails } from 'utils/model';
import _ from 'lodash';
import { logOut, updateAuthSessionDetails } from 'store/actions/authentication';
import { getAuth, signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../../firebase';
import { FcGoogle } from 'react-icons/fc';
import logo from '../../assets/Roadrunnr.svg';
import {  Illustration, Illustration_2 } from './Images';
import { userLogin } from 'apis';
import { setRole } from 'utils/helperFunctions';

const Login = () => {
    const authDetails: TUserDetails = useSelector((state: any) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loginError, setLoginError] = useState<string>('');
    const auth = getAuth();

    useEffect(() => {
        setInterval(()=>{auth.onAuthStateChanged(async (user: any) => {
            const updatedPayload = {
                ...authDetails,
                authToken: await user.getIdToken(),
                uid: user.uid,
                isAuthenticated: true,
                email: user.email
            };
            updateAuthSessionDetails(updatedPayload);
            dispatch(setAuthStatus(updatedPayload))
            dispatch(updateAuthState(updatedPayload));
        });},600000)
    },);


    const validateGoogleCredential = (email: string | null) => {
        if (email && (_.endsWith(email, '91social.com') || _.endsWith(email, 'onequince.com'))) {
            return true;
        }
        return false;
    };
    const getUserDetailsAndAuthenticate = async () => {
        const response = await userLogin();
        if (response.data && response.data.userRole) {
            setRole(response.data.userRole);
            navigate("/console/dashboard");
        }
        else{
            setLoginError('Invalid Email Id');
            logOut();
        }
    }
    const onGoogleSignIn = async () => {
        try {
            const response: any = await signInWithPopup(auth, provider);
            if (validateGoogleCredential(response.user.email)) {
                sessionStorage.setItem('photoURL',response.user.photoURL);
                const updatedPayload = {
                    ...authDetails,
                    authToken: response.user.accessToken,
                    uid: response.user.uid,
                    isAuthenticated: true,
                    email: response.user.email
                };
                updateAuthSessionDetails(updatedPayload);
                dispatch(setAuthStatus(updatedPayload))
                dispatch(updateAuthState(updatedPayload));
                getUserDetailsAndAuthenticate();
            } else {
                setLoginError('Invalid Email Id');
                logOut();
            }
        } catch (error: any) {
            console.log(error)
            setLoginError("Invalid email id");
        }
    };

    return (
        <div className="login">
            <div className="left-half">
                <div className="logo">
                    <img src={logo} className='logo-img'  />
                </div>
                <div className="tag">
                    makes <span>IT</span> reliable
                </div>
                <div className="message">Login to your dashboard</div>
                <div onClick={onGoogleSignIn} className="google-login">
                    <FcGoogle size={25} />
                    <div className="label">Login with Google</div>
                </div>
                {loginError ? <div className="error login-error">{loginError}</div> : null}
                <div className="pattern">
                    <Illustration_2 />
                    <div className="copy-right">
                        <p>All Rights Reserved 91social Private Limited 2022</p>
                    </div>
                </div>
            </div>
            <div className="right-half">
                <Illustration />
                <div className="copy-right">
                    <p>All Rights Reserved 91social Private Limited 2022</p>
                </div>
            </div>
        </div>
    );
};

export default Login;
