import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { logInWithEmailAndPassword } from 'store/actions/authentication';
import { TUserDetails } from 'utils/model';

const initialState: TUserDetails = {
    isAuthenticated: false,
    email: '',
    password: '',
    authToken: '',
    uid: ''
};

const reducers = {
    updateAuthState: (state: any, action: any) => {
       state = _.cloneDeep(action.payload);
       return state;
    },
    setAuthStatus: (state: any, action: any) => {
        const { isAuthenticated } = action.payload;
        sessionStorage.setItem("isAuthenticated",isAuthenticated)
        state.isAuthenticated = isAuthenticated;
    },
    signOut: (state:any, action:any) => {
        state = _.cloneDeep(initialState);
        return state;
    }
};

const extraReducers = {

}
export const authentiationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers,
    extraReducers
});

export const { updateAuthState, setAuthStatus, signOut } = authentiationSlice.actions;

export default authentiationSlice.reducer;
