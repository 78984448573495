import InputField from 'components/common/InputField';
import Select from 'components/common/Select';
import Credential from './Credential';
import { CHANNNEL_TYPE } from 'utils/constants';
import credentialConfig from './credentialConfig';
import { useState } from 'react';

type TProps = {
    formData: any;
    setFormData: Function;
    error: any;
};

const CreateDistributionForm = (props: TProps) => {
    const { formData, setFormData, error } = props;
    const setName = (e: any) => {
        setFormData({ ...formData, name: e.target.value });
    };

    const setCredentials = (key: string, value: string) => {
        setFormData({ ...formData, channelDetails: { ...formData.channelDetails, [key]: value } });
    };

    const setDistributionChannelType = (value: CHANNNEL_TYPE) => {
        setFormData({
            ...formData,
            channelType: value,
            channelDetails: credentialConfig[value] ?? formData.channelDetails
        });
    };

    const getOptions = () => {
        return Object.keys(CHANNNEL_TYPE);
    };

    return (
        <div className="create-datasource-form">
            <InputField
                label="Channel name"
                required={true}
                value={formData.name}
                onChangeHandler={setName}
                type={'text'}
                error={error.name}
            />
            
            <Select
                label="Distribution type"
                required={true}
                value={formData.channelType}
                onChangeHandler={(e: any, value: any) => {
                    setDistributionChannelType(value);
                }}
                options={getOptions()}
                error={error.channelType}
            />
            <Credential
                data={formData.channelDetails}
                onChangeHandler={setCredentials}
                error={error}
            />
        </div>
    );
};

export default CreateDistributionForm;
