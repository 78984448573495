import { configureStore } from '@reduxjs/toolkit';
import scenarioReducer from './reducers/scenarioSummarySlice';
import datasourceReducer from './reducers/datasourceSummarySlice';
import loaderReducer from './reducers/LoaderSlice';
import scenarioDetailsReducer from './reducers/scenarioDetailsSlice';
import auditTrailReducer from './reducers/AuditTrailSlice';
import authenticaionReducer from './reducers/AuthenticationSlice';
import errorReducer from './reducers/ErrorSlice';
import consoleDashboardReducer from './reducers/consoleDashboardSlice';
import distributionSlice from './reducers/distributionSlice';

const store = configureStore({
    reducer: {
        auth: authenticaionReducer,
        scenarios: scenarioReducer,
        datasource: datasourceReducer,
        loader: loaderReducer,
        scenarioDetails: scenarioDetailsReducer,
        auditTrails: auditTrailReducer,
        error: errorReducer,
        consoleDashboard: consoleDashboardReducer,
        distribution:distributionSlice
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
