import Button,{TYPES} from "components/common/Button";

const BtnCellRenderer  = (props:any)=> {
   
    const btnClickedHandler = () => {
     props.clicked(props.data);
    }
      return (
        <Button onClick={btnClickedHandler} buttonText={props.label} type={TYPES.SMALL_GREEN}/>
      )
  }

  export default BtnCellRenderer;