import { SCENARIO_TYPES } from 'utils/constants';
import { getDashboardListWrapper, getDatasourcesWrapper, getScenariosWrapper, getDistributionWrapper, getAuditTrailsWrapper } from './actions';
import { getHeader } from './actions/authentication';
import store from './index';
import { setReloadRequired } from './reducers/consoleDashboardSlice';

let isLoaded = false;
export const loadInitialScenarioData = async () => {
    const header = getHeader();
    if (!isLoaded && header && header.uid) {
        store.dispatch(setReloadRequired(true));
        await store.dispatch(getScenariosWrapper(SCENARIO_TYPES.INVESTIGATION));
        await store.dispatch(getScenariosWrapper(SCENARIO_TYPES.FIX));
        await store.dispatch(getDatasourcesWrapper());
        await store.dispatch(getScenariosWrapper(SCENARIO_TYPES.EXCEPTION));
        await store.dispatch(getDashboardListWrapper());
        await store.dispatch(getDistributionWrapper());
        await store.dispatch(getAuditTrailsWrapper())
        isLoaded = true;
    }
}