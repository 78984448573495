import { fetchProxyDSList, fetchProxyList } from 'apis';
import Button from 'components/common/Button';
import InputField from 'components/common/InputField';
import Select, { FIELD_TYPES as SELECT_FIELD_TYPES } from 'components/common/Select';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LOCAL_PROXY, scheduleTimeList } from 'utils/constants';
import { TDashboardData } from 'utils/model';

type TProps = {
    formData: TDashboardData;
    onBaseSave: Function;
};

const initialBaseDetails ={
    name: '',
    description: '',
    scheduleHours: [],
    distributionTarget: null,
    proxyId: ''
};
const MANDATORY_FIELDS = ['name','scheduleHours', 'proxyId' ];

const BasicInfo = (props: TProps) => {
    const { formData, onBaseSave } = props;
    const distributionData = useSelector((state: any) => state.distribution.distribution);
    const [baseDetails, setBaseDetail] = useState<any>(initialBaseDetails);
    const [loading, setLoading] = useState<boolean>(false);
    const [proxyList, setProxyList] = useState<String[]>([LOCAL_PROXY]);
    const [errorObj, setErrorObj] = useState<any>({});
    const distributionList = distributionData
    ? distributionData.map((data: any) => {
          return data.name;
      })
    : [];

   const loadProxyList = async ()=>{
    try{
        setLoading(true);
        const response = await fetchProxyList();
        setProxyList([...proxyList,...(response.data??[])])
    }
    catch(e){

    }finally{
        setLoading(false);
    }
   }

   useEffect(()=>{
    loadProxyList();
   },[]);

    const onChange = (key: string, value: any) => {
        setBaseDetail({ ...baseDetails, [key]: value });
    };

    const setInitialBaseDetails = ()=> {
        const tempBaseDetails = {
            name: formData.name,
            description: formData.description,
            scheduleHours:formData.scheduleHours,
            distributionTarget: formData.distributionTarget,
            proxyId: formData.proxyId.length==0?proxyList[0]: formData.proxyId
        };
        setBaseDetail(tempBaseDetails);
    }

    useEffect(() => {
        setInitialBaseDetails();
    }, []);

    const validate = () => {
        let isValid: boolean = true;
        const formDataTemp: any = _.cloneDeep(baseDetails);
        const errorObjTemp: any = _.cloneDeep(errorObj);
        for (let key in formDataTemp) {
            if (MANDATORY_FIELDS.includes(key) && _.isEmpty(formDataTemp[key])) {
                errorObjTemp[key] = 'Invalid ' + key;
                isValid = false;
            } else {
                errorObjTemp[key] = '';
            }
        }
        setErrorObj(errorObjTemp);
        isValid && onBaseSave(baseDetails);
        return isValid;
    };
    if(loading){
        return <div>Loading...</div>
    }
    return (
        <div className='basic-container'>
            <div>
                <InputField
                    label="Dashboard Name"
                    value={baseDetails.name}
                    onChangeHandler={(e) => onChange('name', e.target.value)}
                    type={'text'}
                    error={errorObj.name}
                    required={true}
                />
                {/* <InputField
                    label="Description"
                    value={baseDetails.description}
                    onChangeHandler={(e) => onChange('description', e.target.value)}
                    type={'text'}
                    error={errorObj.description}
                    required={true}
                /> */}
                <div className="split-row">
                    <Select
                        label="Schedule Hours"
                        value={baseDetails.scheduleHours}
                        onChangeHandler={(e: any, value: any) => onChange('scheduleHours', value)}
                        options={scheduleTimeList}
                        fieldType={SELECT_FIELD_TYPES.TYPE_1}
                        error={errorObj.scheduleHours}
                        multiple={true}
                        required={true}
                        width={'45%'}
                    />
                    <Select
                        label="Distribution Target"
                        value={baseDetails.distributionTarget}
                        onChangeHandler={(e: any, value: any) => onChange('distributionTarget', value)}
                        options={distributionList}
                        fieldType={SELECT_FIELD_TYPES.TYPE_1}
                        width={'45%'}
                    />
                </div>
                <Select
                    label="Proxy ID"
                    value={baseDetails.proxyId}
                    onChangeHandler={(e: any, value: any) => onChange('proxyId', value)}
                    options={proxyList}
                    fieldType={SELECT_FIELD_TYPES.TYPE_1}
                    error={errorObj.proxyId}
                    required={true}
                    width={'45%'}
                />
            </div>

            <div className="button-div">
                <Button
                    buttonText="Setup Scenario & Datasource >"
                    onClick={() => validate()}
                    classList={['add-update-btn']}
                />
            </div>
        </div>
    );
};

export default BasicInfo;
