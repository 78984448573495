import SummaryItemList from 'components/common/SummaryItemList';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDatasourcesWrapper,
    getScenarios,
    getScenariosWrapper,
    removeScenario,
    saveScenarioUpdates
} from 'store/actions';
import { COLUMNDETAILS_SCENARIOS, SCENARIO_TYPES, USER_ROLES } from 'utils/constants';
import { TQueryData, TScenarioData } from 'utils/model';
import { getObjectDiff, getRole } from 'utils/helperFunctions';
import CreateScenario from '../CreateScenario';
import CreateorUpdate from '../CreateUpdate';
import Dialog from 'components/common/Dialog';
import { setReloadRequired } from 'store/reducers/consoleDashboardSlice';
import { BsCaretRight } from 'react-icons/bs';
import TabularView from 'components/common/Table';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import './style.scss'

type TProps = {
    data: any[];
    type: SCENARIO_TYPES;
    executePath: string;
};

const INITIAL_DATA=[{
    name: '', createdDate: '',
}]
const ScenarioList = ({ data, type, executePath }: TProps) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<TScenarioData>();
    const [loadedData, setLoadedData] = useState<TScenarioData>();
    const [showUpdateForm, setShowUpdateForm] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState(false);
    const [deleteIndex, setIndex] = useState(0);
    const [row,setRow] = useState(INITIAL_DATA);
    const fixDetails = useSelector((state: any) => state.scenarios[SCENARIO_TYPES.FIX]);
    const investigationDetails = useSelector(
        (state: any) => state.scenarios[SCENARIO_TYPES.INVESTIGATION]
    );
    const [dataLoaded,setdataLoaded]= useState(false);
    const onSubmit = () => {
        setShowDialog(false);
        dispatch(removeScenario(data[deleteIndex]));
        dispatch(setReloadRequired(true))
    };
    const onClose = () => {
        setShowDialog(false);
    };

    const OpenDialog = (index: number) => {
        setShowDialog(true);
        setIndex(index);
    };

    const onEdit = (index: number) => {
        const scenarioDetails: TScenarioData = _.cloneDeep(data[index]);
        if (typeof scenarioDetails.linkedFixScenarioId == 'number') {
            const fixObj = _.find(fixDetails, { id: scenarioDetails.linkedFixScenarioId });
            scenarioDetails.linkedFixScenarioId = fixObj ? `${fixObj.title} (${fixObj.id})` : '';
        }
        if (typeof scenarioDetails.linkedInvestigationScenarioId == 'number') {
            const investObj = _.find(investigationDetails, {
                id: scenarioDetails.linkedInvestigationScenarioId
            });
            scenarioDetails.linkedInvestigationScenarioId = investObj
                ? `${investObj.title} (${investObj.id})`
                : '';
        }
        scenarioDetails.checks = scenarioDetails.checks?.map((query) => {
            // if (typeof query.datasourceId == 'number') {
            //     const datasource = _.find(datasources, { id: query.datasourceId });
            //     return { ...query, datasourceId: `${datasource.title} (${datasource.id})` };
            // }
            return query;
        });
        setLoadedData(scenarioDetails);
        setFormData(scenarioDetails);
        setShowUpdateForm(true);
    };

    const onUpdate = async () => {
        const cleanedFormData = {
            ...formData,
            checks: formData?.checks.map((query: any) => {
                delete query.editMode;
                return query;
            })
        };
        const diffData = getObjectDiff(cleanedFormData, loadedData);
        await dispatch(saveScenarioUpdates({ scenarioId: formData?.id, request: diffData }));
        dispatch(setReloadRequired(true));
        setShowUpdateForm(false);
    };

    useEffect(() => {
        setRow(INITIAL_DATA);
        setdataLoaded(false);
        let obj: any = {};
         let x=  data.map((item: any, index: number) => {
            if(!_.isEmpty(item))
                {
                let nameDesc=<div className='name-desc-column'>
                    <Link to={`${executePath}/${item.id}`} state={item}>
                        <div className="ctrl-btn-list execute-item">
                            <BsCaretRight size={20} />
                        </div>
                    </Link>
                 <div className='name-desc-div'>{item.title}<br/><div className='desc'>{item.description}</div></div>
                 </div>
                let date= item.createdDate?.split(', ')
                obj={}

                obj['name'] = nameDesc,
                obj['namedesc'] = `${item.title} ${item.description}`,
                obj['createdDate'] = item.createdDate?<><div>{date[0]}, {date[1]}</div><div className='date'>{date[2]}</div></>:'NA',
                obj['date'] = item.createdDate
                obj['actions']= <div className="action-column-list">
                <div className="delete-list" >
                <AiOutlineDelete size={20}  onClick={() =>{OpenDialog(index)}}/></div> 
                <AiOutlineEdit size={20} onClick={() => {onEdit(index)}} />
                </div>
                return obj;
                }
               else return null
        });
        var newArray = x.filter((value:any) => Object.keys(value).length !== 0);
        setRow(newArray);
        setdataLoaded(true);
    },[data]);


    return (
        <div>
            <Dialog
                show={showDialog}
                onCancel={onClose}
                onSubmit={onSubmit}
                message={`Are you sure you want to delete scenario - ${
                    showDialog ? data[deleteIndex].title : ''
                }?`}
                title={'Delete Scenario'}></Dialog>
            {getRole() !== USER_ROLES.VIEWER ? <CreateScenario type={type} /> : null}
            {formData && (
                <CreateorUpdate
                    title={`Update ${formData.title} scenario`}
                    type={type}
                    formData={formData}
                    setFormData={setFormData}
                    onSubmit={onUpdate}
                    show={showUpdateForm}
                    onClose={() => setShowUpdateForm(false)}
                    errorObj={{}}
                />
            )}
           
          {dataLoaded && row && <TabularView  columns={COLUMNDETAILS_SCENARIOS} filterRequired={false} rows={row} columnToHide={'namedesc'} />}
        </div>
    );
};
export default ScenarioList;
