import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import './style.scss'

type TProps = {
    menuItems: any[];
    selectedMenuItems: string[];
    handleSelect: Function;
    label: string;
    buttonLabel: string|JSX.Element;
}

const ButtonDropdown = (props: TProps) => {
    const { menuItems, selectedMenuItems, handleSelect, label, buttonLabel } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemSelection = (item: string) => {
        handleSelect(item);
        handleClose();
    }
    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={"button-dropdown"}
            >
                {buttonLabel}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    _.differenceWith(menuItems, selectedMenuItems, _.isEqual).map((item: any) => {
                        return <MenuItem onClick={() => handleMenuItemSelection(item)}>{
                            item?item[label]:null
                            }</MenuItem>
                    })

                }

            </Menu>
        </div>
    );
}

export default ButtonDropdown;