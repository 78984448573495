import { SCENARIO_TYPES } from 'utils/constants';
import api from '../store/actions/authentication'
import URLS from './urlConstants';

export const fetchScenario = (scenarioType: SCENARIO_TYPES) => {
    return api.get(`${URLS.GET_SCENARIOS}${scenarioType}`);
};

export const fetchScenarioById = (scenarioId: number) => {
    return api.get(`${URLS.GET_SCENARIO_BY_ID}${scenarioId}`);
};

export const fetchDatasource = () => {
    return api.get(URLS.GET_DATASOURCES);
};

export const createDatasource = (requestBody: any) => {
    return api.post(URLS.CREATE_DATASOURCES, requestBody);
};

export const deleteScenario = (id: number) => {
    return api.delete(`${URLS.DELETE_SCENARIO}${id}`)
}

export const deleteDatasource = (id: number) => {
    return api.delete(`${URLS.DELETE_DATASOURCE}${id}`);
};

export const createScenario = (requestBody: any) => {
    return api.post(URLS.CREATE_SCENARIO, requestBody)
}

export const executeScenario = (scenarioId: number, request: any) => {
    return api.post(`${URLS.EXECUTE_SCENARIO}${scenarioId}`, request);
}

export const fetchAuditTrails = () => {
    return api.get(URLS.GET_AUDIT_TRAILS);
}

export const updateScenario = (scenarioId: number, request: any) => {
    return api.post(`${URLS.UPDATE_SCENARIO}${scenarioId}`, request);
}

export const clearCacheAPI = () => {
    return api.post(`${URLS.CLEAR_CACHE}`, {});
}

export const clearQueryCacheAPI = (dashboardId:number,queryId: number) => {
    return api.post(`${URLS.CLEAR_CACHE_BY_QUERY}${dashboardId}/${queryId}`, {});
}

export const executeReadQuery = (request: any) => {
    return api.post(URLS.EXECUTE_READ_QUERY, request);
}

export const executeWriteQuery = (request: any) => {
    return api.post(URLS.EXECUTE_WRITE_QUERY, request);
}

export const fetchConsoleDashboardData = (dashboardId: number,refresh:boolean) => {
    return api.get(`${URLS.GET_EXCEPTION_SUMMARY}${dashboardId}?refresh=${refresh}`);
};

export const fetchSnapshots = (dashboardId:number ,queryId: number) => {
    return api.get(`${URLS.GET_SNAPSHOT_LIST}${dashboardId}/${queryId}`);
}

export const fetchSnapshotData = (snapshotId: number) => {
    return api.get(`${URLS.GET_SNAPSHOT_DATA}${snapshotId}`);
}

export const saveSnapshot =(dashboardId:number,queryId: number, request: any) => {
    return api.post(`${URLS.SAVE_SNAPSHOT}${dashboardId}/${queryId}`, request);
}
export const fetchRemoteDatasourceList = () => {
    return api.get(`${URLS.GET_REMOTE_DS_LIST}`);
};

export const fetchDashboardList = ()=>{
    return api.get(URLS.GET_DASHBOARD_LIST);
}

export const fetchProxyList = ()=>{
    return api.get(URLS.GET_PROXY_LIST);
}

export const fetchProxyDSList = (proxyId: String)=>{
    return api.get(URLS.GET_PROXY_DS_LIST+proxyId);
}

export const createDashboard = (request: any)=>{
    return api.post(URLS.CREATE_DASHBOARD, request);
}

export const updateDashboard = (request: any)=>{
    return api.post(URLS.UPDATE_DASHBOARD, request);
}

export const fetchDBSnapshotList = (dashboardId: number)=>{
    return api.get(URLS.GET_DB_SNAPSHOT_LIST+dashboardId);
}

export const fetchDashboardSnapshot = (dashboardRunId: number)=>{
    return api.get(URLS.GET_DASHBOARD_SNAPSHOT+dashboardRunId);
}

export const deleteDashboard = (dashboardId: number)=>{
    return api.delete(URLS.DELETE_DASHBOARD+dashboardId);
}

export const getQueryDashboardSnapshotData = (request: any)=>{
    return api.post(URLS.GET_QUERY_DASHBOARD_SNAPSHOT, request);
}

export const userLogin = () => {
    return api.get(URLS.USER_LOGIN);
}

export const fetchDistribution = () => {
    return api.get(URLS.GET_DISTRIBUTION);
};

export const createDistribution = (requestBody: any) => {
    return api.post(URLS.CREATE_DISTRIBUTION, requestBody);
};

export const updateDistribution = (requestBody: any) => {
    return api.post(URLS.UPDATE_DISTRIBUTION, requestBody);
};

export const deleteDistribution = (id: number) => {
    return api.delete(`${URLS.DELETE_DISTRIBUTION}${id}`);
};

