import _ from "lodash";
import React from "react";
import { MdOutlineAccessTime } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
type TProps = {
};

const DashboardDisplay = (props: TProps) => {
    const data = useSelector((state: any) => state.consoleDashboard.data);
    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const selectedsnapshot = useSelector((state: any) => state.consoleDashboard.selectedsnapshot);
    const navigate = useNavigate();
    const navigateToDetails = (scenarioId: any, id: number) => {
        navigate('../exception-details/' + scenarioId + `/${id}`, { state: { isDashboardEnabled: true, selectedDashboard, selectedsnapshot } });
    };
    return <div className="db-display">
        {data.map((summaryItem: any) => (
            <div className="summary-item">
                <div className="title">{summaryItem.title}</div>
                <div className="query-card">
                    {summaryItem.checks?.map((cardItem: any, id: number) => {
                        return (
                            <div
                                key={cardItem.id}
                                className={`card ${cardItem.isDataValid? '' : 'alert'}`}
                                onClick={() => navigateToDetails(summaryItem.id, id)}>
                                {cardItem.success === true ? <React.Fragment>
                                    <div className="name">{_.startCase(cardItem.name)}</div>
                                    <div className="section">
                                        <div className="value">{cardItem.value}</div>
                                        <div className="type">{cardItem.type}</div>
                                    </div>
                                    <p className="updated-time">
                                        <MdOutlineAccessTime size={11} />
                                        <span>{cardItem.lastExecutionTime}</span>
                                    </p>
                                </React.Fragment>: <p>Query failed to execute. Please check the query:: <span className="error">Error message: {cardItem.exception}</span></p>}
                            </div>
                        );
                    })}
                </div>
            </div>
        ))}
    </div>
}

export default DashboardDisplay;