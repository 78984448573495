import './style.scss';
import { AiOutlineClose } from 'react-icons/ai';
import Button, { TYPES } from 'components/common/Button';

type TProps = {
    title: string;
    show: boolean;
    onClose: Function;
    onSubmit: Function;
    submitText: string;
    showButton?: Boolean;
    children: JSX.Element;
    fullSize?: Boolean;
};

const Modal = (props: TProps) => {
    const { show, onClose, title, onSubmit, submitText, children, showButton, fullSize } = props;

    return show ? (
        <div className="modal">
            <div className={`inner ${fullSize?'fullSize':''}`}>
                <div className="modal-container">
                    <div className="close" onClick={(e) => onClose()}>
                        <AiOutlineClose />
                    </div>
                    <div className="content" >
                        <h1 className="title">{title}</h1>
                        <div className="children-modal">{children}</div>
                        <div className="submit">
                            {showButton && <Button
                                onClick={(e) => onSubmit()}
                                buttonText={submitText}
                                type={TYPES.PRIMARY}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default Modal;
