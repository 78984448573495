import { IconButton, Tooltip } from "@mui/material";


type Tprops={
    title:string|JSX.Element;
    icon:JSX.Element;
}

const TooltipComponent=(props:Tprops)=>{
const {title,icon} = props;
return (
    <Tooltip title={title}>
                        <IconButton>
                           {icon}
                        </IconButton>
                    </Tooltip>
)
}

export default TooltipComponent