import _ from 'lodash';
import { EDITOR_SCREENS, MONTHS } from 'utils/constants';
import * as XLSX from 'xlsx';

/**
 * @function getMonthString
 * @param monthIndex -  value is in range of [0,11], 0 for january and 11 for December
 * @returns function returns month as string based on passed index
 */
export const getMonthString = (monthIndex: number) => {
    if (typeof monthIndex !== 'number' || monthIndex < 0 || monthIndex > 11) {
        throw 'Invalid month index';
    }
    return MONTHS[monthIndex];
};

/**
 * @function getDDMonthYYYY
 * @param date - it can be both date and string format.
 * @returns it will return date string in a format of dd Month yyyy
 */
export const getDDMonthYYYY = (date: Date | string) => {
    try {
        const dateObj = typeof date == 'string' ? new Date(date) : date;
        if (isNaN(dateObj.getDate())) {
            throw 'Invalid input';
        }

        const dateString = `${dateObj.getDate()} ${getMonthString(
            dateObj.getMonth()
        )} ${dateObj.getFullYear()}`;
        return dateString;
    } catch (e) {
        throw e;
    }
};

/**
 * @function getTimeDifference
 * @param startDate
 * @param endDate
 * @returns returns difference of start date and end date in a secs/mins/hours
 */
export const getTimeDifference = (startDate: Date | string, endDate: Date | string) => {
    try {
        const start: number = new Date(startDate).valueOf();
        const end: number = new Date(endDate).valueOf();
        if (isNaN(start) || isNaN(end) || end < start) {
            throw 'Invalid input';
        }
        const diffInMilliSeconds = end - start;
        const inSeconds = Math.floor(diffInMilliSeconds / 1000);
        if (inSeconds < 60) {
            return { value: inSeconds, unit: 'secs' };
        }
        const inMinutes = Math.floor(diffInMilliSeconds / (60 * 1000));
        if (inMinutes < 60) {
            return { value: inMinutes, unit: 'mins' };
        }
        const inHours = Math.floor(diffInMilliSeconds / (60 * 60 * 1000));
        if (inHours < 24) {
            return { value: inHours, unit: 'hours' };
        }
        const inDays = Math.floor(diffInMilliSeconds / (24 * 60 * 60 * 1000));
        return { value: inDays, unit: 'days' };
    } catch (e) {
        throw e;
    }
};

/**
 * @function generateCalendarDays
 * @param startDate - start date in date format
 * @returns Returns an array 35 date objects from start date
 */
export const generateCalendarDays = (startDate: Date) => {
    try {
        if (isNaN(startDate.getDate())) {
            throw 'Invalid Input';
        }
        let i = 0;
        let dateArr: Date[] = [];
        while (i < 35) {
            const tempDate = new Date(startDate);
            dateArr.push(new Date(tempDate.setDate(tempDate.getDate() + i)));
            i++;
        }
        return dateArr;
    } catch (e) {
        throw 'Invalid input';
    }
};

/**
 * @function getCalenderDates
 * @param monthIndex it takes month index in range of [0,11] (0 for jan and 11 for december)
 * @param year it takes year as number
 * @returns returns an array of 35 date objects that starts with sunday and ends with saturday
 */
export const getCalenderDates = (monthIndex: number, year: number) => {
    //getDay -> weekday -> 0-6->[sun,mon,tue,..., sat];
    //getDate -> 1-31
    try {
        const monthStart = new Date(year, monthIndex, 1);
        if (monthIndex > 11 || monthIndex < 0 || isNaN(monthStart.getDate())) {
            throw new Error('Invalid input');
        }
        const startDayIndex = monthStart.getDay();
        switch (startDayIndex) {
            case 0:
                return generateCalendarDays(monthStart);
            default:
                return generateCalendarDays(
                    new Date(monthStart.setDate(monthStart.getDate() - startDayIndex))
                );
        }
    } catch (e) {
        throw 'Invalid input';
    }
};

export const arrRepeat = (arr: any[], count: number) => {
    let newArr: any[] = [];
    for (let i = 0; i < count; i++) {
        newArr = newArr.concat(arr);
    }
    return newArr;
};
const getQuotesStringReplaced = (str: string)=>{
    const quotesRegex = /\'(.*?)\'/g;
    const variables:any = str.match(quotesRegex);
    if(!variables) return str;

    let replacedStr = str;
    for(let variable of variables){
        replacedStr = replacedStr.replaceAll(variable,'');
    }
    replacedStr = replacedStr.replaceAll(":=",'')//removing colon equal
    return replacedStr;
}

const getTimeReplaced = (processedStr: string) =>{
    // replace time with empty string
    const regex= /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/g;
    const regex2= /[0-2]\d:[0-5]\d(:[0-5]\d)*(?:\.\d+)?Z?/g
    const variables1:any = processedStr.match(regex) || [];
    const variables2:any = processedStr.match(regex2) || [];
    const variables:any= variables1.concat(variables2);
    if(!variables) return processedStr;
    let replacedStr = processedStr;
    for(let variable of variables){
        replacedStr = replacedStr.replaceAll(variable,'');
    }
    return replacedStr;

}

export const getVariables = (str: string): string[] => {
    const processedStr = getQuotesStringReplaced(str);
    const processedStr2 = getTimeReplaced(processedStr);
    const regex = /:([^:\s;]+)/g;
    let top:any;
    var variables = [];
    
    while (( top = regex.exec(processedStr2)) !== null) {
        variables.push(top[1]);
    }
    return variables;
};
export const getPathVariables = (str: string) =>{
    const prefix = new RegExp("^(http|https)://", "i");
    const updatedStr = str.replace(prefix,'');
    const urlWithPath = updatedStr.split('?')[0];
    const regex = /:([^:\s;\/]+)/g;
    let top:any;
    var variables = [];
    
    while (( top = regex.exec(urlWithPath)) !== null) {
        variables.push(top[1]);
    }
    return variables;
}

export const getUrlVariables = (str: string): string[] => {
    let pathVariables:string[] = getPathVariables(str);
    let result: string[] = [];
    const paramStr = str.split('?')[1];
    if(paramStr && paramStr.length>0){
        const paramList = paramStr.split('&');
        result = paramList.flatMap((paramStrItem: string)=>{
            return getVariables(paramStrItem);
        });
    }
    return [...pathVariables,...result];
}

export const getMapFromArray = (arr: string[]) => {
    const response: any = {};
    for (let str of arr) {
        response[str] = '';
    }
    return response;
};

export const getLastEnclosedIdValue = (str: string): number|null => {
    if(!str){
        return null;
    }
    const start = str.lastIndexOf('(') + 1;
    const end = str.lastIndexOf(')');
    return parseInt(str.slice(start, end));
};

export const getObjectDiff = (obj1: any, obj2: any) => {
    const diff: any = {};
    for (const key in obj1) {
        if (obj1[key] === obj2[key]) {
            continue;
        } else if (Array.isArray(obj1[key]) && Array.isArray(obj1[key])) {
            if (!_.isEqual(obj1, obj2)) diff[key] = obj1[key];
        } else if (typeof obj1[key] == 'object') {
            if (typeof obj2[key] == 'object' && obj1[key] != null) {
                diff[key] = getObjectDiff(obj1[key], obj2[key]);
            } else {
                diff[key] = obj1[key];
            }
        } else diff[key] = obj1[key];
    }
    return diff;
};

export const isValidEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    return regex.test(email);
};

const getFormattedString = (input: any, len: number) => {
    const str = `${input}`;
    if (str.length < len) {
        const pending = len - str.length;
        const restStr = '  '.repeat(pending);
        const finalStr = str + restStr;
        return finalStr;
    }
    return str;
};
export const getClipboardTextForGrid = (data: any[]) => {
    let outputString = '';
    if (!_.isEmpty(data)) {
        const keys = _.keys(data[0]);
        const strLens = _.map(keys, (key: string) => {
            let len = key.length;
            for (let dataItem of data) {
                if (dataItem[key] && dataItem[key].length > len) len = dataItem[key].length;
            }
            return len + 3;
        });
        for (let i = 0; i < keys.length; i++) {
            outputString += getFormattedString(keys[i], strLens[i]);
        }
        outputString += '\n';
        for (let dataItem of data) {
            for (let i = 0; i < keys.length; i++) {
                outputString += getFormattedString(dataItem[keys[i]], strLens[i]);
            }
            outputString += '\n';
        }
    }
    return outputString;
};

export const copyToClipboardAPI = async (dataString: string) => {
    try {
        navigator.clipboard.writeText(dataString);
        return 'Copied successfully';
    } catch (e) {
        return 'Failed to copy data';
    }
};

export const pasteFromClipboardAPI = async () => {
    try {
       let data = navigator.clipboard.readText();
        return data;
    } catch (e) {
        return 'Failed to paste data';
    }
};

export const csvToJson = async (csv: string) => {
    var array = csv.toString().split('\n');
    let result = [];
    let headers = array[0].split(',');
    for (let i = 1; i < array.length - 1; i++) {
        let obj: any = {};

        let str = array[i];
        let s = '';
        let flag = 0;
        for (let ch of str) {
            if (ch === '"' && flag === 0) {
                flag = 1;
            } else if (ch === '"' && flag == 1) flag = 0;
            if (ch === ', ' && flag === 0) ch = '|';
            if (ch !== '"') s += ch;
        }
        let properties = s.split(',');
        for (let j in headers) {
            obj[headers[j]] = properties[j];
        }

        result.push(obj);
    }
    return result;
};

export const convertToCSV = (arr: any) => {
    let str = 'data:text/csv;charset=utf-8,';
    if (arr.length) {
        let keys = _.keys(arr[0]);
        str += keys.join('|') + '\r\n';
        for (let item of arr) {
            for (let key of keys) {
                let val = `${item[key]}`;
                str += val + '|';
            }
            // str = str.slice(0, str.length - 2);
            str += '\r\n';
        }
    }
    var encodedUri = encodeURI(str);
    window.open(encodedUri);
};

const getArrayOfArray = (arrObj: any) => {
    const keys = _.keys(arrObj[0]);
    const response = [keys];
    for (let obj of arrObj) {
        response.push(_.values(obj));
    }
    return response;
};
export const createExcel = (arr: any, title: string) => {
    let wb = XLSX.utils.book_new();
    wb.Props = {
        Title: title,
        Subject: 'Scenario data',
        Author: 'Roadrunner',
        CreatedDate: new Date()
    };

    let wsData = getArrayOfArray(arr);

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, title);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, title + '.xlsx');
};

export const parseJson = (jsonStr: string) => {
    try {
        return JSON.parse(jsonStr);
    } catch (error) {
        return [jsonStr];
    }
};

export const Copy = async (data: any[]) => {
    let arr: string[] = [];
    let final_arr: any[] = [];
    let final_arr2: any[] = [];
    let sizes: any[] = [];
    let final_sizes: any[] = [];
    let final_data: any[] = [];

    if (data && data.length) {
        // to get all headings
        let x = Object.values(data);
        Object.entries(x[1]).map((i) => i[0] && arr.push(i[0]));
        //arr contains all headings
        let max_len: any;
        for (let item of arr) {
            data.map((val: any, key) => {
                if (key == 0) {
                    final_arr = [];
                    sizes = [];
                }
                final_arr.push({ key: key, val: val[item] });
                for (let i of final_arr) i.val ? sizes.push(i.val.toString().length) : '';
                sizes.push(item.length);
                if (sizes.length > 1000) {
                    sizes.splice(1000);
                }
                max_len = Math.max(...sizes);
                if (final_arr.length == data.length) {
                    for (let i of final_arr) {
                        let x = i.val.toString().length;
                        x < max_len
                            ? final_arr2.push(i.val + ' '.repeat(max_len - x))
                            : final_arr2.push(i.val);
                    }
                }
            });
        }

        let i = 0;
        while (i < data.length + 1 && data.length) {
            let obj: any = {};
            final_arr2.map((item, key) => {
                if (key % data.length == i) {
                    key % data.length == 0 &&
                        final_sizes.push(item == 0 ? '0' : item.toString().length);

                    let z = arr[Math.floor(key / data.length)];

                    if (z && z.length) {
                        final_sizes[Math.floor(key / data.length)] - z.length > 0
                            ? (z =
                                  z +
                                  ' '.repeat(final_sizes[Math.floor(key / data.length)] - z.length))
                            : z;
                    }

                    obj[z] = item;
                }
            });
            final_data.push({ ...obj });
            obj = {};
            i = i + 1;

            final_data.splice(data.length);
        }
    }
    return final_data;
};

export const getValueReplacedString = (str:string, keyValueObject:any)=>{
    let resStr = str;
    for(let key in keyValueObject){
        const keyToReplace = ':'+key;
        resStr = resStr.replaceAll(keyToReplace,keyValueObject[key])
    }
    return resStr;
}

export const setRole=(role:string)=>{
sessionStorage.removeItem('role');
sessionStorage.setItem('role',role);
}

export const getRole=()=>{
    const role=sessionStorage.getItem('role');
    return role;
}

export const checkValidity=()=>{
    for(let route of EDITOR_SCREENS)
    {
        if(window.location.pathname.includes(route))
        {
           return false
        }   
    }
    return true
}

export const getFilteredQueryDSMapList = (queryList:any[], dsMapList: any[]) => {
    if(!queryList || !dsMapList){
        return [];
    }
    const validQueryIdList = queryList.map((queryItem:any)=>queryItem.id);
    return dsMapList.filter((dsMapItem:any)=>validQueryIdList.includes(dsMapItem.queryId));
}

export const convertJsonObjectToKVArray = (jsonObject:Object)=>{
    if(jsonObject === null || _.isEmpty(jsonObject)){
        return [];
    }
    return _.entries(jsonObject).map(([key, value]:any)=>{
        let data=typeof value=='object'?JSON.stringify(value):value
        return {
            key,
            value:data
        }
    })
}