import Checkbox from 'components/common/Checkbox';
import { fetchRemoteDatasourceList } from 'apis';
import InputField from 'components/common/InputField';
import Select from 'components/common/Select';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { DB_TYPES } from 'utils/constants';

type TProps = {
    data: Object;
    onChangeHandler: Function; //It passes key and value
    error: any;
};

const Credential = (props: TProps) => {
    const { data, onChangeHandler, error } = props;
    const [remoteDatasourceList, setRemoteDatasourceList] = useState([]);

    return (
        <div className="credential-container">
            <h4>Configuration details</h4>
            {Object.entries(data).map(([key, value]) => {
                if (key == 'dbType') {
                    return (
                        <Select
                            required={true}
                            label={_.startCase(key)}
                            value={value}
                            onChangeHandler={(e: any, value: any) => onChangeHandler(key, value)}
                            options={DB_TYPES}
                            error={error[key]}
                        />
                    );
                } else if (key === 'datasourceName') {
                    return (
                        <Select
                            required={true}
                            label={_.startCase(key)}
                            value={value}
                            onChangeHandler={(e: any, value: any) => onChangeHandler(key, value)}
                            options={remoteDatasourceList}
                            error={error[key]}
                        />
                    );
                } else if (key == 'readOnly') {
                    return (
                        <Checkbox
                            value={value}
                            onChangeHandler={(e: any, checked: boolean) =>
                                onChangeHandler(key, checked)
                            }
                            label={_.startCase(key)}
                        />
                    );
                }
                return (
                    <InputField
                        required={key != 'key' ? true : false}
                        type="text"
                        label={_.startCase(key)}
                        value={value}
                        onChangeHandler={(e) => onChangeHandler(key, e.target.value)}
                        error={error[key]}
                    />
                );
            })}
        </div>
    );
};

export default Credential;
