import ComponentWrapper from '../ComponentWrapper';
import { useLocation, useParams } from 'react-router-dom';
import QueryResponseComponent from '../QueryResponseComponent';
import ScenarioSection from './ScenarioSection';
import './style.scss';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getScenarioDetailsByIdWrapper } from 'store/actions';
import _ from 'lodash';
import ExecuteForm from '../Execute';
import { loadInitialScenarioData } from 'store/loadData';
import { getRole } from 'utils/helperFunctions';
import { USER_ROLES } from 'utils/constants';


type TProps = {};

const ExceptionDetails = (props: TProps) => {
   
    const params:any = useParams();
    const id = params['id'];
    const itemSelected=params['*']
    const scenarioDetails = useSelector((state: any) => state.scenarioDetails[id]);
    const [isLoaded, setIsLoaded] = useState(false);
    const dispatch = useDispatch(); 
    const locationData : any = useLocation();
    const [executionDetails, setExecutionDetails] = useState<any>({});
    const [isExecuted, setIsExecuted] = useState<boolean>(false);
    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const [isValidScenario,setIsValidScenario] = useState(true);

    const initialSetup = async () => {
        if (id) {
            await dispatch(getScenarioDetailsByIdWrapper(id));
            setIsLoaded(true);
          
        }
    };
 
    const getExecutionDetails = () => {
        if (getRole() === USER_ROLES.VIEWER && selectedDashboard) {
            const scenarioQueries =_.filter(selectedDashboard.dashboardQueries,{scenarioId: parseInt(id)});
            if(scenarioQueries.length === 0){
                setIsValidScenario(false);
                return;
            }
            setIsValidScenario(true);
            const currentExceptionDetails = {
                proxyId: selectedDashboard.proxyId,
                queryDsMap: selectedDashboard.dashboardQueries,
                dashboardId: selectedDashboard.id
            }
            setExecutionDetails(currentExceptionDetails);
            setIsExecuted(true);
        }
    }
    useEffect(()=>{
        getExecutionDetails();
    },[selectedDashboard])

    useEffect(() => {
        initialSetup();
        loadInitialScenarioData();
    }, []);

   const onExecute = (executeDetails:any) => {
    setExecutionDetails(executeDetails);
    setIsExecuted(true);
   }
    return (
        <ComponentWrapper title={scenarioDetails?.title ?? 'Exception Details'}>
            {scenarioDetails && isLoaded ? (
                <div className="exception-details">
                    <div className="detail-audit-section">
                        <div className="title-description">
                            <h4>Description</h4>
                            <p>{scenarioDetails.description}</p>
                        </div>
                    </div>
                    {
                        isValidScenario
                            ? <React.Fragment>
                                {locationData.state?.isDashboardEnabled || getRole() === USER_ROLES.VIEWER ? null : <ExecuteForm data={scenarioDetails} onExecute={onExecute} scenarioId={parseInt(id)} />}

                                {(!locationData.state?.isDashboardEnabled && isExecuted) || locationData.state?.isDashboardEnabled ? <QueryResponseComponent
                                    itemSelected={itemSelected}
                                    data={scenarioDetails.checks}
                                    scenarioId={scenarioDetails.id}
                                    isClearCacheEnabled={true}
                                    isSnapshotEnabled={true}
                                    executionDetails={executionDetails}
                                    disableCache = {(!locationData.state?.isDashboardEnabled && isExecuted)}
                                /> : null}
                            </React.Fragment>
                            : <h2>Invalid Scenario for selected dashboard</h2>
                    }
                </div>
            ) : (
                <div>{isLoaded ? 'Invalid scenario id' : 'Loading...'}</div>
            )}
        </ComponentWrapper>
    );
};

export default ExceptionDetails;
