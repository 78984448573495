// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKOtp_DcLz3tUTvtzC873ATIBO6iL0Czg",
  authDomain: "instats-409fa.firebaseapp.com",
  projectId: "instats-409fa",
  storageBucket: "instats-409fa.appspot.com",
  messagingSenderId: "596025603828",
  appId: "1:596025603828:web:e7331ae36bf68e241290a1",
  measurementId: "G-CK29NBCZXS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  hd: '91social.com'
});