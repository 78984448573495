import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addDistribution, getDistribution, removeDistribution, updateDistributionChannel } from 'store/actions';

const initialState = {
    distribution: []
};

const reducers = {};

const extraReducers: any = {
    [getDistribution.fulfilled]: (state: any, action: any) => {
        const { data } = action.payload;
        data.map((i:any)=>i.channelDetails=JSON.parse(i.channelDetails))
        state.distribution = data;
    },
    [addDistribution.fulfilled]: (state: any, action: any) => {
        const { data } = action.payload;
        data && state.distribution.push(data);
    },
    [updateDistributionChannel.fulfilled]: (state: any, action: any) => {
        const { data } = action.payload;
        let id=data.id;
        if (data) {
         let index=  _.findIndex(state.distribution, { id });
         state.distribution[index]= data;
        }
    },
    [removeDistribution.fulfilled]: (state: any, action: any) => {
        const { id } = action.payload;
        if (id) {
            _.remove(state.distribution, { id });
        }
    }
};
export const distributionSlice = createSlice({
    name: 'distribution',
    initialState,
    reducers,
    extraReducers
});

export const {} = distributionSlice.actions;
export default distributionSlice.reducer;
