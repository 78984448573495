import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show: false
};

const reducers = {
    showLoader: (state: any) => {
        state.show = true;
    },
    hideLoader: (state: any) => {
        state.show = false;
    }
};

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export default loaderSlice.reducer;