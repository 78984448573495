import TabComponent from 'components/common/Tabs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import './style.scss';

type TProps = {
    steps: {};
    stage: number;
};


const Wizard = (props: TProps) => {
    const { steps, stage } = props;
    const [label,setLabel]= useState<any[]>([])
    useEffect(() => {
        getLabels();
    }, [steps]);

    const getLabels = () => {
        const labelList=Object.values(steps).map((item: any, key: number) => item.label);
        setLabel(labelList)
    };

    return (
        <div className='wizard-container'>
            {label.length > 0 &&
                Object.values(steps).map((item: any, key: number) => {
                    return (
                        <>
                            {key + 1 == stage && <TabComponent stage={stage} labelArray={label} />}
                            {stage == key + 1 && <div className='wizard-content'>{item.component}</div>}
                        </>
                    );
                })}
        </div>
    );
};

export default Wizard;
