import SummaryItemList from 'components/common/SummaryItemList';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDatasourcesWrapper, removeDatasource } from 'store/actions';
import CreateDataSource from './CreateDataSource';
import _ from 'lodash';
import { getHeader, temp_header } from 'store/actions/authentication';
import './style.scss';
import Dialog from 'components/common/Dialog';
import { getRole } from 'utils/helperFunctions';
import { setReloadRequired } from 'store/reducers/consoleDashboardSlice';
import { COLUMNDETAILS_SCENARIOS } from 'utils/constants';
import { Link } from 'react-router-dom';
import { AiOutlineDelete } from 'react-icons/ai';
import TabularView from 'components/common/Table';
type TProps = {
    data: any[];
};
const INITIAL_DATA=[{
    name: '', createdDate: '',
}]

const Datasource = (props: TProps) => {
    const data = useSelector((state: any) => state.datasource.datasources);
    const [showDialog, setShowDialog] = useState(false);
    const [deleteIndex, setIndex] = useState(0);
    const [row,setRow] = useState(INITIAL_DATA);
    const dispatch = useDispatch();

    const loadData = async () => {
        if(!data)
        await dispatch(getDatasourcesWrapper());
    };


    useEffect(() => {
        loadData();
    }, []);
   
    
    const onSubmit = () => {
        setShowDialog(false);
        const { id } = data[deleteIndex];
        dispatch(removeDatasource(id));
        dispatch(setReloadRequired(true));
      
    };
    const onClose = () => {
        setShowDialog(false);
    };

    const OpenDialog = (index: number) => {
        setShowDialog(true);
        setIndex(index);
    };

    useEffect(() => {
        setRow(INITIAL_DATA);
        let obj: any = {};
         let x=  data.map((item: any, index: number) => {
            if(!_.isEmpty(item))
                {
                let nameDesc=<div className='name-desc-column'>
                    
                 <div className='name-desc-div'>{item.title}<br/><div className='desc'>{item.description}</div></div>
                 </div>
                let date= item.createdDate?.split(', ')
                obj={}

                obj['name'] = nameDesc,
                obj['namedesc'] = `${item.title} ${item.description}`
                obj['createdDate'] = item.createdDate?<><div>{date[0]}, {date[1]}</div><div>{date[2]}</div></>:'NA',
                obj['date'] = item.createdDate
                obj['actions']= <div className="action-column-list">
                <div className="delete-list" >
                <AiOutlineDelete size={20}  onClick={() =>{OpenDialog(index)}}/></div> 
                </div>
                return obj;
                }
               else return null
        });
        var newArray = x.filter((value:any) => Object.keys(value).length !== 0);
        setRow(newArray);
    },[data]);
    return (
       <div>
             <Dialog
                show={showDialog}
                onCancel={onClose}
                onSubmit={onSubmit}
                message={`Are you sure you want to delete datasource-  ${showDialog?data[deleteIndex].title:''} ?`}
                title={'Delete DataSource'}></Dialog>
            <CreateDataSource />
            {row && <TabularView  filterRequired={false} columns={COLUMNDETAILS_SCENARIOS} rows={row} columnToHide={'namedesc'} />}
        </div>
    );
};

export default Datasource;
