import React, {  useEffect, useState } from 'react';                                   
import Button from 'components/common/Button';
import { useDispatch } from 'react-redux';
import { TScenarioData } from 'utils/model';
import { addScenario, getScenariosWrapper } from 'store/actions';
import _ from 'lodash';
import './style.scss'
import { SCENARIO_TYPES } from 'utils/constants';
import CreateorUpdate from '../CreateUpdate';

type TProps = {
    type: SCENARIO_TYPES;
    onSave?:Function;
};

const INTIAL_DATA: TScenarioData = {
    title: '',
    description: '',
    linkedFixScenarioId: '',
    linkedInvestigationScenarioId: '',
    scenarioType: '',
    checks: [],
}

const MANDATORY_FIELDS = ['title','description']

const CreateScenario = (props: TProps) => {
    const { type, onSave } = props;
    const [show, setShow] = useState(false);
    const INITIAL_DATA_TYPE: TScenarioData = { ...INTIAL_DATA, scenarioType: type }
    const [formData, setFormData] = useState<TScenarioData>(INITIAL_DATA_TYPE);
    const [errorObj, setErrorObj] = useState<any>({});
    const dispatch = useDispatch();
 
    useEffect(() => {
        setFormData(INITIAL_DATA_TYPE);
    }, [type]);

   
    const validate = ()=>{
        let isValid: boolean = true;
        const formDataTemp:any = _.cloneDeep(formData);
        const errorObjTemp:any = _.cloneDeep(errorObj);
        for(let key in formDataTemp){
            if(MANDATORY_FIELDS.includes(key) && _.isEmpty(formDataTemp[key])){
                errorObjTemp[key] = 'Invalid '+key;
                isValid= false;
            }else{
                errorObjTemp[key] = '';
            }
        }
        setErrorObj(errorObjTemp);
        return isValid;
    }

    const onSubmit = async () => {
        if(validate()){
            setShow(false);
            const response = await dispatch(addScenario(formData));
            await dispatch(getScenariosWrapper(type));    
            setFormData(INITIAL_DATA_TYPE);
            if(onSave){
                onSave(response.payload.data);
            }
        }
    };

    const onClose = () => {
        setShow(false);
    };
   
    return (
        <div className="create-scenario">
           {(location.pathname.endsWith('exception') || location.pathname.endsWith('investigation') ||location.pathname.endsWith('fix'))   && <div className='create-scenario-button'>
            <Button
                buttonText="Create scenario"
                onClick={() => setShow(true)}
                classList={['create-btn']}
            />
            </div>}
          
           <CreateorUpdate
                title={`Create ${_.lowerCase(type)} scenario`}
                type={type}
                formData={formData}
                setFormData={setFormData}
                onSubmit={onSubmit}
                show={show}
                onClose={onClose}
                errorObj={errorObj} 
                />

        </div>
    );
}

export default CreateScenario;
