import { useEffect, useState } from 'react';
import DynamicGrid from '../DynamicGrid';
import _ from 'lodash';
import './style.scss';
/**
 * props - current data, prev data
 * displays diff if, updated, new row added, new row deleted
 */
type TProps = {
    idField: string;
    previousData: any[];
    currentData: any[];
};

type TDiffObject = {
    deleted: any[];
    added: any[];
    updated: any;
};
/**
 *
 * TODO:
 * Create a diff object with three keys, updated, deleted, added using previous and currentData,
 * Use generated diff details object to set colors
 */
const DiffComponent = (props: TProps) => {
    const { previousData, currentData, idField } = props;
    const [diffObj, setDiffObj] = useState<TDiffObject>({
        deleted: [],
        added: [],
        updated: {} ///keys will be row keys, each key will have array value with {field, new, old}
    });

    useEffect(() => {
        if (diffEligibilityCheck()) { getDataDiff(); }
    }, [previousData, currentData]);

    const diffEligibilityCheck = () => {
        const isPreviousValid = Array.isArray(previousData) && previousData.length && (typeof previousData[0] == 'object' && _.keys(previousData[0]).includes(idField));
        const isCurrentValid = Array.isArray(currentData) && currentData.length &&  (typeof currentData[0] == 'object' && _.keys(currentData[0]).includes(idField));
        return isCurrentValid && isPreviousValid;
    }

    const getDataDiff = () => {
        const previousDataIdList: string[] = previousData.map((item) => item[idField]);
        const currentDataIdList: string[] = currentData.map((item) => item[idField]);
        const updatedDiffObj: TDiffObject = _.clone(diffObj);
        updatedDiffObj.deleted = _.difference(previousDataIdList, currentDataIdList);
        updatedDiffObj.added = _.difference(currentDataIdList, previousDataIdList);
        for (let item of currentData) {
            const previousItem = _.find(previousData, { [idField]: item[idField] });
            if (previousItem && !_.isEqual(item, previousData)) {
                const keys = Object.keys(item);
                const itemDiff: any[] = [];
                for (let key of keys) {
                    if (!_.isEqual(item[key], previousItem[key])) {
                        itemDiff.push({ field: key, old: previousItem[key], new: item[key] });
                    }
                }
                updatedDiffObj.updated[item[idField]] = itemDiff;
            }
        }
        setDiffObj(updatedDiffObj);
    };

    const getRowClassPrevious = (params: any) => {
        if (diffObj.deleted.indexOf(params.data[idField]) !== -1) {
            return 'row-dark-red row-red-border';
        }
        return 'row-light-red row-red-border';
    };

    const getRowClassCurrent = (params: any) => {
        if (diffObj.added.indexOf(params.data[idField]) !== -1) {
            return 'row-dark-green row-green-border';
        }
        return 'row-light-green row-green-border';
    };

    const getUpdatedCellStatus = (params: any) => {
        const currentId = params.data[idField];
        if (diffObj.updated[currentId]) {
            const fieldItem = _.find(diffObj.updated[currentId], { field: params.colDef.field });
            return fieldItem ? true : false;
        }
        return false;
    };
    const cellClassRulesPrevious = {
        'row-dark-red': getUpdatedCellStatus
    };

    const cellClassRulesCurrent = {
        'row-dark-green': getUpdatedCellStatus
    };

    return (
        <div>
            {Array.isArray(previousData) ? <DynamicGrid
                data={previousData}
                cellClassRules={cellClassRulesPrevious}
                getRowClass={getRowClassPrevious}
                cellClassRuleApplyAllField={true}
                title={"Previous data"}
                showTitleSection={true}
            /> : <div>Something went wrong</div>}
            {Array.isArray(previousData) ? <DynamicGrid
                data={currentData}
                cellClassRules={cellClassRulesCurrent}
                getRowClass={getRowClassCurrent}
                cellClassRuleApplyAllField={true}
                title={"Current data"}
                showTitleSection={true}
            /> : <div>Something went wrong</div>}
        </div>
    );
};

export default DiffComponent;
