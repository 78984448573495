import Button, { TYPES } from "components/common/Button";
import DynamicGrid from "components/common/DynamicGrid";
import { COLOR_SCHEMA_TYPE } from "utils/constants";
import { TColorConfig, TValueColorSchema } from "utils/model";

type TProps = {
    data: TColorConfig;
    onEdit: Function;
    onDelete: Function;
    index: number;
}
const SummaryColorScheme = (props: TProps) => {
    const { data, onEdit, onDelete, index } = props;
    return <div className="summary-color-scheme">
        <div className="row">
            <div className="label">Field: </div>
            <div className="value">{data.columnName}</div>
        </div>
        <div className="row">
            <div className="label">Type: </div>
            <div className="value">{data.type}</div>
        </div>
        <div className="row">
            <div className="label">Color: </div>
            <div className="value">{data.colorClass}</div>
        </div>
        <div className="row">
            <div className="label">Pinned: </div>
            <div className="value">{data.pinned}</div>
        </div>
        {
            data.type === COLOR_SCHEMA_TYPE.COLUMN_RANGE ?
                <div className="row">
                    <div className="label">Range: </div>
                    <div className="value">{`${data.range.startValue} - ${data.range.endValue}`}</div>
                </div>
                : null
        }
        {
            data.colorScheme.length ?
                <div className="color-scheme">
                    <div className="t-head">
                        <div className="col-1">Value</div>
                        <div className="col-1">Color</div>
                        <div className="col-1">Comparison</div>
                    </div>
                    {
                        data.colorScheme.map((item: TValueColorSchema) => {
                            return <div className="t-row">
                                <div className="col-1">{item.value}</div>
                                <div className="col-1">{item.colorClass}</div>
                                <div className="col-1">{item.comparisonType}</div>
                            </div>
                        })
                    }
                </div>
                : null
        }
        <div className="controller">
            <Button
                buttonText="Edit"
                onClick={() => onEdit(index)}
                type={TYPES.PRIMARY_OUTLINED}
                classList={['float-right']}
            />
            <Button
                buttonText="Delete"
                onClick={() => onDelete(index)}
                type={TYPES.PRIMARY_OUTLINED}
                classList={['float-right']}
            />
        </div>
    </div>
}

export default SummaryColorScheme;