
import { auth } from '../../firebase';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { TUserDetails } from 'utils/model';
import { AUTH_STORAGE_KEY } from 'utils/constants';
import axios, { AxiosRequestConfig } from 'axios';
import { _ } from 'ag-grid-community';
import { setAuthStatus, updateAuthState } from 'store/reducers/AuthenticationSlice';
import store from 'store';
let authDetail: any;


export const updateAuthSessionDetails = (authDetails: TUserDetails) => {
  
    authDetail = authDetails;
    getHeader();
};

const deleteAuthSessionDetails = () => {
    sessionStorage.removeItem(AUTH_STORAGE_KEY);
    sessionStorage.removeItem('role');
};

export const logInWithEmailAndPassword: any = async (payload: TUserDetails) => {
    const response: any = await signInWithEmailAndPassword(auth, payload.email, payload.password);
    const updatedPayload = {
        ...payload,
        authToken: response.user.accessToken,
        uid: response.user.uid,
        isAuthenticated: true
    };
    updateAuthSessionDetails(updatedPayload);
    return updatedPayload;
};

export const logOut: any = async () => {
    await auth.signOut();
    deleteAuthSessionDetails();
};

const baseURL =
    process.env.NODE_ENV === 'production'
        ? 'https://api.gopitstop.net'
        :'https://api-dev.gopitstop.net';

const axiosInstance = axios.create({
    baseURL
});

export const getHeader = () => {
    if (sessionStorage.getItem('isAuthenticated') === 'true') {
        const authDetails = authDetail;
        return {
            'auth-token': authDetails?.authToken ?? '',
            uid: authDetails?.uid ?? '',
            email: authDetails?.email ?? ''
        };
    }
    return {};
};

export const authWrapper = async ({apiCallback,payload}:any) => {
        auth.onAuthStateChanged(async (user: any) => {
            const updatedPayload = {
                ...store.getState().auth,
                authToken: user? await user.getIdToken():undefined,
                uid: user?.uid,
                isAuthenticated: true,
                email: user?.email
            };
            updateAuthSessionDetails(updatedPayload);
            store.dispatch(setAuthStatus(updatedPayload));
            store.dispatch(updateAuthState(updatedPayload));
            await store.dispatch(apiCallback(payload));
        });
};

axiosInstance.interceptors.request.use(function (config: AxiosRequestConfig<any>) {
    config.headers = { ...config.headers, ...getHeader() };
    return config;
});
export const temp_header = { ...getHeader() };
export default axiosInstance;
