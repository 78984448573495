import './style.scss';

type TProps = {
    label?: string|JSX.Element;
    type: string;
    onChangeHandler: React.ChangeEventHandler<HTMLInputElement>;
    value: string|number;
    classList?: string[];
    fieldType?: FIELD_TYPES;
    error?: string;
    required?:boolean;
    placeholder?:string;
};

const InputField = (props: TProps) => {
    const { label, type, onChangeHandler, value, classList = [], fieldType, error,required, placeholder} = props;
    switch (fieldType) {
        case FIELD_TYPES.TYPE_2:
            return (
                <div className={`field-1 ${classList.join(' ')}`}>
                    {label ? <label className={`label ${required?"required":''}`}   >{label}</label> : null}
                    <div className='input-conatiner-2'>
                        <input value={value} onChange={onChangeHandler} type={type} className={`${error ? "has-error" : ''}`}  placeholder={placeholder} />
                        {error ? <small className="error">{error}</small> : null}
                    </div>
                </div>
            );
        case FIELD_TYPES.TYPE_1:
        default:
            return (
                <div className={`field ${classList.join(' ')}`}>
                    {label ? <label className={`label ${required?"required":''}`}>{label}</label> : null}
                    <input value={value} onChange={onChangeHandler} type={type} className={error ? "has-error" : ''} placeholder={placeholder}/>
                    {error ? <small className="error">{error}</small> : null}
                </div>
            );
    }
};

export enum FIELD_TYPES {
    TYPE_1 = 'type1',
    TYPE_2 = 'type2'
}
export default InputField;
