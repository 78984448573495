import Button from 'components/common/Button';
import InputField from 'components/common/InputField';
import Select from 'components/common/Select';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SCENARIO_TYPES } from 'utils/constants';
import { TScenarioData } from 'utils/model';

type TProps = {
    type: SCENARIO_TYPES;
    formData: TScenarioData;
    title: string;
    onBaseSave: Function;
};

const MANDATORY_FIELDS = ['title', 'description'];
const Base = (props: TProps) => {
    const { type, formData, title, onBaseSave } = props;
    const [baseDetails, setBaseDetail] = useState({
        title: '',
        description: '',
        linkedInvestigationScenarioId: '',
        linkedFixScenarioId: ''
    });
    const [errorObj, setErrorObj] = useState<any>({});


    const fixDetails = useSelector((state: any) => state.scenarios[SCENARIO_TYPES.FIX]);
    const fixNameIdList = fixDetails
        ? fixDetails.map((fix: any) => `${fix.title} (${fix.id})`)
        : [];

    const onChange = (key: string, value: any) => {
        setBaseDetail({ ...baseDetails, [key]: value });
    };

    useEffect(() => {
        setBaseDetail(formData);
    }, []);


    const validate = () => {
        let isValid: boolean = true;
        const formDataTemp: any = _.cloneDeep(baseDetails);
        const errorObjTemp: any = _.cloneDeep(errorObj);
        for (let key in formDataTemp) {
            if (MANDATORY_FIELDS.includes(key) && _.isEmpty(formDataTemp[key])) {
                errorObjTemp[key] = 'Invalid ' + key;
                isValid = false;
            } else {
                errorObjTemp[key] = '';
            }
        }
        setErrorObj(errorObjTemp);
        isValid && onBaseSave(baseDetails);
        return isValid;
    };
    return (
        <>
        <div className="create-form">
            <div>
                <InputField
                    label="Title"
                    value={baseDetails.title}
                    onChangeHandler={(e) => onChange('title', e.target.value)}
                    type={'text'}
                    error={errorObj.title}
                    required={true}
                />
                <InputField
                    label="Description"
                    value={baseDetails.description}
                    onChangeHandler={(e) => onChange('description', e.target.value)}
                    type={'text'}
                    error={errorObj.description}
                    required={true}
                />
            </div>
            </div>
            {/* <div className='seperator'></div> */}
            <div className='button-container-basic'>
            <Button
                buttonText="Setup Data Extraction Methods >"
                onClick={() => validate()}
                classList={['add-update-btn']}
                
            />
            </div>
        
        </>
    );
};

export default Base;
