import {CHANNNEL_TYPE, DB_TYPES} from 'utils/constants';

const credentialConfig = {
    [CHANNNEL_TYPE.SLACK]:{
        webhookURL: ''
    }
    // ,
    // [CHANNNEL_TYPE.EMAIL]:{
    //     emailId: '',
    // }
}

export default credentialConfig;