import './style.scss';
import { useSelector } from 'react-redux';
import FixResponseCard from './FixResponseCard';
import { EXTRA_DS } from 'utils/constants';
import { getValueReplacedString } from 'utils/helperFunctions';
import Button, { TYPES } from 'components/common/Button';
import _ from 'lodash';
import { TQueryData } from 'utils/model';

type TProps = {
    scenarioId: number;
};

const FixDetails = (props: TProps) => {
    const { scenarioId } = props;
    const scenarioDetails = useSelector((state: any) => state.scenarioDetails[scenarioId]);

    const getExternalQueryForm = (checkItem:any) => {
        const updatedUrl = getValueReplacedString(checkItem.fetchQuery,checkItem.fetchVariableData);
        const onLinkOpen = () => {
            if (checkItem != null && window !== null) { window.open(updatedUrl, '_blank')?.focus(); }
        }
        return <div className='external-link'>
            <div className='head-section'>{checkItem.name}</div>
            <div className='ext-content'>
                <div className='message'>On Clicking open button page will be redirected to below link</div>
                <div className='link'>{updatedUrl}</div>
                <Button buttonText={"Open"} onClick={onLinkOpen} type={TYPES.SMALL_GREEN} />
            </div>
        </div>
    }
    
    const getSortedChecks = ()=>{
        const externalUrlChecks = _.filter(scenarioDetails.checks,{reportType: EXTRA_DS.EXTERNAL_URL});
        const extraChecks = _.filter(scenarioDetails.checks,(checkItem:TQueryData)=>checkItem.reportType !== EXTRA_DS.EXTERNAL_URL);
        return [...externalUrlChecks, ... extraChecks];
    }
    
    return (
        <div className="fix-details">
            <h2>Query Execution Response</h2>
            <div className="data-diff-container">
                {
                    getSortedChecks().map((checkItem: any) => {
                        if (checkItem.type === EXTRA_DS.EXTERNAL_URL) {
                            return getExternalQueryForm(checkItem);
                        };
                        return <FixResponseCard checkItem={checkItem} scenarioId={scenarioId}/>
                    })
                }
            </div>
        </div>
    );
};

export default FixDetails;
