export default {
    GET_SCENARIOS: 'console/scenarios?scenarioType=',
    GET_SCENARIO_BY_ID: '/console/scenarios/',
    CREATE_SCENARIO: 'console/scenarios/create',
    UPDATE_SCENARIO: '/console/scenarios/update/',
    DELETE_SCENARIO: '/console/scenarios/delete/',
    GET_DATASOURCES: '/console/datasources',
    CREATE_DATASOURCES: '/console/datasources/create',
    DELETE_DATASOURCE: '/console/datasources/delete/',
    EXECUTE_SCENARIO: '/console/scenarios/execute/',
    GET_AUDIT_TRAILS: '/console/audit-trails',
    CLEAR_CACHE: '/cache/clear',
    CLEAR_CACHE_BY_QUERY: '/cache/clear/',
    EXECUTE_READ_QUERY: '/console/scenarios/execute/query/read',
    EXECUTE_WRITE_QUERY: '/console/scenarios/execute/query/write',
    GET_EXCEPTION_SUMMARY: '/console/scenarios/summary/',
    GET_SNAPSHOT_LIST: '/cache/getSnapshots/',
    GET_SNAPSHOT_DATA: '/cache/getSnapshotData/',
    SAVE_SNAPSHOT:'/cache/saveLatestSnapshot/',
    GET_REMOTE_DS_LIST: '/console/datasource/remote/list',
    GET_DASHBOARD_LIST: '/console/dashboard/list',
    GET_PROXY_LIST: '/console/datasource/remote/proxy/list',
    GET_PROXY_DS_LIST: '/console/datasource/remote/list?proxyId=',
    CREATE_DASHBOARD: '/console/dashboard/create',
    UPDATE_DASHBOARD: '/console/dashboard/update',
    GET_DB_SNAPSHOT_LIST: '/console/dashboard/snapshots/',
    GET_DASHBOARD_SNAPSHOT: '/console/dashboard/summary/',
    DELETE_DASHBOARD: '/console/dashboard/delete/',
    GET_QUERY_DASHBOARD_SNAPSHOT: '/cache/getSnapshotByDashboard',
    USER_LOGIN: '/user/login',
    GET_DISTRIBUTION: '/console/distribution/list',
    CREATE_DISTRIBUTION: '/console/distribution/create',
    UPDATE_DISTRIBUTION:'/console/distribution/update',
    DELETE_DISTRIBUTION:'/console/distribution/delete/'
};