import { AiOutlineException, AiOutlineFileSearch, AiOutlineKey } from 'react-icons/ai';
import { GiAutoRepair } from 'react-icons/gi';
import { BsJournalText } from 'react-icons/bs';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import ComponentWrapper from 'components/console/ComponentWrapper';
import ScenarioSummary from 'components/console/ScenarioSummary';
import Datasource from 'components/console/Datasource';
import AuditTrails from 'components/console/AuditTrails';
import { SCENARIO_TYPES } from 'utils/constants';
import data from './sampleData.json'; //TODO: Remove this
import _ from 'lodash';
import ConsoleDashboard from 'components/console/ConsoleDashboard';
import Distribution from 'components/console/Distribution';
import { FaUsers } from 'react-icons/fa';


type TScenarioComponentProp = {
    type: SCENARIO_TYPES,
    executePath: string;
};
    
const ScenarioComponent = ({ type, executePath }: TScenarioComponentProp) => {
    
    return (
        <ComponentWrapper title={_.startCase(_.lowerCase(type) + ' Scenario')}>
            <ScenarioSummary type={type} executePath={executePath} />
        </ComponentWrapper>
    );
};

const components = [
    {
        title: 'Dashboard',
        icon: <MdOutlineSpaceDashboard size={22} color={'#000000a6'} />,
        component: <ComponentWrapper>
            <ConsoleDashboard /></ComponentWrapper>,
        hash: 'dashboard'
    },
    {
        title: 'Exception',
        icon: <AiOutlineException size={22} color={'#000000a6'} />,
        component: <ScenarioComponent type={SCENARIO_TYPES.EXCEPTION} executePath={"/console/exception-details"} />,
        hash: 'exception'
    },
    {
        title: 'Investigation',
        icon: <AiOutlineFileSearch size={22} color={'#000000a6'} />,
        component: <ScenarioComponent type={SCENARIO_TYPES.INVESTIGATION} executePath={"/console/execute-investigation"} />,
        hash: 'investigation'
    },
    {
        title: 'Fix',
        icon: <GiAutoRepair size={22} color={'#000000a6'} />,
        component: <ScenarioComponent type={SCENARIO_TYPES.FIX} executePath={"/console/execute-fix"} />,
        hash: 'fix'
    },
    {
        title: 'Data source',
        icon: <AiOutlineKey size={22} color={'#000000a6'} />,
        component: (
            <ComponentWrapper title="Datasources">
                <Datasource data={data.credential} />
            </ComponentWrapper>
        ),
        hash: 'datasource'
    },
    {
        title: 'Distribution Channel',
        icon: <FaUsers size={22} color={'#00000080'} />,
        component: (
            <ComponentWrapper title="Distribution Channel">
                <Distribution data={data.channel}/>
            </ComponentWrapper>
        ),
        hash: 'distribution'
    },
    {
        title: 'Audit trails',
        icon: <BsJournalText size={22} color={'#000000a6'} />,
        component: (
            <ComponentWrapper title="Audit trails">
                <AuditTrails />
            </ComponentWrapper>
        ),
        hash: 'audit-trail'
    }
];

export default components;
