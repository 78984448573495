import InputField from 'components/common/InputField';
import _ from 'lodash';

type TProps = {
    data: Object;
    onChangeHandler: Function; //It passes key and value
    error: any;
};

const Credential = (props: TProps) => {
    const { data, onChangeHandler, error } = props;
   

    return (
        <div className="credential-container">
            <h4>Channel details</h4>
            {Object.entries(data).map(([key, value]) => {
                
                return (
                    <InputField
                        required={key != 'key' ? true : false}
                        type="text"
                        label={_.startCase(key)}
                        value={value}
                        onChangeHandler={(e) => onChangeHandler(key, e.target.value)}
                        error={error[key]}
                    />
                );
            })}
        </div>
    );
};

export default Credential;
