import { TQueryData } from 'utils/model';
import _ from 'lodash';
import Button, { TYPES } from 'components/common/Button';
import { useEffect, useRef, useState } from 'react';
import Select, { FIELD_TYPES as SELECT_FIELD_TYPES } from 'components/common/Select';

type TProps = {
    createScenario: Function;
    queryList: TQueryData[];
    SelectedData: Function;
    newMethod: Boolean;
    deletedIndex: Boolean;
    edit:Boolean
};

const SearchData = (props: TProps) => {
    const { createScenario, queryList, SelectedData, newMethod, deletedIndex,edit } = props;
    const [isActive, setIsActive] = useState(0);
    const [selectedName, setSelectedName] = useState('');
    const [selectedindex, setIndex] = useState(-1);
    const options: any = [{ key: '', val: '' }];
    let temp: any[] = [];
    let dataArr: any[] = [];

    const scollToRef = useRef<any>([]);

    useEffect(() => {
        options.push({ key: -1, val: 'All' });
        queryList?.map((val: any, key) => {
            options.push({ key: key, val: `${val.name}` });
        });
        queryList
            ? selectedName == ''
                ? ''
                : ((temp = queryList.filter((val) => {
                      return val.name == selectedName;
                  })),
                  (dataArr = temp))
            : '';
            
      
        if (isActive + 1 == queryList?.length) {
            scollToRef.current[isActive]?.focus();
        }

        if (deletedIndex) {
            onChange('All', 0);
            setIsActive(0);
            setIndex(0);
            scollToRef.current[0]?.focus();
        }
       
    });

    useEffect(()=>{
        if(edit && selectedName!='')
        { 
            onChange(queryList[isActive].name,isActive)
        }
    },[edit])

    const onChange = (value: any, key: number) => {
        setIsActive(key);
        setIndex(key);
        setSelectedName(value);
        value == 'All' ? (setSelectedName(''), (dataArr = queryList), scollToRef.current[0]?.focus(),setIsActive(0),SelectedData(0)) : (dataArr = temp);
        SelectedData(key);
    };

    return (
        <div className="search-container">
            <div className="inner-search-container">
                <Select
                    label="Search Query Name"
                    value={selectedName}
                    onChangeHandler={(e: any, value: any) => onChange(value.val, value.key)}
                    options={options}
                    getOptionLabel={"val"}
                    fieldType={SELECT_FIELD_TYPES.TYPE_1}
                    error={''}
                    multiple={false}
                />
            </div>
            <div className={newMethod ? 'create-button-active' : 'create-button'}>
                <Button
                    buttonText="+  Create New Method"
                    onClick={() => {
                        onChange('', -1);
                        createScenario();
                    }}
                    width={'17.75vw'}
                    type={TYPES.PRIMARY_NOT_OUTLINED}
                    classList={['float-none']}
                />
            </div>

            <div className="data-list">
                {queryList?.map((item: TQueryData, index: number) => {
                    deletedIndex && scrollTo(0, 0);
                    isActive == -1 && setIsActive(index + 1);
                    return selectedName == '' ? (
                        <div>
                            <button
                                ref={(e) => (scollToRef.current[index] = e)}
                                className={`button-searchdata-table ${
                                    isActive == index ? 'highlight' : ''
                                } `}
                                key={index}
                                onClick={() => {
                                    SelectedData(index);
                                    setIsActive(index);
                                    setIndex(index);
                                }}>
                                <table className="table-details">
                                    <tbody>
                                        <tr>
                                            <td className="searchdata-key">Check Name</td>
                                            <td className="searchdata-value">{item.name}</td>
                                        </tr>
                                        <tr>
                                            <td className="searchdata-key">Method Type</td>
                                            <td className="searchdata-value">{item.reportType}</td>
                                        </tr>
                                        {/* <tr>
                                            <td className="searchdata-key">Data Source</td>
                                            <td className="searchdata-value">
                                                {item.datasourceId}
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </button>
                        </div>
                    ) : (
                        item.name == selectedName && index == isActive && (
                            <div>
                                <table
                                    className={`table-details-selected ${
                                        isActive == selectedindex ? 'highlight' : ''
                                    }`}>
                                    <tbody>
                                        <tr>
                                            <td>Check Name</td>
                                            <td>{item.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Method Type</td>
                                            <td>{item.reportType}</td>
                                        </tr>
                                        {/* <tr>
                                            <td>Data Source</td>
                                            <td>{item.datasourceId}</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default SearchData;
