import React, { useState } from "react";
import { MdOutlineContentCopy, MdOutlineFileDownload, MdOutlineRefresh } from "react-icons/md"
import FlashMessage from "../FlashMessage";
import './style.scss';

type TProps = {
    type: BUTTON_TYPES,
    onClick: Function,
}

const IconButton = (props: TProps) => {
    const { type, onClick } = props;
    const [message, setMessage] = useState<string>(SUCCESS_MESSAGES.EMPTY);

    const showSuccessMessage = () => {
        setMessage(SUCCESS_MESSAGES[type]);
        setTimeout(() => {
            setMessage(SUCCESS_MESSAGES.EMPTY)
        }, 4000)
    }

    const showFailedMessage = () => {
        setMessage(FAILED_MESSAGES[type]);
        setTimeout(() => {
            setMessage(FAILED_MESSAGES.EMPTY)
        }, 4000)
    }

    const onButtonClick = async () => {
        try {
            await onClick();
            showSuccessMessage();
        } catch (e) {
            showFailedMessage();
        }
    }

    return <React.Fragment>
        <FlashMessage message={message} />
        <div onClick={onButtonClick} title={type} className="icon-btn">
            {ICONS[type]}
        </div>
    </React.Fragment>
}

export enum BUTTON_TYPES {
    COPY = "COPY",
    DOWNLOAD = "DOWNLOAD",
    REFRESH = "REFRESH"
}
const SUCCESS_MESSAGES = {
    [BUTTON_TYPES.COPY]: "Sucessfully copied!!!",
    [BUTTON_TYPES.DOWNLOAD]: "Successfully downloaded!!!",
    [BUTTON_TYPES.REFRESH]: "Refreshed!!!",
    EMPTY: ''
}
const FAILED_MESSAGES = {
    [BUTTON_TYPES.COPY]: "Failed to copy!!!",
    [BUTTON_TYPES.DOWNLOAD]: "Download failed!!!",
    [BUTTON_TYPES.REFRESH]: "Refresh failed!!!",
    EMPTY: ''
}
const ICONS = {
    [BUTTON_TYPES.COPY]: <MdOutlineContentCopy size={16}/>,
    [BUTTON_TYPES.DOWNLOAD]: <MdOutlineFileDownload size={16}/>,
    [BUTTON_TYPES.REFRESH]: <MdOutlineRefresh size={16}/>
}

export default IconButton;