import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import QueryResponseComponent from '../QueryResponseComponent';
import { getScenarioDetailsByIdWrapper } from 'store/actions';

const QueryDetail = () => {
    const params: any = useParams();
    const scenarioid = params['scenarioId'];
    const queryId = params['queryId'];
    const scenarioDetails = useSelector((state: any) => state.scenarioDetails[scenarioid]);
    const [check, setCheck] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const initialSetup = async () => {
        if (!scenarioDetails || _.isEmpty(check))
            if (scenarioid) {
                await dispatch(getScenarioDetailsByIdWrapper(scenarioid));
            }
    };

    useEffect(() => {
        initialSetup();
    }, []);

    useEffect(() => {
        if (scenarioDetails != undefined)
            for (let checkRow of scenarioDetails.checks) {
                checkRow.id == queryId ? setCheck(() => [checkRow]) : '';
                loading && setLoading(false);
            }
    }, [scenarioDetails]);

    return (
        <div>
            {scenarioDetails && !loading && !_.isEmpty(check) ? (
                <QueryResponseComponent
                    data={check}
                    scenarioId={parseInt(scenarioid)}
                    isClearCacheEnabled={true}
                    isSnapshotEnabled={true}
                    singleQuery={true}
                />
            ) : (
                <div>Loading....</div>
            )}
        </div>
    );
};

export default QueryDetail;
