import ScenarioList from '../ScenarioList';
import { SCENARIO_TYPES, USER_ROLES } from 'utils/constants';
import { useEffect } from 'react';
import { getScenarios, getScenariosWrapper } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import _ from 'lodash';
import { getRole } from 'utils/helperFunctions';

type TProps = {
    type: SCENARIO_TYPES;
    executePath: string;
};

const ScenarioSummary = (props: TProps) => {
    const { type, executePath } = props;
    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const dashboardList = useSelector((state: any) => state.consoleDashboard.dashboardList);
    const data = useSelector((state: any) => state.scenarios[type]);

    const dispatch = useDispatch<AppDispatch>();

    const getScenarioList = ()=>{
        if(dashboardList?.length==0 && getRole() === USER_ROLES.VIEWER)
        {
            return [];
        }
        else if(getRole() === USER_ROLES.VIEWER && selectedDashboard){
            const scenarioIdList = _.uniq(selectedDashboard.dashboardQueries.map((item:any)=>item.scenarioId));
            return data.filter((scenario:any)=> scenarioIdList.indexOf(scenario.id) !== -1);
        }
         
        return data;
    }

    return <ScenarioList data={getScenarioList()} type={type} executePath={executePath}/>;
};

export default ScenarioSummary;
