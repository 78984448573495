import DynamicGrid from 'components/common/DynamicGrid';
import { useDispatch, useSelector } from 'react-redux';
import { resetError, setError } from 'store/reducers/ErrorSlice';
import { getReadQueryExecutionData, getSnapshotData } from 'store/actions';
import React, { useCallback, useEffect, useState } from 'react';
import { clearQueryCacheAPI, fetchSnapshots, saveSnapshot } from 'apis';
import { hideLoader, showLoader } from 'store/reducers/LoaderSlice';
import _ from 'lodash';
import SaveSnapshotDialog from './SaveSnapshotDialog';
import BtnCellRenderer from './BtnCellRenderer';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Modal, Typography } from '@mui/material';
import { SNAPSHOT_DB_LATEST, USER_ROLES } from 'utils/constants';
import { getFilteredQueryDSMapList, getRole } from 'utils/helperFunctions';

type TProps = {
    checkItem: any;
    index: number;
    copyToClipBoard: Function;
    getFieldWiseCellRules: Function;
    getFieldConfig: Function;
    scenarioId: number;
    isClearCacheEnabled: boolean;
    isSnapshotEnabled?: boolean;
    executionDetails?:any;
    disableCache?:boolean;
};

let loading = false;
const ResponseCard = (props: TProps) => {
    const {
        checkItem,
        index,
        copyToClipBoard,
        getFieldConfig,
        getFieldWiseCellRules,
        scenarioId,
        isClearCacheEnabled,
        isSnapshotEnabled,
        executionDetails,
        disableCache
    } = props;
    const [showLocalLoader, setShowLocalLoader] = useState(true);
    const [snapshotList, setSnapshotList] = useState<any[]>([]);
    const [currentSnapshot, setCurrentSnapshot] = useState({ label: '' });
    const [showSaveDialog, setShowSaveDialog] = useState(false);
   const [refreshingData,setRefreshingData] = useState(0);
   const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
   const selectedsnapshot = useSelector((state: any) => state.consoleDashboard.selectedsnapshot);
    const navigate = useNavigate();
    const locationData: any = useLocation();
    const dispatch = useDispatch();

    
    const loadData = async () => {
        try {
            dispatch(resetError());
            !loading && setShowLocalLoader(true);
            const payload = {
                checkId: checkItem.id,
                scenarioId: scenarioId,
                isDashboardEnabled:  locationData.state?.isDashboardEnabled,
                proxyId: locationData.state?.isDashboardEnabled? locationData.state.selectedDashboard.proxyId:executionDetails.proxyId,
                dashboardId: locationData.state?.isDashboardEnabled ? locationData.state.selectedDashboard.id
                    : getRole() === USER_ROLES.VIEWER ? executionDetails.dashboardId : null,
                selectedsnapshot: locationData.state?.isDashboardEnabled?locationData.state.selectedsnapshot:null,
                queryRequestList: 
                !locationData.state?.isDashboardEnabled
                ? getFilteredQueryDSMapList(checkItem.queries,executionDetails.queryDsMap)
                : getFilteredQueryDSMapList(checkItem.queries,locationData.state.selectedDashboard.dashboardQueries),
                disableCache: disableCache
            }
            await dispatch(
                getReadQueryExecutionData(payload)
            );
          
            if (loading) {
                loading = false;
                setRefreshingData(0)
            }
            //NOTE: below code was to load snapshot list. since we disable snapshot selection in query level commenting below call
            // locationData.state?.isDashboardEnabled && selectedsnapshot.executedAt=== SNAPSHOT_DB_LATEST && await loadSnapshotList();
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            setShowLocalLoader(false);
        }
    };
    useEffect(() => {
        loadData();
    }, [executionDetails]);
  
    const loadSnapshotList = async () => {
        const response = await fetchSnapshots(locationData.state.selectedDashboard.id, checkItem.id);
        if (response?.data?.data) {
            const snapshots = response.data.data;
            const updatedSnapshots = snapshots.map((snapshotItem: any) => {
                return { ...snapshotItem, label: snapshotItem.relativeFilePath };
            });
            const latestSnapshot = _.find(updatedSnapshots, { snapshotType: 'LATEST' });
            setCurrentSnapshot(latestSnapshot);
            setSnapshotList(updatedSnapshots);
        }
    };

    const clearCache: any = async (queryId: number) => {
        try {
            dispatch(showLoader());

            await clearQueryCacheAPI(selectedDashboard.id,queryId);
        } catch (e) {
        } finally {
            dispatch(hideLoader());
        }
    };
    const clearCacheAndRefresh = async () => {
        loading = true;
        setRefreshingData(checkItem.id);
        await clearCache(checkItem.id);
        await loadData();
    };

    const getDetails = () => {
        return (
            <div className="query-meta">
                <div className="section">
                    <label>Executed string: </label>
                    <div className="content">{checkItem.executedQuery}</div>
                </div>
                {checkItem.notes ? (
                    <div className="section">
                        <label>Notes: </label>
                        <pre className="content">{checkItem.notes}</pre>
                    </div>
                ) : null}
            </div>
        );
    };

    const onSnapshotUpdate = (event: any, value: any) => {
        //TODO: Add loader on saving
        setCurrentSnapshot(value);
        dispatch(getSnapshotData({ snapshotId: value.id, scenarioId, checkId: checkItem.id }));
    };

    const onSaveHandler = () => {
        setShowSaveDialog(true);
    };

    const onSnapshotSave = async (customName: string) => {
        const request = {
            customName
        };
        try {
            const response = await saveSnapshot(locationData.state.selectedDashboard.id,checkItem.id, request);
            const snapshotDetails = response.data.data;
            setSnapshotList([
                ...snapshotList,
                { ...snapshotDetails, label: snapshotDetails.relativeFilePath }
            ]);
        } catch (e) {
            //TODO: Handle error case
        }
        setShowSaveDialog(false);
    };

    const getCustomConfig = useCallback(() => {
        const customConfig = [];
        if (checkItem.linkedInvestigationScenarioId) {
            customConfig.push({
                field: 'investigation',
                cellRenderer: BtnCellRenderer,
                cellRendererParams: {
                    label: 'Investigate',
                    clicked: (rowData: any) => {
                        const state = {
                            refData: rowData,
                            exceptionScenarioId: scenarioId,
                            exceptionCheckId: checkItem.id,
                            isDashboardEnabled:  locationData.state?.isDashboardEnabled,
                            selectedDashboard: locationData.state?.isDashboardEnabled? locationData.state?.selectedDashboard: undefined,
                            selectedsnapshot: locationData.state?.isDashboardEnabled? locationData.state?.selectedsnapshot: undefined
                          };
                        navigate(
                            `/console/execute-investigation/${checkItem.linkedInvestigationScenarioId}`,
                            { state }
                        );
                    }
                }
            });
        }
        if (checkItem.linkedFixScenarioId) {
            customConfig.push({
                field: 'fix',
                cellRenderer: BtnCellRenderer,
                cellRendererParams: {
                    label: 'Fix',
                    clicked: (rowData: any) => {
                        const state = {
                            refData: rowData,
                            exceptionScenarioId: scenarioId,
                            exceptionCheckId: checkItem.id,
                            isDashboardEnabled:  locationData.state?.isDashboardEnabled,
                            selectedDashboard: locationData.state?.isDashboardEnabled? locationData.state?.selectedDashboard: undefined,
                            selectedsnapshot: locationData.state?.isDashboardEnabled? locationData.state?.selectedsnapshot: undefined
                          };
                        navigate(`/console/execute-fix/${checkItem.linkedFixScenarioId}`, {
                            state
                        });
                    }
                }
            });
        }
        return customConfig;
    }, [checkItem]);

    return   showLocalLoader ? (
        <div>Loading...</div>
    ) : checkItem.error ||
      (!Array.isArray(checkItem.currentData) &&
          !Array.isArray(checkItem.currentData.data) &&
          !checkItem.currentData.success) && checkItem.currentData!='' ? (
        <div className="error">{checkItem.error??checkItem.currentData.message}</div>
    ) : checkItem.currentData.dataType === 'jsonml' ? (
        <div className="multi-container">
           
            <div className="multi-table-meta">
                <h3 className="title">{checkItem.name}</h3>
                {getDetails()}
            </div>
            {checkItem.currentData?.data?.map((dataItem: any) => (
                <DynamicGrid
                    data={dataItem}
                    fieldWiseCellRules={getFieldWiseCellRules(index)}
                    fieldConfig={getFieldConfig(index)}
                    copyToClipBoard={(data: any[]) => copyToClipBoard(data, index)}
                    title={checkItem.name}
                    loading={loading}
                />
            ))}
        </div>
    ) : (
        <React.Fragment>
            <DynamicGrid
                data={ checkItem.currentData==''?['']:
                    Array.isArray(checkItem.currentData)
                        ? checkItem.currentData
                        : Array.isArray(checkItem.currentData.data)
                        ? checkItem.currentData.data
                        : [checkItem.currentData.data]
                }
                fieldWiseCellRules={getFieldWiseCellRules(index)}
                fieldConfig={getFieldConfig(index)}
                copyToClipBoard={(data: any[]) => copyToClipBoard(data, index)}
                title={checkItem.name}
                clearCacheAndRefresh={locationData.state?.isDashboardEnabled && selectedsnapshot.executedAt=== SNAPSHOT_DB_LATEST? clearCacheAndRefresh: undefined}
                showTitleSection={true}
                details={getDetails()}
                lastUpdatedTime={checkItem.executedTime}
                // enableDataChange={locationData.state?.isDashboardEnabled && selectedsnapshot.executedAt=== SNAPSHOT_DB_LATEST ? isSnapshotEnabled: undefined}
                // dataChangeOptions={snapshotList}
                // onDataChangeHandler={onSnapshotUpdate}
                // onDataChangeSelectedOption={currentSnapshot}
                // onSaveHandler={
                //     isSnapshotEnabled && locationData.state?.isDashboardEnabled && selectedsnapshot.executedAt=== SNAPSHOT_DB_LATEST
                //         ? queryItem.snapshotType === 'LATEST'
                //             ? onSaveHandler
                //             : undefined
                //         : undefined
                // }
                customColumns={getCustomConfig()}
                loading={loading}
                id={checkItem.id}
                refreshingData={refreshingData}
            />
            <SaveSnapshotDialog
                show={showSaveDialog}
                onSnapshotSave={onSnapshotSave}
                queryDetails={checkItem}
                onClose={() => setShowSaveDialog(false)}
            />
        </React.Fragment>
    );
};

export default ResponseCard;
