import PageNotFound from "containers/PageNotFound";
import React from "react";
import { Navigate } from "react-router-dom";
import { checkValidity, getRole } from "utils/helperFunctions";

type TProps = {
    children: React.ReactNode;
};

const PrivateRoute = (props:TProps):JSX.Element => {
    const {children} = props;
    const userRole=getRole();
    if(userRole=='VIEWER')
    {
       const isValid = checkValidity();
       if(!isValid)
       return <React.Fragment><PageNotFound/></React.Fragment> ;
    }

    if(sessionStorage.getItem("isAuthenticated")==="true")
    {
        return <React.Fragment>{children}</React.Fragment>;
    }
    return <Navigate to="/"/>
}

export default PrivateRoute;