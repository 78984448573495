import MaterialReactTable, { MRT_ColumnDef, MRT_Icons } from 'material-react-table';
import './style.scss';
import { useEffect, useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BiArrowToRight, BiDownArrow } from 'react-icons/bi';

type TProps = {
    columns: any;
    rows: any;
    groupBy?:string[];
    leftPin?:string[];
    rightPin?:string[];
    columnToHide?:string;
    width?:number;
    tablewidth?:string;
    filterRequired:boolean;
};

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        '& .MuiPaper-root': {
            margin: 'auto',
            width: '100%',
            borderTop: '1px solid #d3d3d3'
        },
        '& .MuiTableContainer-root': {
            border: '1px solid #d3d3d3'
        },
        '& .MuiTableCell-root': {
            textAlign: 'center'
        },
        '& .MuiAlert-message': {
            display: 'none'
        },
        '& .Mui-TableHeadCell-Content': {
            color: '#919191',
            fontFamily: 'Roboto',
            fontSize: '14px',
            justifyContent: 'center'
        },
        '& .Mui-TableHeadCell-root': {
            minWidth: '10px',
            maxWidth: '100px'
        },
        '& .MuiTableCell-body': {
            fontSize: '18px',
            fontWeight: 500,
            color: '#202020',
            textTransform: 'capitalize'
        },
        '& .MuiTableCell-head': {
            borderRight: '1px solid #d3d3d3'
        },
        '& .MuiTableRow-root': {
            border: '1px solid #d3d3d3'
        },
        '& .Mui-disabled': {
            display: 'none'
        },
        '& .MuiIconButton-root': {
            color: '#b261b5'
        }
    }
}));

const fontAwesomeIcons: Partial<MRT_Icons> = {
    ArrowDownwardIcon: (props: any) => <BiDownArrow {...props} color={'yellow'} />,
    ArrowRightIcon: <BiArrowToRight />
};

const TablularView = (props: TProps) => {
    const { columns, rows, groupBy, leftPin,rightPin, width, tablewidth, filterRequired } = props;
    const classes = useStyles();
    const [row, setRow] = useState(rows);
    
    useEffect(() => {
        setRow(rows);
    }, [rows]);


    const columnss = useMemo<MRT_ColumnDef<any>[]>(() => columns, []);

    return (
        <div className="table-component" style={{width:tablewidth?tablewidth:''}} >
            <div className={classes.root}>
                
               {rows && <MaterialReactTable
                    enableExpandAll={true}
                    enableFilters={true}
                    enableColumnFilters ={filterRequired}
                    enableSorting={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableColumnActions={false}
                    enableGrouping={true}
                    enableHiding={false}
                    enablePagination={false}
                    columns={columnss}
                    data={row.map((i:any)=>{return i})}
                    initialState={{
                        grouping: groupBy,
                        expanded: true,
                        columnPinning: {
                            left:leftPin,
                            right: rightPin
                        },
                        columnVisibility: {'namedesc':false, 'date':false, 'state':false}  
                    }}
                    defaultColumn={{
                        size: width, //make columns wider by default
                      }}
                    icons={fontAwesomeIcons}
                    
                />}
            </div>
        </div>
    );
};

export default TablularView;
