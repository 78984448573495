import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getDatasources, addDatasource, removeDatasource } from 'store/actions';

const initialState = {
    datasources: []
};

const reducers = {};

const extraReducers: any = {
    [getDatasources.fulfilled]: (state: any, action: any) => {
        const { data } = action.payload;
        state.datasources = data;
    },
    [addDatasource.fulfilled]: (state: any, action: any) => {
        const { data } = action.payload;
        data && state.datasources.push(data);
    },
    [removeDatasource.fulfilled]: (state: any, action: any) => {
        const { id } = action.payload;
        if (id) {
            _.remove(state.datasources, { id });
        }
    }
};
export const datasourceSummarySlice = createSlice({
    name: 'datasources',
    initialState,
    reducers,
    extraReducers
});

export const {} = datasourceSummarySlice.actions;
export default datasourceSummarySlice.reducer;
