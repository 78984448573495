import Modal from 'components/common/Modal';
import { useEffect, useState } from 'react';
import Button from 'components/common/Button';
import CreateDistributionForm from './CreateDistributionForm';
import { CHANNNEL_TYPE } from 'utils/constants';
import credentialConfig from './credentialConfig';
import { addDistribution, updateDistributionChannel } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TDistribution } from 'utils/model';
import _ from 'lodash';

type TProps = { data?: TDistribution; submit: Function, closeModal:Function; };

const INITIAL_DATA = {
    name: '',
    channelType: CHANNNEL_TYPE.SLACK,
    channelDetails: credentialConfig[CHANNNEL_TYPE.SLACK]
};

const MANDATORY_QUERY_FIELDS = ['name', 'channelType'];
const MANDATORY_SLACK_FIELDS = ['webhookURL'];
const MANDATORY_EMAIL_FIELDS = ['emailId'];

const CreateDistribution = (props: TProps) => {
    const { data, submit, closeModal } = props;
    const distributionData = useSelector((state: any) => state.distribution.distribution);
    const [formData, setFormData] = useState<TDistribution>(INITIAL_DATA);
    const [show, setShow] = useState(false);
    const [errorObj, setErrorObj] = useState<any>({});
    const [edit, setEdit] = useState(false);
    

    useEffect(() => {
        if (!_.isEmpty(data?.name)) {
            setEdit(true);
            setFormData(data ? data : INITIAL_DATA);
            setShow(true);
        }
        if (edit) {
            setFormData(data ? data : INITIAL_DATA);
            setShow(true);
        }
    }, [data]);

    const validate = () => {
        let isValid: boolean = true;
        const formDataTemp: any = _.cloneDeep(formData);
        const errorObjTemp = _.cloneDeep(errorObj);
        const channelDetailsTemp = _.cloneDeep(formData.channelDetails);

        const MANDATORY_CRED =
            formDataTemp.channelType == 'SLACK' ? MANDATORY_SLACK_FIELDS : MANDATORY_EMAIL_FIELDS;

        for (let key in formDataTemp) {
            if (MANDATORY_QUERY_FIELDS.includes(key) && _.isEmpty(formDataTemp[key])) {
                errorObjTemp[key] = 'Invalid ' + key;
                isValid = false;
            } 
            else {
                errorObjTemp[key] = '';
            }
        }

        for (let key in channelDetailsTemp) {
            if (MANDATORY_CRED.includes(key) && _.isEmpty(channelDetailsTemp[key])) {
                errorObjTemp[key] = 'Invalid ' + key;
                isValid = false;
            } else {
                errorObjTemp[key] = '';
            }
        }

       for(let item of distributionData)
       {
        if(item.name==formDataTemp['name'] && !edit)
        {
            isValid=false;
            errorObjTemp['name'] = 'Name already exists';
        }
       }

        setErrorObj(errorObjTemp);
        return isValid;
    };

    const onSubmit = async () => {
        if (validate()) {
            setEdit(false);
            submit(formData);
            setShow(false);
            setFormData(INITIAL_DATA);
            setErrorObj({});
        }
    };
    return (
        <div className="create-datasource">
            <div className="create-datasource-btn">
                <Button
                    buttonText="Create Distribution"
                    onClick={() => {
                        setShow(true);
                        setFormData(INITIAL_DATA);
                    }}
                    classList={['create-btn']}
                />
            </div>
            <Modal
                show={show}
                onClose={() => {setShow(false);setEdit(false);closeModal()}}
                onSubmit={onSubmit}
                submitText="Save"
                title={`Create Distribution Channel`}
                showButton={true}>
                <CreateDistributionForm
                    formData={formData}
                    setFormData={setFormData}
                    error={errorObj}
                />
            </Modal>
        </div>
    );
};

export default CreateDistribution;
