import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import React, { useState } from 'react';

type TProps = {
    detailsRef: React.RefObject<HTMLInputElement>
}
const ShowDetails = (props: TProps) => {
    const { detailsRef } = props;
    const [show, setShow] = useState(false);

    const onDetailsClick = () => {
        if (detailsRef && detailsRef.current) {
            if (show) {
                detailsRef.current.style.maxHeight = '0px';
                setShow(false);
            } else {
                detailsRef.current.style.maxHeight = '300px';
                setShow(true);
            }
        }
    }
    return <div className='details-controller' onClick={onDetailsClick}>
        <AiOutlineInfoCircle size={12} className="details-info" />
        {show
            ? <React.Fragment>
                <div>Hide Details</div>
                <MdKeyboardArrowDown size={12} />
            </React.Fragment>
            : <React.Fragment>
                <div>Show Details</div>
                <MdKeyboardArrowUp size={12} />
            </React.Fragment>}
    </div>
}

export default ShowDetails;