import TabularView from 'components/common/Table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { BsCaretRight } from 'react-icons/bs';
import { COLUMNDETAILS_DASHBOARD } from 'utils/constants';

const INITIAL_DATA=[{
    scenarioName: '', queryName: '', summary: '', updatedAt: '', scenarioId:'', queryId:''
}]


const TabularDisplay = () => {
    const data = useSelector((state: any) => state.consoleDashboard.data);
    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const selectedsnapshot = useSelector((state: any) => state.consoleDashboard.selectedsnapshot);
    const navigate = useNavigate();
    const [row,setRow] = useState(INITIAL_DATA);
    const [loading, setLoading] = useState(true);

    const viewData=(index:any)=>{
        let selectedRowDetails:any;
        for(let x of row)
        {
            if(x.queryId==index)
            selectedRowDetails=x;
        }
        navigate('../exception-detail/' + selectedRowDetails['scenarioId'] + `/${selectedRowDetails['queryId']}`, { state: { isDashboardEnabled: true, selectedDashboard, selectedsnapshot } });
    }

    useEffect(() => {
        let temp_details = data.flatMap((item: any, index: number) => {
           return item.checks?.map((query: any, key: number) => {
                let obj: any = {};
                obj['scenarioName'] = item.title;
                obj['queryName'] = query.name;
                obj['summary'] = query.value;
                obj['updatedAt'] = <div className='updated-time-div'><span>{query.lastExecutionTime?.slice(0,12)}</span><span>{query.lastExecutionTime?.slice(13)}</span></div> ;
                obj['scenarioId'] = item.id;
                obj['queryId']=query.id;
                obj['view']= <div className="ctrl-btn execute"><BsCaretRight size={20} onClick={()=>viewData(query.id)} /></div> 
                return obj;
            });
        });
        setLoading(false);
        setRow(temp_details);   
    });


    return (
        <div>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>{row  && <TabularView filterRequired={true} columns={COLUMNDETAILS_DASHBOARD} rows={row} groupBy={['scenarioName']} leftPin={[]} rightPin={['scenarioName', 'queryName', 'summary', 'updatedAt', 'view']} />}</>
               
            )}
        </div>
    );
};

export default TabularDisplay;
