import Select, { FIELD_TYPES as SELECT_FIELD_TYPES } from '../Select';
import { useSelector } from 'react-redux';
import { getConsoleDashboardWrapper, getDashboardListWrapper } from 'store/actions';
import { setReloadRequired, setSelectedDashboard } from 'store/reducers/consoleDashboardSlice';
import { useDispatch } from 'react-redux';
import ButtonDropdown from '../ButtonDropdown';
import { MdArrowDropDown, MdOutlineSpaceDashboard } from 'react-icons/md';
import {RiDashboardLine} from 'react-icons/ri';
import { useEffect } from 'react';
import { getRole } from 'utils/helperFunctions';

const DashboardSelection = () => {
    const dashboardList = useSelector((state: any) => state.consoleDashboard.dashboardList);
    const role=getRole();
    const dispatch = useDispatch();

    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const onDashboardChange = async (dashboard: any) => {
        try {
            dispatch(setReloadRequired(true));
            dispatch(setSelectedDashboard(dashboard));
        } catch (e) {}
    };
    
    return (
        <div className={`${role=='VIEWER'?"view-dashboard":""} dashboard-div`}>
       { role=='VIEWER' &&   <MdOutlineSpaceDashboard size={22} color={'#706b6b'} style={{marginRight:'-5px'}}/> }
           <ButtonDropdown
                menuItems={dashboardList}
                selectedMenuItems={selectedDashboard}
                handleSelect={onDashboardChange}
                label="name"
                buttonLabel={<h2>{selectedDashboard?.name} <MdArrowDropDown size={22}/></h2>}
            />
        </div>
    );
};

export default DashboardSelection;
