import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getConsoleDashboard, getDashboardList, getConsoleDashboardSnapshot, getConsoleDashboardDelete, getConsoleDashboardonRefresh } from 'store/actions';
import { SNAPSHOT_DB_LATEST } from 'utils/constants';

const defaultSnapshot = {
    executedAt: SNAPSHOT_DB_LATEST
};

const initialState = {
    data: [],
    isDashboardListLoaded: false,
    dashboardList: [],
    currentSnapshotList: [defaultSnapshot],
    selectedsnapshot: defaultSnapshot,
    selectedDashboard: null,
    reloadRequired:false
};

const reducers = {
    setSelectedSnapshot: (state: any, action: any) => {
        state.selectedsnapshot = action.payload;
    },
    setSelectedDashboard: (state: any, action: any) => {
        state.selectedDashboard = action.payload;
    },
    setDashboardList: (state: any, action: any) => {
       const {data,type} = action.payload;
       if(type=='new')
        {      
         state.dashboardList.push(data);
        }
        else{
            const item= _.findIndex(state.dashboardList,{id:data.id})
            state.dashboardList[item]=data
        }
    },
    setReloadRequired: (state: any, action: any) => {
        state.reloadRequired = action.payload;
    },
};

const extraReducers:any ={
    [getConsoleDashboard.fulfilled]: (state:any, action:any)=>{
     if(action.payload.isUpdateRequired)
       {
            if(_.isEmpty(action.payload?.data))
        {
            state.data = [];
            state.currentSnapshotList = [];
            state.selectedsnapshot = defaultSnapshot;
        }
        else
        { 
            state.currentSnapshotList = [defaultSnapshot, ...action.payload.snapshotList];
            state.data = action.payload.data;
            state.selectedsnapshot = defaultSnapshot;
        }
       }
    },
    [getConsoleDashboardonRefresh.fulfilled]: (state:any, action:any)=>{
        if(_.isEmpty(state.dashboardList))
        {
            state.data = action.payload.data;
            state.currentSnapshotList = [defaultSnapshot, ...action.payload.snapshotList];
            state.selectedsnapshot = defaultSnapshot;
        }
        else if(action.payload.dashboardId==state.selectedDashboard?.id)
        {
            state.data = action.payload.data;
            state.currentSnapshotList = [defaultSnapshot, ...action.payload.snapshotList];
            state.selectedsnapshot = defaultSnapshot;
        }
    },
    [getConsoleDashboardSnapshot.fulfilled]: (state:any, action:any)=>{
        state.data = action.payload.data;
    },
    [getDashboardList.fulfilled]: (state: any, action: any)=>{
        state.dashboardList = action.payload.dashboardList;
        if (state.selectedDashboard) {
            const currentDashboard = _.find(action.payload.dashboardList, { id: state.selectedDashboard.id });
            state.selectedDashboard = currentDashboard;
        }else{
            state.selectedDashboard = action.payload.dashboardList[0];
        }
        state.isDashboardListLoaded = true;
    },
    [getDashboardList.rejected]: (state: any, action: any)=>{
        state.isDashboardListLoaded = true;
    },
    [getConsoleDashboardDelete.fulfilled]: (state: any, action: any)=>{
        const {dashboardList, selectedDashboard, snapshotList, data}= action.payload;
        state.data = data;
        state.dashboardList = dashboardList;
        state.selectedsnapshot = defaultSnapshot;
        state.currentSnapshotList = [defaultSnapshot, ...snapshotList];
        state.selectedDashboard = selectedDashboard;
    },
};

export const consoleDashboardSlice = createSlice({
    name: 'consoleDashboard',
    initialState,
    reducers,
    extraReducers
});

export const {setSelectedSnapshot, setSelectedDashboard, setDashboardList, setReloadRequired} = consoleDashboardSlice.actions;
export default consoleDashboardSlice.reducer;