import InputField from 'components/common/InputField';
import Select from 'components/common/Select';
import Credential from './Credential';
import { DATASOURCE_TYPE } from 'utils/constants';
import credentialConfig from './credentialConfig';
import { useState } from 'react';

type TProps = {
    formData: any;
    setFormData: Function;
    error: any;
};

const CreateDatasourceForm = (props: TProps) => {
    const { formData, setFormData, error } = props;
    const setTitle = (e: any) => {
        setFormData({ ...formData, title: e.target.value });
    };

    const setDescription = (e: any) => {
        setFormData({ ...formData, description: e.target.value });
    };

    const setCredentials = (key: string, value: string) => {
        setFormData({ ...formData, credentials: { ...formData.credentials, [key]: value } });
    };

    const setDatasourceType = (value: DATASOURCE_TYPE) => {
        setFormData({
            ...formData,
            datasourceType: value,
            credentials: credentialConfig[value] ?? formData.credentials
        });
    };

    const getOptions = () => {
        return Object.keys(DATASOURCE_TYPE);
    };

    return (
        <div className="create-datasource-form">
            <InputField
                label="Title"
                required={true}
                value={formData.title}
                onChangeHandler={setTitle}
                type={'text'}
                error={error.title}
            />
            <InputField
                label="Description"
                required={true}
                value={formData.description}
                onChangeHandler={setDescription}
                type={'text'}
                error={error.description}
            />
            <Select
                label="Datasource type"
                required={true}
                value={formData.datasourceType}
                onChangeHandler={(e: any, value: any) => {
                    setDatasourceType(value);
                }}
                options={getOptions()}
                error={error.datasourceType}
            />
            <Credential
                data={formData.credentials}
                onChangeHandler={setCredentials}
                error={error}
            />
        </div>
    );
};

export default CreateDatasourceForm;
