import Button, { TYPES } from 'components/common/Button';
import InputField, { FIELD_TYPES } from 'components/common/InputField';
import React, { useEffect, useRef, useState } from 'react';
import { TColorConfig, TQueryData, TQueryDetail } from 'utils/model';
import { getUrlVariables, getVariables } from 'utils/helperFunctions';
import Select, { FIELD_TYPES as SELECT_FIELD_TYPES } from 'components/common/Select';
import {
    CHECK_TYPE,
    DATASOURCE_TYPE,
    EXTRA_DS,
    RESPONSE_TYPE,
    SCENARIO_TYPES,
    scheduleTimeList,
    SUMMARY_OPERATION_TYPES
} from 'utils/constants';
import _ from 'lodash';
import ConfigureColorScheme from '../ConfigureColorScheme';
import TextField from 'components/common/TextField';
import { useSelector } from 'react-redux';
import { BiInfoCircle } from 'react-icons/bi';
import InfoCard, { CARDTYPES } from 'components/common/InfoCard';
import Switch from 'components/common/Switch';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import AddQuery from './AddQuery';
import QueryList from './QueryList';
import TooltipComponent from 'components/common/Tooltip';
import { SUMMARY_SQL, VALIDATION_CRITERIA } from 'utils/info';

type TProps = {
    index?: number;
    dataObject?: TQueryData;
    onSubmit: Function;
    onBack: Function;
    datasourcesNameIdList: string[];
    type: SCENARIO_TYPES;
    pasteData?: TQueryData;
};

const INITIAL_QUERY_OBJ: TQueryDetail[] = [
    {
        name: '',
        type: '',
        fetchQuery: '',
        updateQuery: '',
        fetchVariables: [],
        updateVariables: [],
        checkId: '',
        methodType:'',
        responsePath:'',
        querySummarySQL:'',
        responseType:'',
        payload:'',
        isDynamic:false,
        startPeriod:0,
        endPeriod:0,
        startPeriodUnit:'DAYS',
        endPeriodUnit:'DAYS',
        periodStartKey:'',
        periodEndKey:'',
        timeFormat:'',
        startTime:'',
        startTimeKey:'',
        startTimeFormat:'',
    }
];

const INTIAL_DATA_OBJ: TQueryData = {
    name: '',
    reportType: CHECK_TYPE.LIST,
    // fetchQuery: '',
    // updateQuery: '',
    // fetchVariables: [],
    // updateVariables: [],
    colorConfig: [],
    cacheDuration: 0,
    editMode: true,
    notes: '',
    linkedInvestigationScenarioId: null,
    linkedFixScenarioId: null,
    operationType: SUMMARY_OPERATION_TYPES.COUNT,
    // columnName: '',
    summaryTemplate: '',
    summarySQL: 'select count(*) as summaryValue from :table',
    dataInSummary: false,
    validationCriteria: 'summaryValue==0',
    queries: []
};
const MANDATORY_CHECK_FIELDS = ['name', 'reportType', 'summarySQL','validationCriteria'];
const MANDATORY_QUERY_FIELDS = ['name', 'type', 'fetchQuery','responseType'];
const MANDATORY_DYNAMIC_FIELDS = ['startPeriod','endPeriod','startPeriodUnit','endPeriodUnit','periodStartKey','periodEndKey','timeFormat']
const MANDATORY_QUERY_FIELDS_INV_FIX = ['name', 'type', 'fetchQuery'];

const CreateOrUpdate = (props: TProps) => {
    const { dataObject, index, onSubmit, datasourcesNameIdList, type, onBack, pasteData } = props;
    const [formData, setFormData] = useState<TQueryData>(INTIAL_DATA_OBJ);
    const [formDataQuery, setFormDataQuery] = useState<any>(INITIAL_QUERY_OBJ[0]);
    const [errorObj, setErrorObj] = useState<any>({});
    const [errorObjQuery, setErrorObjQuery] = useState<any>({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [edit,setEdit] = useState(false);
    const [editIndex,setEditIndex] = useState(-1);
    const investigationDetails = useSelector(
        (state: any) => state.scenarios[SCENARIO_TYPES.INVESTIGATION]
    );
    const investigationNameIdList = investigationDetails
        ? investigationDetails.map((investigation: any) => {
              return { ...investigation, label: `${investigation.title} (${investigation.id})` };
          })
        : [];

    const fixDetails = useSelector((state: any) => state.scenarios[SCENARIO_TYPES.FIX]);
    const fixNameIdList = fixDetails
        ? fixDetails.map((fix: any) => {
              return { ...fix, label: `${fix.title} (${fix.id})` };
          })
        : [];
    const queryContainerRef: any = useRef(null);
    const querySummaryRef: any = useRef(null);

    const getUpdatedObject = (dataObject: TQueryData) => {
        const linkedInvestigationScenarioId = _.find(investigationDetails, {
            id: dataObject.linkedInvestigationScenarioId
        });
        const linkedFixScenarioId = _.find(fixDetails, { id: dataObject.linkedFixScenarioId });
        const updatedDataObj: TQueryData = {
            ...dataObject,
            // scheduleHours:
            //     typeof dataObject.scheduleHours === 'string'
            //         ? (dataObject.scheduleHours as string).split(',')
            //         : dataObject.scheduleHours,
            colorConfig: dataObject.colorConfig
                ? Array.isArray(dataObject.colorConfig)
                    ? dataObject.colorConfig
                    : JSON.parse(dataObject.colorConfig)
                : [],
            linkedInvestigationScenarioId: linkedInvestigationScenarioId
                ? {
                      ...linkedInvestigationScenarioId,
                      label: `${linkedInvestigationScenarioId.title} (${linkedInvestigationScenarioId.id})`
                  }
                : null,
            linkedFixScenarioId: linkedFixScenarioId
                ? {
                      ...linkedFixScenarioId,
                      label: `${linkedFixScenarioId.title} (${linkedFixScenarioId.id})`
                  }
                : null
        };
        return updatedDataObj;
    };

    useEffect(() => {
        if (dataObject) {
            const updatedDataObj: TQueryData = getUpdatedObject(dataObject);
            setFormData(updatedDataObj);
            updatedDataObj.reportType==CHECK_TYPE.LIST && setFormDataQuery(updatedDataObj.queries[0])
        }
        if (pasteData?.name) {
            setFormData({ ...pasteData });
            pasteData.reportType==CHECK_TYPE.LIST && setFormDataQuery(pasteData.queries[0])
        }
        setDataLoaded(true);
    }, [dataObject]);

    const onChange = (key: keyof TQueryData, value: string | TColorConfig[] | number) => {
        const updatedObject: TQueryData = {
            ...formData,
            [key]: key === 'cacheDuration' && typeof value === 'string' ? parseInt(value) : value
        };
        setFormData(updatedObject);
    };

    const onChangeQuery = (key: keyof TQueryDetail, value: any, index: number) => {
        const updatedQueryObj = {...formDataQuery,[key]:value};
        if(key =='startPeriod' || key == 'endPeriod')
        {
            updatedQueryObj.startPeriod = parseInt(updatedQueryObj.startPeriod)
            updatedQueryObj.endPeriod = parseInt(updatedQueryObj.endPeriod)
        }
        if (key == 'fetchQuery' || key == 'type') {
            updatedQueryObj.fetchVariables =
            updatedQueryObj.type === DATASOURCE_TYPE.API ||
            updatedQueryObj.type === EXTRA_DS.EXTERNAL_URL
                    ? getUrlVariables(updatedQueryObj.fetchQuery)
                    : getVariables(updatedQueryObj.fetchQuery);
        }
        if (key == 'updateQuery' || key == 'type') {
            updatedQueryObj.updateVariables =
            updatedQueryObj.type === DATASOURCE_TYPE.API ||
            updatedQueryObj.type === EXTRA_DS.EXTERNAL_URL
                    ? getUrlVariables(updatedQueryObj.updateQuery)
                    : getVariables(updatedQueryObj.updateQuery);
        }
        setFormDataQuery(updatedQueryObj);
        if(formData.reportType==CHECK_TYPE.LIST)
        {
            let obj=updatedQueryObj
            setFormData({ ...formData, queries: [obj] });
        }
       
    };

    const isValidJson=(json:any)=> {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }

    const validateQuery=()=>{
        const formDataTemp = _.cloneDeep(formDataQuery);
        const errorObjQueryTemp: any = _.cloneDeep(errorObj);
        let isValid: boolean = true;
        if(!_.isEmpty(formDataQuery['payload']))
        {
           isValid =  isValidJson(formDataQuery['payload'])
           if(isValid)
           {
            let parsedData= JSON.parse(formDataQuery['payload']);
            formDataQuery['payload'] = JSON.stringify(parsedData);
           }else{
            errorObjQueryTemp['payload'] = 'Invalid JSON' ;
           }
        }
            for (let queryKey in formDataTemp) {
                if (MANDATORY_QUERY_FIELDS.includes(queryKey) && _.isEmpty(formDataTemp[queryKey])) {
                    errorObjQueryTemp[queryKey] = 'Invalid ' + queryKey;
                    isValid = false;
                }
                else if(type=='FIX' && _.isEmpty(formDataTemp['updateQuery']))
                {
                    errorObjQueryTemp['updateQuery'] = 'Invalid updateQuery' ;
                    isValid = false;
                }
                else if(formDataTemp['isDynamic'] && MANDATORY_DYNAMIC_FIELDS.includes(queryKey) && _.isEmpty(formDataTemp[queryKey].toString()))
                {
                    errorObjQueryTemp[queryKey] = 'Invalid ' + queryKey;
                    isValid = false;
                }
                else if((formDataTemp['type']==DATASOURCE_TYPE.CLOUD_WATCH_METRICS ||formDataTemp['type']==DATASOURCE_TYPE.CLOUD_WATCH_QUERY_INSIGHTS) && _.isEmpty(formDataTemp['payload']) )
                {
                    errorObjQueryTemp['payload'] = 'Invalid payload';
                    isValid = false;
                }
            }
        
         setErrorObjQuery(errorObjQueryTemp);
        return isValid;
    }


    const validate = () => {
        let isValid: boolean = true;
        let formDataTemp: any;
        let word= (/summaryValue(\W|_)/g);
        // exact table
        let tableWord1 = (/:table$/g);
        // table followed by spl characters
        let tableWord2 = (/:table(\W|_)/g);
        if(formData.reportType==CHECK_TYPE.LIST)
        {
             formDataTemp = _.cloneDeep({ ...formData, queries: [formDataQuery] });
        }
        else
        formDataTemp = _.cloneDeep(formData);
        const errorObjTemp: any = _.cloneDeep(errorObj);
        const errorObjQueryTemp: any = _.cloneDeep(errorObj);
        for (let key in formDataTemp) {
        
            const columnNameCondition =
                type === SCENARIO_TYPES.EXCEPTION &&
                key === 'columnName' &&
                formDataTemp.operationType !== SUMMARY_OPERATION_TYPES.COUNT &&
                _.isEmpty(formDataTemp[key]);
            if (
                (key === 'cacheDuration' && isNaN(formDataTemp[key])) ||
                ((type === SCENARIO_TYPES.EXCEPTION
                    ? MANDATORY_CHECK_FIELDS.includes(key)
                    : MANDATORY_CHECK_FIELDS.includes(key)) &&
                    _.isEmpty(formDataTemp[key])) ||
                (key === 'datasourceId' &&
                    formDataTemp.type !== EXTRA_DS.EXTERNAL_URL &&
                    _.isEmpty(formDataTemp[key]))
            ) {
                errorObjTemp[key] = 'Invalid ' + key;
                isValid = false;
            } else if (!formDataTemp['summarySQL'].match(word) ||
            (formData.reportType==CHECK_TYPE.LIST && formDataQuery.responseType!=RESPONSE_TYPE.JSON_OBJECT && (!formDataTemp['summarySQL'].match(tableWord1) 
            && !formDataTemp['summarySQL'].match(tableWord2))) 
            ) {
                errorObjTemp['summarySQL'] = 'Invalid summarySQL';
                isValid = false;
            } else {
                errorObjTemp[key] = '';
            }
        }
        
        setErrorObj(errorObjTemp);
        setErrorObjQuery(errorObjQueryTemp);
        return isValid;
    };
    
    const onAddOrSave = () => {
        let validation:boolean=false;
        if(formData.reportType==CHECK_TYPE.LIST)
        {
            setFormData({ ...formData, queries: [formDataQuery] });
            let test = validate();
            let test_query= validateQuery();
            validation=test && test_query; 
        }
        else if(formData.reportType==CHECK_TYPE.KEY_VALUE && formData.queries.length==0)
        {
            let test = validate();
            let test_query= validateQuery();
            validation=test && test_query; 
        }
        else{
            validation = validate()
        }
        if (validation) {
            onSubmit(formData, index);
            setFormData(INTIAL_DATA_OBJ);
        }
    };

    const addQuery = () => {
        let newData=formDataQuery;
        if(validateQuery())
        { 
            querySummaryRef.current?.scrollIntoView({ behaviour: 'smooth' })
        setFormDataQuery(INITIAL_QUERY_OBJ[0]);
        if(!edit)
        { 
        if(formData.queries.length<1)
        {
            setFormData({ ...formData, queries: [newData] });
        }
        else{
            formData.queries[formData.queries.length] = newData;
            setFormData({ ...formData, queries: formData.queries });
        }
         }
        else{
            formData.queries[editIndex] = newData;
            setFormData({ ...formData, queries: formData.queries });
            setEdit(false);
            setEditIndex(-1);
        }}
    };


    const onEdit = (index: number) => {
        setEdit(true);
        setEditIndex(index);
        setFormDataQuery(formData.queries[index]);
        queryContainerRef.current?.scrollIntoView({ behaviour: 'smooth' })
    };

    const onDelete = (index: number) => {
        formData.queries.splice(index, 1);
        setFormData({ ...formData, queries: formData.queries });
    };

    return dataLoaded ? (
        <React.Fragment>
            <div className="data-create-update">
                <InputField
                    label="Check name"
                    value={formData.name}
                    onChangeHandler={(e) => onChange('name', e.target.value)}
                    type={'text'}
                    fieldType={FIELD_TYPES.TYPE_1}
                    error={errorObj.name}
                    required={true}
                />
                <Select
                    label="Report type"
                    value={formData.reportType}
                    onChangeHandler={(e: any, value: any) => onChange('reportType', value)}
                    fieldType={SELECT_FIELD_TYPES.TYPE_1}
                    options={type!=SCENARIO_TYPES.EXCEPTION?[...Object.keys(CHECK_TYPE),...Object.keys(EXTRA_DS)]:[...Object.keys(CHECK_TYPE)]}
                    error={errorObj.type}
                    required={true}
                />

                {type == SCENARIO_TYPES.EXCEPTION ? (
                    <React.Fragment>
                        <TextField
                            label="Summary Template"
                            value={formData.summaryTemplate}
                            onChangeHandler={(e) => onChange('summaryTemplate', e.target.value)}
                        />
                        <div className="card-field">
                            <TextField
                                label={
                                    <InfoCard
                                        label={'Summary SQL'}
                                        message={'Info : SummaryValue - Dashboard summary variable'}
                                        icon={<TooltipComponent title={SUMMARY_SQL} icon={<BiInfoCircle size={12} className='margin-alignment' color={'#6b6a68'}/>}/>}
                                        type={CARDTYPES.INFO}
                                        required={true}
                                    />
                                }
                                value={formData.summarySQL}
                                onChangeHandler={(e) => onChange('summarySQL', e.target.value)}
                                // required={true}
                                error={errorObj.summarySQL}
                            />
                        </div>
                        <Switch
                            value={formData.dataInSummary}
                            onChangeHandler={(e: any) => {
                                onChange('dataInSummary', e.target.checked);
                            }}
                            label="Attach data in summary report"
                        />
                    </React.Fragment>
                ) : null}
                <InputField
                    label={<div className='row-display'>Validation Criteria <div className='req'>*</div><TooltipComponent title={VALIDATION_CRITERIA} icon={<BiInfoCircle size={12} className='margin-alignment' color={'#6b6a68'}/>}/></div>}
                    value={formData.validationCriteria}
                    onChangeHandler={(e) => onChange('validationCriteria', e.target.value)}
                    type={'text'}
                    fieldType={FIELD_TYPES.TYPE_1}
                    error={errorObj.validationCriteria}
                    // required={true}
                />
                <TextField
                    label="Notes"
                    value={formData.notes ?? ''}
                    onChangeHandler={(e) => onChange('notes', e.target.value)}
                />
                {type == SCENARIO_TYPES.EXCEPTION ? (
                    <Select
                        label="Investigation Scenario Id"
                        value={formData.linkedInvestigationScenarioId}
                        onChangeHandler={(e: any, value: any) =>
                            onChange('linkedInvestigationScenarioId', value)
                        }
                        classList={['id-li-item']}
                        options={investigationNameIdList}
                        fieldType={SELECT_FIELD_TYPES.TYPE_1}
                    />
                ) : null}
                {type == SCENARIO_TYPES.EXCEPTION || type == SCENARIO_TYPES.INVESTIGATION ? (
                    <Select
                        label="Fix Scenario Id"
                        value={formData.linkedFixScenarioId}
                        onChangeHandler={(e: any, value: any) =>
                            onChange('linkedFixScenarioId', value)
                        }
                        options={fixNameIdList}
                        classList={['id-li-item']}
                        fieldType={SELECT_FIELD_TYPES.TYPE_1}
                    />
                ) : null}
                <ConfigureColorScheme
                    colorConfigList={formData.colorConfig}
                    setColorConfigList={(colorConfigList: TColorConfig[]) =>
                        onChange('colorConfig', colorConfigList)
                    }
                />
                {/* Queries field */}
                <div className='quer-form-list-container' ref={queryContainerRef}>
                    <div>
                    <AddQuery index= {formData.queries.length} errorObjQuery={errorObjQuery} onChangeQuery={onChangeQuery} query={formDataQuery} type={type} />
                    {
                        formData.reportType!=CHECK_TYPE.LIST &&
                        <Button
                            buttonText={'Save Query'}
                            onClick={() => {
                                addQuery();
                            }}
                            classList={['add-query-btn']}
                            type={TYPES.PRIMARY_OUTLINED}
                        />
                    }
                    </div>
                    { !_.isEmpty(formData.queries) && formData.reportType!=CHECK_TYPE.LIST &&   <div className="query-sub-container" ref={querySummaryRef}>
                       { 
                        formData.queries?.length>0 && formData.queries?.map((query: any, index: number) => {
                            return (
                               <QueryList query={query} onEdit={onEdit} onDelete={onDelete} index ={index}type={type} edit={edit} />
                            );
                        })}
                        </div>
                    }
                </div>
            </div>

            <div className="button-container">
                <Button
                    buttonText={'Next'}
                    onClick={() => {}}
                    classList={['add-update-btn']}
                    type={TYPES.PRIMARY_OUTLINED}
                    disabled={true}
                />

                <Button
                    buttonText={'Save Extraction Method'}
                    onClick={onAddOrSave}
                    classList={['add-update-btn']}
                    type={TYPES.PRIMARY}
                    disabled={formData.queries.length==0 && formData.reportType!=CHECK_TYPE.LIST}
                />
                <Button
                    buttonText={'Back'}
                    onClick={() => onBack(1)}
                    classList={['add-update-btn']}
                    type={TYPES.PRIMARY_OUTLINED}
                />
            </div>
        </React.Fragment>
    ) : (
        <div>Loading...</div>
    );
};

export default CreateOrUpdate;
