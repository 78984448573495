import {
    fetchDatasource,
    fetchScenario,
    createDatasource,
    deleteDatasource,
    createScenario,
    deleteScenario,
    fetchScenarioById,
    executeScenario,
    fetchAuditTrails,
    updateScenario,
    executeReadQuery,
    executeWriteQuery,
    fetchConsoleDashboardData,
    fetchSnapshotData,
    fetchDashboardList,
    fetchDBSnapshotList,
    fetchDashboardSnapshot,
    deleteDashboard,
    getQueryDashboardSnapshotData,
    createDistribution,
    updateDistribution,
    fetchDistribution,
    deleteDistribution
} from 'apis';
import { DATASOURCE_TYPE, SCENARIO_TYPES, SNAPSHOT_DB_LATEST } from 'utils/constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TDatasource, TDistribution, TQueryData, TQueryDetail, TQueryRequest, TScenarioData } from 'utils/model';
import { getMapFromArray, getLastEnclosedIdValue, csvToJson, convertJsonObjectToKVArray } from 'utils/helperFunctions';
import _ from 'lodash';
import { hideLoader, showLoader } from 'store/reducers/LoaderSlice';
import { setError, resetError } from 'store/reducers/ErrorSlice';
import { authWrapper } from './authentication';
import { resetScenarioDetailsLoader } from 'store/reducers/scenarioDetailsSlice';

let loaderCount = 0;
export const getScenarios: any = createAsyncThunk(
    'scenarios/getScenariosList',
    async (scenarioType: SCENARIO_TYPES, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const response: any = await fetchScenario(scenarioType);

            if (response?.data?.success === false) {
                throw response.data.message;
            }
            return {
                scenarioType,
                data: response.data?.data ?? []
            };
        } catch (e: any) {
            dispatch(setError(e.message ?? JSON.stringify(e)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const getScenariosWrapper: any = createAsyncThunk(
    'scenarios/getScenariosWrapper',
    async (payload: any) => {
        authWrapper({ apiCallback: getScenarios, payload });
    }
);

export const getScenarioDetailsById: any = createAsyncThunk(
    'scenarioDetails/getScenarioDetailsById',
    async (scenarioId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const response: any = await fetchScenarioById(scenarioId);
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            const data: any = response.data?.data ?? null;
            // to check this format after api
            if (data) {
                data.checks = data.checks.map((checkItem: any) => {
                    return {
                        ...checkItem,
                        currentData: [],
                        previousData: [],
                        executedQuery: '',
                        queries: checkItem.queries?.map((queryItem: any)=>{
                            return {
                                ...queryItem,
                                fetchVariableData: !_.isEmpty(queryItem.fetchVariables)
                                    ? getMapFromArray(queryItem.fetchVariables.split(','))
                                    : {},
                                update: {
                                    updateVariableData: !_.isEmpty(queryItem.updateVariables)
                                        ? getMapFromArray(queryItem.updateVariables.split(','))
                                        : {},
                                    ...(queryItem.type === DATASOURCE_TYPE.API &&
                                        data.scenarioType === SCENARIO_TYPES.FIX && { request: '' })
                                }
                            }
                        })
                    };
                });
            }
            return {
                data
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
            return rejectWithValue({ id: scenarioId });
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);
export const getScenarioDetailsByIdWrapper: any = createAsyncThunk(
    'scenarioDetails/getScenarioDetailsByIdWrapper',
    async (payload: any) => {
        await authWrapper({ apiCallback: getScenarioDetailsById, payload });
    }
);

export const addScenario: any = createAsyncThunk(
    'scenarios/addScenario',
    async (scenarioData: TScenarioData, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const request = getScenarioRequest(scenarioData);
            const response: any = await createScenario(request);
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            return {
                scenarioType: scenarioData.scenarioType,
                data: response.data?.data ?? []
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const removeScenario: any = createAsyncThunk(
    'scenarios/removeScenario',
    async (scenario: any, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const { id, scenarioType } = scenario;
            const response: any = await deleteScenario(id);
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            return {
                id,
                scenarioType
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

const getScenarioRequest = (scenarioData: TScenarioData) => {
    return {
      
        userId: 1000, //TODO: Replace with actual user Id
        executionFrequencyInMinute: 5, //TODO: Replace with actual executionFrequencyInMinute
        title: scenarioData.title,
        description: scenarioData.description,
        scenarioType: scenarioData.scenarioType,
        schedule: false,
        checks: scenarioData.checks.map((checkItem: TQueryData) => {    
            return {
                name: checkItem.name,
                reportType: checkItem.reportType,
                notes: checkItem.notes,
                validationCriteria:checkItem.validationCriteria,
                // operationType: checkItem.operationType,
                // columnName: checkItem.columnName,
                // linkedInvestigationScenarioId: checkItem.linkedInvestigationScenarioId?checkItem.linkedInvestigationScenarioId.id:null,
                // linkedFixScenarioId: checkItem.linkedFixScenarioId?checkItem.linkedFixScenarioId.id:null,
                linkedInvestigationScenarioId: getLastEnclosedIdValue(
                    scenarioData.linkedInvestigationScenarioId
                ),
                linkedFixScenarioId: getLastEnclosedIdValue(scenarioData.linkedFixScenarioId),
                summarySQL: checkItem.summarySQL,
                summaryTemplate: checkItem.summaryTemplate,
                dataInSummary: checkItem.dataInSummary,
                colorConfig:
                    typeof checkItem.colorConfig === 'string'
                        ? checkItem.colorConfig
                        : Array.isArray(checkItem.colorConfig)
                        ? JSON.stringify(checkItem.colorConfig)
                        : JSON.stringify([]),

                queries: checkItem.queries.map((queryItem: TQueryDetail) => {
                    return {
                        name: queryItem.name,
                        type: queryItem.type,
                        fetchQuery: queryItem.fetchQuery.replace(/\s+/g, ' ').trim(),
                        updateQuery: queryItem.updateQuery?.replace(/\s+/g, ' ').trim(),
                        fetchVariables: Array.isArray(queryItem.fetchVariables)
                            ? queryItem.fetchVariables.join(',')
                            : queryItem.fetchVariables,
                        updateVariables: Array.isArray(queryItem.updateVariables)
                            ? queryItem.updateVariables.join(',')
                            : queryItem.updateVariables,
                        methodType:queryItem.methodType,
                        responsePath:queryItem.responsePath,
                        querySummarySQL:queryItem.querySummarySQL,
                        responseType:queryItem.responseType,
                        payload:queryItem.payload,
                        isDynamic:queryItem.isDynamic,
                        startPeriod:queryItem.startPeriod,
                        endPeriod:queryItem.endPeriod,
                        startPeriodUnit:queryItem.startPeriodUnit??'DAYS',
                        endPeriodUnit:queryItem.endPeriodUnit??'DAYS',
                        periodStartKey:queryItem.periodStartKey,
                        periodEndKey:queryItem.periodEndKey,
                        timeFormat:queryItem.timeFormat,
                        startTime:queryItem.startTime,
                        startTimeKey:queryItem.startTimeKey,
                        startTimeFormat:queryItem.startTimeFormat
                    }
                })
                        
            };
        })
    };
};

export const getDatasources: any = createAsyncThunk(
    'datasources/getDatasourceList',
    async (payload, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const response = await fetchDatasource();
            return {
                data: response.data?.data ?? []
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const getDatasourcesWrapper: any = createAsyncThunk(
    'datasources/getDatasourcesWrapper',
    async (payload: any) => {
        authWrapper({ apiCallback: getDatasources, payload });
    }
);

export const addDatasource: any = createAsyncThunk(
    'datasources/create',
    async (createData: TDatasource, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const request = {
                ...createData,
                credentials: JSON.stringify(createData.credentials),
                userId: 1000
            }; //TODO: Replace user id with actual user id
            const response = await createDatasource(request);
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            return {
                data: response.data?.data ? { ...request, id: response.data.data } : null
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const removeDatasource: any = createAsyncThunk(
    'datasources/delete',
    async (id: number, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const response = await deleteDatasource(id);
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            return {
                id: response.data.data
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

const getProcessedQueryDSMap = (checkItem: TQueryData, queryDSMapList: TQueryRequest[]) => {
    return checkItem.queries.map((queryItem: any) => {
        const dsMap = _.find(queryDSMapList, { queryId: queryItem.id });
        return {
            ...dsMap,
            fetchQueryParams: queryItem.fetchVariableData,
            updateQueryParams: queryItem.update?.updateVariableData,
            updateRequest: queryItem.update?.request
        }
    })
}

export const getReadQueryExecutionData: any = createAsyncThunk(
    'scenarioDetails/executeReadQuery', async (payload: any, { getState, dispatch }) => {
        const { checkId, scenarioId, isDashboardEnabled, proxyId, selectedsnapshot, queryRequestList,dashboardId, disableCache } = payload;
        const state: any = getState();
        const scenario = state.scenarioDetails[scenarioId];
        const checkItem = _.find(scenario.checks, { id: checkId });

        if (isDashboardEnabled && selectedsnapshot.executedAt!== SNAPSHOT_DB_LATEST) {
            const request = {
                checkId: checkId,
                dashboardId: dashboardId,
                scheduleHour: selectedsnapshot.scheduleHour
            }
            try{
                const response = await getQueryDashboardSnapshotData(request);
                const responseData = await getProccessedExecutionResponseData(response, checkItem);
                return {
                    data: responseData,
                    scenarioId,
                    checkId,
                    message: response.data.success === false? response.data.message:''
                };
            }catch(e){
                return {
                    data: [],
                    scenarioId,
                    checkId,
                    message: JSON.stringify(e)
                };
            }
            
        }
        else {
            const processedQueryRequestList: any = getProcessedQueryDSMap(checkItem, queryRequestList);
            const requestBody = getReadQueryExecuteRequest(checkItem, scenario.scenarioType, dashboardId, proxyId, processedQueryRequestList, disableCache);
            try {
                const response = await executeReadQuery(requestBody);
                const responseData = await getProccessedExecutionResponseData(response, checkItem);
                return {
                    data: responseData,
                    scenarioId,
                    checkId,
                    message: response.data.success === false ? response.data.message : ''
                };
            } catch (e) {
                return {
                    data: [],
                    scenarioId,
                    checkId,
                    message: JSON.stringify(e)
                };
            }
        }
        

    });
export const getSnapshotData:any = createAsyncThunk(
    'scenarioDetails/getSnapshotData',
    async (payload:any, {getState})=>{
        const {snapshotId, scenarioId, checkId} = payload;
        const state: any = getState();
        const scenario = state.scenarioDetails[scenarioId];
        const checkItem = _.find(scenario.checks, { id: checkId });
        const response = await fetchSnapshotData(snapshotId);
        const responseData = await getProccessedExecutionResponseData(response, checkItem);
        return {
            data: responseData,
            scenarioId,
            checkId
        };
    }
);

const getProccessedExecutionResponseData = async (response: any, checkItem: TQueryData)=>{
    if (response?.data?.success === false) {
        throw response.data.message;    
    }
    const responseData:any = response.data?.data ?? [];
    responseData.currentData = responseData.currentData??[];
    if(typeof responseData?.currentData === "string"){
        if(_.isEmpty(responseData.currentData))
        {
            responseData.currentData='';
        }
        else
        responseData.currentData = await getJsonData(responseData.currentData, checkItem.reportType);
    }
    return responseData;
}

const getJsonData = async (str: string, reportType: String) => {
    const responseData = JSON.parse(str);
    if(reportType === "KEY_VALUE" || str.startsWith("{")){
        return convertJsonObjectToKVArray(responseData)
    }
    if (responseData.dataType === 'csv') {
        const processedData = await csvToJson(responseData.data);
        return processedData;
    }
    if (responseData.dataType == 'jsonml') {
        return responseData;
    }

    const processedResponseData = Array.isArray(responseData)
        ? responseData
        : Array.isArray(responseData.data)
        ? responseData.data
        : [responseData.data];
    return processedResponseData;
};


const getReadQueryExecuteRequest = (checkItem: any, scenarioType: any,dashboardId: number, proxyId: number, queryRequestList: TQueryRequest[], disableCache: boolean) => {
    return {
        checkId: checkItem.id,
        isCacheAllowed: disableCache === true? false : SCENARIO_TYPES.EXCEPTION === scenarioType,
        dashboardId,
        proxyId,
        queryRequestList 
    };
};

export const getWriteQueryExecutionData: any = createAsyncThunk(
    'scenarioDetails/executeWriteQuery',
    async (payload: any, { getState, dispatch }) => {
        const { checkId, scenarioId, proxyId, queryRequestList ,dashboardId } = payload;
        const state: any = getState();
        const checkItem = _.find(state.scenarioDetails[scenarioId].checks, { id: checkId });
        const processedQueryRequestList:any = getProcessedQueryDSMap(checkItem,queryRequestList);
        const requestBody = getWriteQueryExecuteRequest(checkItem, proxyId, processedQueryRequestList,dashboardId);
        const response = await executeWriteQuery(requestBody);
        if (response?.data?.success === false) {
            throw response.data.message;
        }
        const responseData: any = response.data?.data ?? [];
        responseData.currentData = responseData.currentData ?? [];
        if (typeof responseData.currentData === 'string') {
            responseData.currentData = await getJsonData(responseData.currentData, checkItem.reportType);
        }
        if (typeof responseData.previousData === 'string') {
            responseData.previousData = await getJsonData(responseData.previousData, checkItem.reportType);
        }
        return {
            data: responseData,
            scenarioId,
            checkId
        };
    }
);

const getWriteQueryExecuteRequest = (checkItem: any, proxyId: number, queryRequestList: TQueryRequest[] ,dashboardId: number) => {
    return {
        checkId: checkItem.id,
        dashboardId,
        queryRequestList,
        proxyId
    };
};

export const getAuditTrails: any = createAsyncThunk(
    'auditTrails/getAuditTrails',
    async (payload, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const response: any = await fetchAuditTrails();
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            return {
                data: response.data?.data ?? []
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const getAuditTrailsWrapper: any = createAsyncThunk(
    'scenarios/getAuditTrailsWrapper',
    async (payload: any) => {
        authWrapper({ apiCallback: getAuditTrails, payload });
    }
);

export const saveScenarioUpdates: any = createAsyncThunk(
    'scenarios/saveScenarioUpdates',
    async (scenarioDetails: any, { getState, dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const { scenarioId, request } = scenarioDetails;
            const linkedScenarioDetails: any = {};
            const state: any = getState();

            if (request.linkedFixScenarioId) {
                request.linkedFixScenarioId = getLastEnclosedIdValue(request.linkedFixScenarioId);
            }
            if (request.linkedInvestigationScenarioId) {
                request.linkedInvestigationScenarioId = getLastEnclosedIdValue(
                    request.linkedInvestigationScenarioId
                );
            }
            if (request.checks) {
                request.checks = request.checks.map((checkItem: any) => {
                    return {
                        ...checkItem,
                        linkedInvestigationScenarioId: checkItem.linkedInvestigationScenarioId ? checkItem.linkedInvestigationScenarioId.id?? checkItem.linkedInvestigationScenarioId: null,
                        linkedFixScenarioId: checkItem.linkedFixScenarioId ? checkItem.linkedFixScenarioId.id??checkItem.linkedFixScenarioId : null,
                        validationCriteria:checkItem.validationCriteria,
                        colorConfig:
                            typeof checkItem.colorConfig === 'string'
                                ? checkItem.colorConfig
                                : Array.isArray(checkItem.colorConfig)
                                ? JSON.stringify(checkItem.colorConfig)
                                : JSON.stringify([]),
                        
                           queries: checkItem.queries.map((queryItem: any) => {
                            return {
                                name: queryItem.name,
                                id: queryItem.id,
                                checkId: queryItem.checkId,
                                type: queryItem.type,
                                fetchQuery: queryItem.fetchQuery.replace(/\s+/g, ' ').trim(),
                                updateQuery: queryItem.updateQuery?.replace(/\s+/g, ' ').trim(),
                                fetchVariables: Array.isArray(queryItem.fetchVariables)
                                    ? queryItem.fetchVariables.join(',')
                                    : queryItem.fetchVariables,
                                updateVariables: Array.isArray(queryItem.updateVariables)
                                    ? queryItem.updateVariables.join(',')
                                    : queryItem.updateVariables,
                                methodType:queryItem.methodType,
                                responsePath:queryItem.responsePath,
                                querySummarySQL:queryItem.querySummarySQL,
                                responseType:queryItem.responseType,
                                payload:queryItem.payload,
                                isDynamic:queryItem.isDynamic,
                                startPeriod:queryItem.startPeriod,
                                endPeriod:queryItem.endPeriod,
                                startPeriodUnit:queryItem.startPeriodUnit??'DAYS',
                                endPeriodUnit:queryItem.endPeriodUnit??'DAYS',
                                periodStartKey:queryItem.periodStartKey,
                                periodEndKey:queryItem.periodEndKey,
                                timeFormat:queryItem.timeFormat,
                                startTime:queryItem.startTime,
                                startTimeKey:queryItem.startTimeKey,
                                startTimeFormat:queryItem.startTimeFormat
                            }
                        })
                           
                    };
                });
            }
            const response = await updateScenario(scenarioId, request);
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            if (request.linkedFixScenarioId) {
                linkedScenarioDetails.linkedFixScenario = _.find(
                    state.scenarios[SCENARIO_TYPES.FIX],
                    { id: request.linkedFixScenarioId }
                );
            }
            if (request.linkedInvestigationScenarioId) {
                linkedScenarioDetails.linkedInvestigationScenario = _.find(
                    state.scenarios[SCENARIO_TYPES.INVESTIGATION],
                    { id: request.linkedInvestigationScenarioId }
                );
            }
            return {
                data: response.data?.data ?? null,
                scenarioId,
                ...linkedScenarioDetails,
                checks: request.checks
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const getDashboardList: any = createAsyncThunk('consoleDashboard/getDashboardList',async (payload, { dispatch })=>{
    try{
        dispatch(resetError());
        dispatch(showLoader());
        loaderCount++;
        const response = await fetchDashboardList();
        if (response?.data?.success === false) {
            throw response.data.message;
        }
        return {
            dashboardList: response.data?.data ?? []
        };
    }catch (error: any) {
        console.log(error);
        dispatch(setError(error.message ?? JSON.stringify(error)));
    } finally {
        loaderCount--;
        loaderCount == 0 && dispatch(hideLoader());
    }
});

export const getConsoleDashboard: any = createAsyncThunk(
    'consoleDashboard/getConsoleDashboard',
    async (payload:any, { dispatch , getState}) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const { dashboardId,refresh } = payload;
            const state: any = getState();
            if(state.consoleDashboard.reloadRequired)
           { 
            const dbResponse: any = await fetchConsoleDashboardData(dashboardId,false);
            if (dbResponse?.data?.success === false) {
                throw dbResponse.data.message;
            }

            const snapshotResponse = await fetchDBSnapshotList(dashboardId);
            if (snapshotResponse?.data?.success === false) {
                throw snapshotResponse.data.message;
            }

            return {
                data: dbResponse.data?.data ?? [],
                snapshotList: snapshotResponse.data?.data??[],
                dashboardList: state.consoleDashboard.dashboardList,
                isUpdateRequired:true
            };}
            else
            {
                return {
                    data: state.consoleDashboard.data,
                    snapshotList: state.consoleDashboard.currentSnapshotList,
                    dashboardList: state.consoleDashboard.dashboardList,
                    isUpdateRequired:false
                };  
            }
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const getConsoleDashboardonRefresh: any = createAsyncThunk(
    'consoleDashboard/getConsoleDashboardonRefresh',
    async (payload:any, { dispatch , getState}) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const { dashboardId,refresh } = payload;
            const state: any = getState();
            const dbResponse: any = await fetchConsoleDashboardData(dashboardId,true);
            if (dbResponse?.data?.success === false) {
                throw dbResponse.data.message;
            }

            const snapshotResponse = await fetchDBSnapshotList(dashboardId);
            if (snapshotResponse?.data?.success === false) {
                throw snapshotResponse.data.message;
            }

            return {
                data: dbResponse.data?.data ?? [],
                snapshotList: snapshotResponse.data?.data??[],
                dashboardList: state.consoleDashboard.dashboardList,
                dashboardId
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const getConsoleDashboardSnapshot: any = createAsyncThunk(
    'consoleDashboard/getConsoleDashboardSnapshot',
    async (payload:any, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const { dashboardRunId } = payload;
            const dbResponse: any = await fetchDashboardSnapshot(dashboardRunId);
            if (dbResponse?.data?.success === false) {
                throw dbResponse.data.message;
            }
            return {
                data: dbResponse.data?.data ?? []
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const getConsoleDashboardDelete: any = createAsyncThunk(
    'consoleDashboard/getConsoleDashboardDelete',
    async (payload:any, { dispatch, getState }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const { dashboardId, refresh } = payload;
            const dbResponse: any = await deleteDashboard(dashboardId);
            if (dbResponse?.data?.success === false) {
                throw dbResponse.data.message;
            }
            const state: any = getState();
            const currentDBList = _.cloneDeep(state.consoleDashboard.dashboardList);
            _.remove(currentDBList, { id: dashboardId });
            const selectedDashboard = currentDBList.length ? currentDBList[0] : null;
            let newSnapshotList = [];
            let newData = [];
            if (selectedDashboard) {
                const snapshotResponse = await fetchDBSnapshotList(selectedDashboard.id);
                if (snapshotResponse?.data?.success === false) {
                    throw snapshotResponse.data.message;
                }
                newSnapshotList = snapshotResponse.data?.data ?? [];

                const dbResponse: any = await fetchConsoleDashboardData(selectedDashboard.id,refresh );
                if (dbResponse?.data?.success === false) {
                    throw dbResponse.data.message;
                }
                newData =  dbResponse.data?.data ?? [];
            }
            return {
                dashboardId,
                dashboardList: currentDBList,
                selectedDashboard,
                snapshotList: newSnapshotList,
                data: newData
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);


export const addDistribution: any = createAsyncThunk(
    'distribution/create',
    async (createData: TDistribution, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const request = {
                ...createData,
                channelDetails: JSON.stringify(createData.channelDetails),
                // userId: 1000
            }; //TODO: Replace user id with actual user id

            const response = await createDistribution(request);
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            return {
                data: response.data?.data ? { ...request,tenantId:response.data.data.tenantId, id: response.data.data.id, channelDetails: JSON.parse(request.channelDetails) } : null
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const updateDistributionChannel: any = createAsyncThunk(
    'distribution/update',
    async (updateData: TDistribution, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            let formattedData: any = _.cloneDeep(updateData);
            formattedData = _.omit(updateData, ['createdAt']);
            const request = {
                ...formattedData,
                channelDetails: JSON.stringify(updateData.channelDetails),
            }; 
            const response = await updateDistribution(request);
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            
            return {
                data: response.data?.data ? { ...request, createdAt:response.data.data?.createdAt,channelDetails: JSON.parse(request.channelDetails),id: response.data.data.id } : null
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const getDistribution: any = createAsyncThunk(
    'distribution/getDistributionList',
    async (payload, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const response = await fetchDistribution();
            return {
                data: response.data?.data ?? []
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);

export const getDistributionWrapper: any = createAsyncThunk(
    'distribution/getDistributionWrapper',
    async (payload: any) => {
        authWrapper({ apiCallback: getDistribution, payload });
    }
);

export const removeDistribution: any = createAsyncThunk(
    'distribution/delete',
    async (id: number, { dispatch }) => {
        try {
            dispatch(resetError());
            dispatch(showLoader());
            loaderCount++;
            const response = await deleteDistribution(id);
            if (response?.data?.success === false) {
                throw response.data.message;
            }
            return {
                id: response.data.data
            };
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        } finally {
            loaderCount--;
            loaderCount == 0 && dispatch(hideLoader());
        }
    }
);


const getWrappedFunction = (apiCallback: any) => {
    return createAsyncThunk('authWrapper', async (payload: any) => {
       await authWrapper({ apiCallback, payload });
    });
};
export const getConsoleDashboardWrapper: any = getWrappedFunction(getConsoleDashboard);
export const getDashboardListWrapper: any = getWrappedFunction(getDashboardList);
export const getConsoleDashboardSnapshotWrapper: any = getWrappedFunction(getConsoleDashboardSnapshot);
export const getConsoleDashboardDeleteWrapper:any = getWrappedFunction(getConsoleDashboardDelete);
