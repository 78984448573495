import { createSlice } from '@reduxjs/toolkit';
import { getScenarios, addScenario, removeScenario, saveScenarioUpdates } from 'store/actions';
import { SCENARIO_TYPES } from 'utils/constants';
import _ from 'lodash';

const initialState = {
    [SCENARIO_TYPES.EXCEPTION]: [],
    [SCENARIO_TYPES.INVESTIGATION]: [],
    [SCENARIO_TYPES.FIX]: []
};

const reducers = {};

const extraReducers: any = {
    [getScenarios.fulfilled]: (state: any, action: any) => {
        const { scenarioType, data } = action.payload;
        state[scenarioType] = data;
    },
    [addScenario.fulfilled]: (state: any, action: any) => {
        const { data, scenarioType } = action.payload;
        state[scenarioType] = [...state[scenarioType], data];
    },
    [removeScenario.fulfilled]: (state: any, action: any) => {
        const { id, scenarioType } = action.payload;
        if (id) {
            _.remove(state[scenarioType], { id });
        }
    },
    [saveScenarioUpdates.fulfilled]: (state: any, action: any) => {
        const { data, scenarioId } = action.payload;
        if (data) {
            const editedIndex = _.findIndex(state[data.scenarioType], { id: scenarioId });
            state[data.scenarioType][editedIndex] = data;
        }
    }
};
export const scenarioSummarySlice = createSlice({
    name: 'scenarios',
    initialState,
    reducers,
    extraReducers
});

export const {} = scenarioSummarySlice.actions;
export default scenarioSummarySlice.reducer;
