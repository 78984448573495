import InputField, { FIELD_TYPES } from 'components/common/InputField';
import Modal from 'components/common/Modal';
import { useState } from 'react';

type TProps = {
    show: boolean;
    onClose: Function;
    onExecute: Function;
};

const ConfirmExecution = (props: TProps) => {
    const { show, onClose, onExecute } = props;
    const [answer, setAnswer] = useState('');
    const [submit, setSubmit] = useState(false);

    return (
        <Modal
            title="Execute Fix"
            show={show}
            onClose={onClose}
            onSubmit={() => {
                setSubmit(true);
                parseInt(answer) == 10 && onExecute();
            }}
            submitText={'Execute'}
            showButton={true}>
            <div className="execute-fix-confirm">
                <p>Are you sure you need to execute this fix?</p>
                <InputField
                    classList={['confirm-Input']}
                    label="5 + 5 = "
                    value={answer}
                    onChangeHandler={(e) => {
                        setAnswer(e.target.value);
                    }}
                    type={'text'}
                    fieldType={FIELD_TYPES.TYPE_2}
                    required={true}
                />
                {submit && parseInt(answer) != 10 ? <span>Invalid Value</span> : <div />}
            </div>
        </Modal>
    );
};

export default ConfirmExecution;
