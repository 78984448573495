import { MouseEventHandler, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Controller from './Controller';
import { TComponent } from './types';
import logo from '../../../assets/Roadrunnr.svg';

import DashboardSelection from './DashboardSelection';
import { getRole } from 'utils/helperFunctions';
import { TUserDetails } from 'utils/model';
import { useSelector } from 'react-redux';
import { BsPerson } from 'react-icons/bs';

type TProps = {
    components: TComponent[];
    basePath: string;
    onLogout?: MouseEventHandler<HTMLDivElement>;
};
const Navigator = (props: TProps) => {
    const userRole = getRole();
    const profilePic= sessionStorage.getItem('photoURL');
    const authDetails: TUserDetails = useSelector((state: any) => state.auth);
    
    
    return (
        <div className="navigator-container">
            <div className="top-header">
                <div>
                <img src={logo} className="logo-img" />
                {userRole == 'VIEWER' && (
                    <DashboardSelection/>
                )}
                </div>
               <div className='profile-details-div'> 
              { profilePic? <img src={profilePic} className='profile-pic'/> :<BsPerson className='profile-pic'/>}
                <div className='profile-details'><span>{authDetails.email}</span><span className='role'>{userRole?.toLowerCase()}</span></div>
               </div>
            </div>

            <Controller
                components={props.components}
                basePath={props.basePath}
                onLogout={props.onLogout}
            />
            <div className="component-container">
                <Outlet />
            </div>
        </div>
    );
};

export default Navigator;
