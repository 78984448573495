import ComponentWrapper from '../ComponentWrapper';
import DiffComponent from 'components/common/DiffComponent';
import SampleData from './SampleData.json'; //TODO: Replace with actual data;
import './style.scss';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const getAuditTrailsDetails = (state: any, id: any) => {
    const data = _.find(state.auditTrails.data, { id: parseInt(id) });
    return data;
}

const getProcessedData = (jsonData: any) => {
    if (_.isEmpty(jsonData)) return [];
    if (!_.isEmpty(jsonData.data)) {
        return Array.isArray(jsonData.data) ? jsonData.data : [jsonData.data]
    }
    return Array.isArray(jsonData) ? jsonData : ![jsonData];
}

const TrailDiff = () => {
    const { id } = useParams();
    const authTrailDetails = useSelector((state: any) => getAuditTrailsDetails(state, id))

    return (
        <ComponentWrapper title="Audit trail details">
            <div>
                <div className="trail-details-section">
                    {Object.entries(authTrailDetails).map(([key, value]: any) => {
                        if (["updatedData", "previousData"].includes(key)) {
                            return null
                        }
                        return (
                            <div className="item-row">
                                <div className="key">{_.startCase(key)}: </div>
                                <div className="value">{value}</div>
                            </div>
                        );
                    })}
                </div>
                {
                    (authTrailDetails) ?
                        <div className="trail-diff">
                            <DiffComponent
                                currentData={getProcessedData(authTrailDetails.updatedData)}
                                previousData={getProcessedData(authTrailDetails.previousData)}
                                idField="id"
                            />
                        </div> : <div>Invalid Trail Id</div>
                }
            </div>
        </ComponentWrapper>
    );
}

export default TrailDiff;
