import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './style.scss';

type TProps = {
    stage: Number;
    labelArray: any[];
};

const TabComponent = (props: TProps) => {
    const { stage, labelArray } = props;

    return (
        <Tabs
            className="tabs-style"
            TabIndicatorProps={{
                style: { background: 'none', color: 'black' }
            }}
            value={stage}
            textColor="inherit">
            {labelArray.map((item, key) => {
                return (
                    <Tab
                        className="tab-text-style"
                        value={key + 1}
                        label={
                            <div>
                                <span className={stage >= key + 1 ? 'active-tab' : 'inactive-tab'}>
                                    {key + 1}
                                </span>
                                <span className="label-span">{item}</span>
                               {key<2 && <span className="label-span">{'>'}</span>}
                            </div>
                        }
                        disabled={!(key + 1 <= stage)}
                    />
                );
            })}
        </Tabs>
    );
};

export default TabComponent;
