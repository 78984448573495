import Select, { FIELD_TYPES as SELECT_FIELD_TYPES } from 'components/common/Select';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { TDashboardData, TDBQueryData, TDBScenarioData, TQueryData, TQueryDetail, TScenarioData } from 'utils/model';
import { useSelector } from 'react-redux';
import Button, { TYPES } from 'components/common/Button';
import { List, ListItem, Menu } from '@mui/material';
import { MdClose } from 'react-icons/md';
import { DATASOURCE_TYPE, LOCAL_PROXY, SCENARIO_TYPES } from 'utils/constants';
import ButtonDropdown from 'components/common/ButtonDropdown';
import { fetchProxyDSList } from 'apis';
import { AiOutlineClose } from 'react-icons/ai';

type TProps = {
    formData: TDashboardData;
    onScenarioSave: Function;
    gotoPrevious: Function;
};

const ScenatioAndDs = (props: TProps) => {
    const { formData, onScenarioSave, gotoPrevious } = props;
    const [menuItems, setMenutems] = useState<any[]>([]);
    const [selectedMenuItems, setSelectedMenuItems] = useState<any[]>([]);
    const [scenarioDSMap, setScenarioDSMap] = useState<TDBScenarioData[]>([]);
    const [errorObj, setErrorObj] = useState<any>({});
    const [validDSList, setValiDSList] = useState<String[]>([]);
    const [currentProxy, setCurrentProxy] = useState<String>('');
    const [isinitialSetUpDone, setIsInitalsetupDone] = useState<Boolean>(false);
    const fixDetails = useSelector((state: any) => state.scenarios[SCENARIO_TYPES.FIX]);
    const investigationDetails = useSelector(
        (state: any) => state.scenarios[SCENARIO_TYPES.INVESTIGATION]
    );
    const exceptionDetails = useSelector((state: any) => state.scenarios[SCENARIO_TYPES.EXCEPTION]);
    const datasources = useSelector((state: any) => state.datasource.datasources);

    const updateDatasourceList = async () => {
        if (formData.proxyId === LOCAL_PROXY) {
            setValiDSList(datasources.map((dsItem: any) => dsItem.title));
        } else {
            try {
                const response = await fetchProxyDSList(formData.proxyId);
                setValiDSList(response.data ? response.data.map((dsItem: any)=>dsItem.name) :[])
            }
            catch (e) {
                setValiDSList([]);
            }
        }
    }

    const setInitialData = () => {
        if (formData.scenarios.length) {
            setScenarioDSMap(formData.scenarios);
            const tempSelectedmenu = [];
            for (const scenario of formData.scenarios) {
                const menuItem = _.find(menuItems, { id: scenario.id })
                tempSelectedmenu.push(menuItem)
            }
            setSelectedMenuItems(tempSelectedmenu);

        }
        setIsInitalsetupDone(true);

    }
    const resetCurrentDSinScenarioDSMap = () => {
        const updatedScenarioDSMap = scenarioDSMap.map((scenarioDSMapItem: TDBScenarioData): TDBScenarioData => {
            return {
                ...scenarioDSMapItem,
                queries: scenarioDSMapItem.queries.map((queryItem: TDBQueryData): TDBQueryData => {
                    return {
                        ...queryItem,
                        datasourceName: ''
                    }
                })
            }
        });
        setScenarioDSMap(updatedScenarioDSMap);

    }

    useEffect(() => {
        if (currentProxy !== formData.proxyId) {
            setCurrentProxy(formData.proxyId);
            updateDatasourceList();
            resetCurrentDSinScenarioDSMap();
        }
    }, [formData]);

    useEffect(() => {
        menuItems.length && setInitialData();
    }, [menuItems]);

    useEffect(() => {
        setMenutems([...exceptionDetails, ...investigationDetails, ...fixDetails]);
    }, [])

    const getScenarioItem = (scenario: any) => {
        const queries: TDBQueryData[] = scenario.checks.flatMap((checkItem: any): TDBQueryData => {
            return checkItem.queries.map((queryItem: any) => {
                return {
                    queryId: queryItem.id,
                    name: queryItem.name,
                    datasourceName: '',
                    type: queryItem.type
                }
            })
        })
        const scenarioItem: TDBScenarioData = {
            id: scenario.id,
            title: scenario.title,
            queries: queries
        }
        return scenarioItem;
    }

    const updateScenerioDSMap = () => {
        const updatedscenarioDSMapList: TDBScenarioData[] = [];
        for (const scenarioItem of selectedMenuItems) {
            const scenarioMapItem = _.find(scenarioDSMap, { id: scenarioItem.id });
            if (!scenarioMapItem) {
                updatedscenarioDSMapList.push(getScenarioItem(scenarioItem))
            } else {
                updatedscenarioDSMapList.push(scenarioMapItem);
            }
        }
        return updatedscenarioDSMapList;
    }

    const removeDeletedItemsScenerioDSMap = (scenarioDSMapList: TDBScenarioData[]) => {
        let updatedscenarioDSMapList: TDBScenarioData[] = [...scenarioDSMapList];
        for (const scenarioItem of scenarioDSMapList) {
            const selectedMenuItem = _.find(selectedMenuItems, { id: scenarioItem.id });
            if (!selectedMenuItem) {
             _.remove(updatedscenarioDSMapList, { id: scenarioItem.id })
            }
        }
        return updatedscenarioDSMapList;
    }

    useEffect(() => {
        if (isinitialSetUpDone) {
            let updatedscenarioDSMapList = updateScenerioDSMap();
            updatedscenarioDSMapList = removeDeletedItemsScenerioDSMap(updatedscenarioDSMapList);
            setScenarioDSMap(updateScenerioDSMap);
        }
    }, [selectedMenuItems]);

    const getLinkedScenarioList = (checks:TQueryData[]) => {
        
        const scenarioIdList:any[] = checks.flatMap((checkItem:TQueryData)=>[checkItem.linkedFixScenarioId, checkItem.linkedInvestigationScenarioId]);
        const filteredScenarioIdList = scenarioIdList.filter(id => id !== undefined && id != null && id != 0);
        const availableScenarios = [...investigationDetails, ...fixDetails];
        const scenarioList = filteredScenarioIdList.map((id)=>_.find(availableScenarios,{id})).filter(id =>id !== undefined && id != null);
        return scenarioList;
    }

    const handleScenarioSelection = (scenarioItem: TScenarioData) => {
        const selectedItems = [scenarioItem,...getLinkedScenarioList(scenarioItem.checks)];
        const updatedMenuSelectionList = _.uniqBy([...selectedMenuItems, ...selectedItems], function (item: TScenarioData) {
            return item.id;
        });
        setSelectedMenuItems(updatedMenuSelectionList);
    }

    const scenarioDsRemoveHandler = (scenarioDsItem: TDBScenarioData) => {
        const updatedMenuSelectionList = _.cloneDeep(selectedMenuItems);
        _.remove(updatedMenuSelectionList,{id: scenarioDsItem.id});
        setSelectedMenuItems(updatedMenuSelectionList);
    }

    const onDSChange = (value: string, scenarioIndex: number, queryIndex: number) => {
        let updatedDSMAP: TDBScenarioData[] = _.cloneDeep(scenarioDSMap);
        updatedDSMAP[scenarioIndex].queries[queryIndex].datasourceName = value;
        setScenarioDSMap(updatedDSMAP);
    }

    const validate = () => {
        const Obj: any = {};
        let isValid = true;
        for (const dsItemIndex in scenarioDSMap) {
            const dsItem = scenarioDSMap[dsItemIndex];
            for (const qIndex in dsItem.queries) {
                const qItem = dsItem.queries[qIndex];
                const validationRequired=  qItem.type==DATASOURCE_TYPE.API || qItem.type==DATASOURCE_TYPE.SQL || _.isEmpty(qItem.type);
                if (dsItem.queries[qIndex].datasourceName === '' && validationRequired) {
                    isValid = false;
                    if (Obj[dsItem.title]) {
                        Obj[dsItem.title][qItem.datasourceName] = 'Invalid datasource';
                    } else {
                        Obj[dsItem.title] = {};
                        Obj[dsItem.title][qItem.datasourceName] = 'Invalid datasource';
                    }
                }
            }
        }
        if (isValid) {
            onScenarioSave({ scenarios: scenarioDSMap });
        } else {
            setErrorObj(Obj);
        }
    }
    
    return (
        <div className='stage2-conatiner'>

            <div className='scenario-ds-container'>
                <div className='ds-config-section'>
                    {scenarioDSMap.map((item: TDBScenarioData, scenarioIndex: number) => <div className='map-ds-scenario' key={item.id}>
                        <div className='head-row'>
                            <div className='title'>{item.title}</div>
                            <div className='remove' title='remove' onClick={()=>scenarioDsRemoveHandler(item)}><AiOutlineClose/></div>
                        </div>
                        <div className='queries'>
                            {
                                item.queries.map((queryItem: TDBQueryData, queryIndex: number) => {
                                    
                                    let required= queryItem.type==DATASOURCE_TYPE.API || queryItem.type==DATASOURCE_TYPE.SQL || _.isEmpty(queryItem.type);
                                    return <div className='query-item' key={queryItem.queryId}>
                                        
                                        <div className='query-name'>{queryItem.name}<div className={`${required?'required':'not-required'}`}>{`*`}</div></div>
                                        <div className="connector">{`>`}</div>               
                                        <div className="ds-select">
                                            <Select
                                                value={queryItem.datasourceName}
                                                onChangeHandler={(e: any, value: any) => onDSChange(value, scenarioIndex, queryIndex)}
                                                options={validDSList}
                                                fieldType={SELECT_FIELD_TYPES.TYPE_1}
                                                error={required && errorObj[item.title] ? errorObj[item.title][queryItem.datasourceName] : ''}
                                                required={required}
                                                disabled={!required}
                                            />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>)}
                </div>
                <ButtonDropdown
                    buttonLabel={"+ Add Scenario"}
                    menuItems={menuItems}
                    selectedMenuItems={selectedMenuItems}
                    handleSelect={handleScenarioSelection}
                    label={"title"} />
            </div>
            <div className="button-container">
                <Button
                    buttonText={'Next'}
                    onClick={() => validate()}
                    classList={['add-update-btn']}
                    disabled={scenarioDSMap.length == 0}
                />
                <Button
                    buttonText={'Back'}
                    onClick={() => gotoPrevious(1)}
                    classList={['add-update-btn']}
                    type={TYPES.PRIMARY_OUTLINED}
                />
            </div>
        </div>
    );
};

export default ScenatioAndDs;