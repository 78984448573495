import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import './style.scss'

type TProps = {
    data: any[];
    changeView: Function;
};


const Toggle = (props: TProps) => {
    const { data, changeView } = props;
    const [index, setIndex] = useState(0);
    return (
        <div>
            <ToggleButtonGroup value={index}>
                {data.map((item: any, key: number) => {
                    return (
                        <ToggleButton 
                        className={`${key==index?'bg-set':''} toggle-button`}
                            value={key}
                            onClick={() => {
                                changeView(key);
                                setIndex(key);
                            }}>
                            {item}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </div>
    );
};

export default Toggle;
