import Modal from 'components/common/Modal';
import { useState } from 'react';
import Button from 'components/common/Button';
import CreateDatasourceForm from './CreateDatasourceForm';
import { DATASOURCE_TYPE } from 'utils/constants';
import credentialConfig from './credentialConfig';
import { addDatasource } from 'store/actions';
import { useDispatch } from 'react-redux';
import { TDatasource } from 'utils/model';
import _ from 'lodash';

type TProps = {};

const INITIAL_DATA = {
    title: '',
    description: '',
    datasourceType: DATASOURCE_TYPE.API,
    credentials: credentialConfig[DATASOURCE_TYPE.API]
};

const MANDATORY_QUERY_FIELDS = ['title', 'description', 'datasourceType'];
const MANDATORY_API_FIELDS = ['url'];
const MANDATORY_SQL_FIELDS = ['dbType', 'jdbcUrl', 'password', 'username'];
const MANDATORY_REMOTE_FIELDS = ['datasourceName'];
function CreateDataSource(props: TProps) {
    const [formData, setFormData] = useState<TDatasource>(INITIAL_DATA);
    const [show, setShow] = useState(false);
    const [errorObj, setErrorObj] = useState<any>({});
    const dispatch = useDispatch();

    const validate = () => {
        let isValid: boolean = true;
        const formDataTemp: any = _.cloneDeep(formData);
        const errorObjTemp = _.cloneDeep(errorObj);
        const CredentialsTemp = _.cloneDeep(formData.credentials);
        const MANDATORY_CRED=formDataTemp.datasourceType == 'API'?MANDATORY_API_FIELDS:formDataTemp.datasourceType == 'SQL'?MANDATORY_SQL_FIELDS:MANDATORY_REMOTE_FIELDS;

        for (let key in formDataTemp) {
        
            if (MANDATORY_QUERY_FIELDS.includes(key) && _.isEmpty(formDataTemp[key])) {
                errorObjTemp[key] = 'Invalid ' + key;
                isValid = false;
            } else {
                errorObjTemp[key] = '';
            }
        }

        for (let key in CredentialsTemp) {
            if(MANDATORY_CRED.includes(key) && _.isEmpty(CredentialsTemp[key]))
            {
                errorObjTemp[key] = 'Invalid ' + key;
                isValid = false;
            }
            else {
                errorObjTemp[key] = '';
            }
        }
        setErrorObj(errorObjTemp);
        return isValid;
    };

    const onSubmit = async () => {
        if (validate()) {
            await dispatch(addDatasource(formData));
            setShow(false);
            setFormData(INITIAL_DATA);
            setErrorObj({})
        }
    };
    return (
        <div className="create-datasource">
            <div className="create-datasource-btn">
            <Button
                buttonText="Create datasource"
                onClick={() => setShow(true)}
                classList={['create-btn']}
            />
            </div>
            <Modal
                show={show}
                onClose={() => setShow(false)}
                onSubmit={onSubmit}
                submitText="Save"
                title={`Create Datasource`}
                showButton={true}>
                <CreateDatasourceForm
                    formData={formData}
                    setFormData={setFormData}
                    error={errorObj}
                />
            </Modal>
        </div>
    );
}

export default CreateDataSource;
