import Navigator from 'components/common/Navigator';
import components from './NavConfig';
import { logOut,  } from 'store/actions/authentication';
import { showLoader, hideLoader } from 'store/reducers/LoaderSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  signOut} from 'store/reducers/AuthenticationSlice';
import { clearCacheAPI } from 'apis/index';
import { getRole } from 'utils/helperFunctions';
import { TComponent } from 'components/common/Navigator/types';
import { EDITOR_SCREENS } from 'utils/constants';




const Console = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

        
    const onLogout = async () => {
        try {
            dispatch(showLoader());
            await logOut();
            localStorage.removeItem('tenant');
            dispatch(signOut({}))
            navigate("../");
        } catch (e) {
            //TODO: show error during logout
        }
        finally {
            dispatch(hideLoader());
        }
    }

    const getComponents = () => {
        const validComponents = getRole() === 'VIEWER' ? components.filter((component: TComponent) => {
            return EDITOR_SCREENS.indexOf(component.hash) === -1
        }) : components;
        return validComponents;
    }

    return <Navigator components={getComponents()} basePath="/console" onLogout={onLogout} />;
};

export default Console;
