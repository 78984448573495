import { userLogin } from 'apis';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { logOut, updateAuthSessionDetails } from 'store/actions/authentication';
import { loadInitialScenarioData } from 'store/loadData';
import { setAuthStatus, updateAuthState } from 'store/reducers/AuthenticationSlice';
import { setRole } from 'utils/helperFunctions';
import { TUserDetails } from 'utils/model';
import './style.scss';

type TProps = {
    title?: string;
    children: JSX.Element;
};

const ComponentWrapper = (props: TProps) => {
    const { title, children } = props;
    const authDetails: TUserDetails = useSelector((state: any) => state.auth);
    const errorMessage = useSelector((state:any)=>state.error.message);
    const [loginError, setLoginError] = useState<string>('');
    const auth = getAuth();
    
    const getUserDetailsAndAuthenticate = async () => {
        const response = await userLogin();
        if (response.data && response.data.userRole) {
            setRole(response.data.userRole);
        }
        else{
            setLoginError('Invalid Email Id');
            logOut();
        }
    }

    useEffect(() => {
        if (authDetails.isAuthenticated) {
            loadInitialScenarioData();
        }else{
            auth.onAuthStateChanged(async (user: any) => {
                sessionStorage.setItem('photoURL',user?.photoURL);
                const updatedPayload = {
                    ...store.getState().auth,
                    authToken: user? await user.getIdToken():undefined,
                    uid: user?.uid,
                    isAuthenticated: true,
                    email: user?.email
                };
                updateAuthSessionDetails(updatedPayload);
                store.dispatch(setAuthStatus(updatedPayload));
                store.dispatch(updateAuthState(updatedPayload));
                getUserDetailsAndAuthenticate();
            });
        }
    },[authDetails.isAuthenticated]);
    return (
        <div className="component-wrapper">
             {loginError ? <div className="error login-error">{loginError}</div> : null}
            {title?<h1>{title}</h1>:null}
            {errorMessage?<div className='common-error'>{errorMessage}</div>:null}
            <div className='children'>{children}</div>
        </div>
    );
};

export default ComponentWrapper;
