import ComponentWrapper from '../ComponentWrapper';
import { useLocation, useParams } from 'react-router-dom';
import ExecuteForm from '../Execute';
import { useDispatch } from 'react-redux';
import { getReadQueryExecutionData, getScenarioDetailsByIdWrapper } from 'store/actions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InvestigationDetails from './InvestgationDetails';
import { resetQueryLoader, setQueryLoader, setScenarioDetailsLoader } from 'store/reducers/scenarioDetailsSlice';
import { resetError, setError } from 'store/reducers/ErrorSlice';
import { TQueryData } from 'utils/model';
import _ from 'lodash';
import ReferenceData from '../ReferenceData';
import { EXTRA_DS, USER_ROLES } from 'utils/constants';
import { getFilteredQueryDSMapList, getRole } from 'utils/helperFunctions';
import React from 'react';

type TProps = {
};

function ExecuteInvestigation(props: TProps) {
    const { id }: any = useParams();
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isInExecution, setIsInExecution] = useState(false);
    const [isExecutedOnce, setIsExecutedOnce] = useState(false);
    const [collapseRef,setCollapseRef] = useState(false);
    const locationData: any = useLocation();
    const scenarioDetails = useSelector((state: any) => state.scenarioDetails[id]);
    const isDataLoading = useSelector((state: any) => state.scenarioDetails.loaders[id]);
    const [isValidScenario,setIsValidScenario] = useState(true);
    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const loadScenarioDetails = async () => {
        if (id) {
            dispatch(setScenarioDetailsLoader({ id }))
            await dispatch(getScenarioDetailsByIdWrapper(id));
            setIsLoaded(true);
        }
    };

    const loadQueryData = async (executionDetails: any) => {

        try {
            dispatch(resetError());
            const checkPromiseList = scenarioDetails.checks.map((checkItem: TQueryData) => {
                if (checkItem.reportType === EXTRA_DS.EXTERNAL_URL) {
                    return null
                };
                return new Promise(async (resolve: any, reject: any) => {
                    try {
                        dispatch(setQueryLoader({ checkId: checkItem.id, scenarioId: id }));
                        const payload = {
                            checkId: checkItem.id,
                            scenarioId: id,
                            isDashboardEnabled:  locationData.state?.isDashboardEnabled,
                            proxyId: locationData.state?.isDashboardEnabled? locationData.state.selectedDashboard.proxyId:executionDetails.proxyId,
                            dashboardId:locationData.state?.isDashboardEnabled?locationData.state.selectedDashboard.id:null,
                            selectedsnapshot: locationData.state?.isDashboardEnabled?locationData.state.selectedsnapshot:null,
                            queryRequestList: 
                            !locationData.state?.isDashboardEnabled
                            ? getFilteredQueryDSMapList(checkItem.queries,executionDetails.queryDsMap)
                            : getFilteredQueryDSMapList(checkItem.queries,locationData.state.selectedDashboard.dashboardQueries)
                        }
                        await dispatch(getReadQueryExecutionData(payload))
                        dispatch(resetQueryLoader({ checkId: checkItem.id, scenarioId: id }))
                        resolve(true);
                    } catch (e) {
                        reject(e);
                    }
                })
            });
            await Promise.allSettled(checkPromiseList);
        } catch (error: any) {
            console.log(error);
            dispatch(setError(error.message ?? JSON.stringify(error)));
        }
        finally {
        }

    }

    useEffect(() => {
        loadScenarioDetails();
    }, []);

    const getExecutionDetails = () => {
        const scenarioQueries = _.filter(selectedDashboard.dashboardQueries, { scenarioId: parseInt(id) });
        if (scenarioQueries.length === 0) {
            setIsValidScenario(false);
            return;
        }
        setIsValidScenario(true);
    }

    useEffect(() => {
        if (getRole() === USER_ROLES.VIEWER && selectedDashboard) {
            getExecutionDetails();
        }
    }, [selectedDashboard])

    const onExecute = async (executionDetails: any) => {
        setIsExecutedOnce(true);
        setIsInExecution(true);
        await loadQueryData(executionDetails);
        setIsInExecution(false);
        setCollapseRef(true);
    };

    return (
        <ComponentWrapper title="Execute Investigation Scenario">
            {isLoaded && scenarioDetails ? (
                <div className='execute-investigation'>
                    {
                        isValidScenario
                            ? <React.Fragment>
                                <div className='execute-investigation-form-section'>
                                    <ExecuteForm data={scenarioDetails} onExecute={onExecute} scenarioId={id} />
                                    {
                                        isExecutedOnce
                                            ? isInExecution
                                                ? <div>Loading execution response...</div>
                                                : <InvestigationDetails
                                                    scenarioId={id}
                                                    exceptionData={locationData?.state?.refdata ?? undefined}
                                                    exceptionScenarioId={locationData?.state?.exceptionScenarioId ?? undefined}
                                                    exceptionCheckId={locationData?.state?.exceptionCheckId ?? undefined}
                                                />
                                            : null
                                    }
                                </div>
                                {locationData?.state?.refData ? <ReferenceData collapse={collapseRef} data={locationData?.state?.refData} /> : null}
                            </React.Fragment>
                            : <h2>Invalid Scenario for selected dashboard</h2>
                    }
                </div>
            ) : (
                isLoaded && !isDataLoading ? <div>Something went wrong</div> : <div>Loading...</div>
            )}
        </ComponentWrapper>
    );
}

export default ExecuteInvestigation;