import Modal from 'components/common/Modal';
import { useEffect, useState } from 'react';
import './style.scss';
import {  useSelector } from 'react-redux';
import { TQueryData, TScenarioData } from 'utils/model';
import _ from 'lodash';
import { SCENARIO_TYPES } from 'utils/constants';
import Base from './Base';
import DataExtraction from './DataExtraction';
import Confirmation from './Confirmation';
import Wizard from 'components/common/Wizard';


const INTIAL_DATA: TScenarioData = {
    title: '',
    description: '',
    linkedFixScenarioId: '',
    linkedInvestigationScenarioId: '',
    scenarioType: '',
    checks: []
};

type TProps = {
    type: SCENARIO_TYPES;
    formData: TScenarioData;
    setFormData: Function;
    onSubmit: Function;
    onClose: Function;
    show: boolean;
    title: string;
    errorObj: any;
};

const CreateorUpdate = (props: TProps) => {
    const { type, formData, setFormData, onSubmit, show, onClose, title } = props;
    const [stage, setStage] = useState(1);
    const INITIAL_DATA_TYPE: TScenarioData = { ...INTIAL_DATA, scenarioType: type };
    const datasources = useSelector((state: any) => state.datasource.datasources);
    const datasourcesNameIdList = datasources
        ? datasources.map((datasource: any) => `${datasource.title} (${datasource.id})`)
        : [];

   

    useEffect(() => {
        show && setStage(1);
    }, [show]);

    const setQueryList = (checks: TQueryData[]) => {
        const updatedData = { ...formData, checks };
        setFormData(updatedData);
    };

    const setFormDetails = (detail: any) => {
        const updatedData = { ...formData, ...detail };
        setFormData(updatedData);
        setStage(stage + 1);
    };

    const gotoPrevious = (index: number) => {
        setStage(index);
    };

    const updateStage = () => {
        setStage(1);
    };


    let stages: any[] = [
        {
            label: 'Basic Info',
            component: (
                <Base
                    title={`Create ${_.lowerCase(type)} scenario`}
                    type={type}
                    formData={formData}
                    onBaseSave={setFormDetails}
                />
            )
        },

        {
            label: ' Data Extraction Method',
            component: (
                <DataExtraction
                    queryList={formData.checks}
                    setQueryList={setQueryList}
                    datasourcesNameIdList={datasourcesNameIdList}
                    goBack={gotoPrevious}
                    type={type}
                />
            )
        },

        {
            label: 'Confirmation',
            component: (
                <Confirmation
                    data={formData}
                    onSave={onSubmit}
                    updateStage={updateStage}
                    onBack={gotoPrevious}
                />
            )
        }
    ];


    return (
        <div className="create-scenario">
            
            <Modal
                show={show}
                onClose={onClose}
                title={title}
                onSubmit={onSubmit}
                submitText={''}
                fullSize={true}
                showButton={false}>
                <div className="de-container">
                    <Wizard steps={stages} stage={stage}></Wizard>
                </div>
            </Modal>
        </div>
    );
};

export default CreateorUpdate;
