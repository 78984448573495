import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './style.scss';
import { createExcel } from 'utils/helperFunctions';
import { Copy } from 'utils/helperFunctions';
import IconButton, { BUTTON_TYPES } from '../IconButton';
import Select from '../Select';
import Button from '../Button';
import { Box, Modal, Typography } from '@mui/material';
import ShowDetails from './ShowDetails';
import _ from 'lodash';

type TProps = {
    data: Object[];
    cellClassRules?: any;
    cellClassRuleApplyAllField?: boolean;
    getRowClass?: any;
    fieldWiseCellRules?: any;
    fieldConfig?: any; //format(will get merged with existing config)- {[field_name]:{...field config}},
    copyToClipBoard?: Function;
    title: string;
    clearCacheAndRefresh?: Function;
    showTitleSection?: boolean;
    details?: JSX.Element;
    lastUpdatedTime?: string;
    enableDataChange?: boolean;
    dataChangeOptions?: any[];
    onDataChangeHandler?: Function;
    onDataChangeSelectedOption?: any;
    onSaveHandler?: React.MouseEventHandler;
    customColumns?: any[];
    loading?: boolean;
    refreshingData?: number;
    id?: number;
};

const MAX_GRID_HEIGHT = 400;
const DynamicGrid = (props: TProps) => {
    const {
        data,
        clearCacheAndRefresh,
        cellClassRules,
        getRowClass,
        cellClassRuleApplyAllField,
        fieldWiseCellRules,
        fieldConfig,
        copyToClipBoard,
        showTitleSection,
        title,
        details,
        lastUpdatedTime,
        enableDataChange = false,
        dataChangeOptions = [],
        onDataChangeHandler = () => {},
        onDataChangeSelectedOption,
        onSaveHandler,
        customColumns,
        loading,
        refreshingData,
        id
    } = props;
    const [rowData, setRowData] = useState<Object[]>([]);
    const [columnDefs, setColumnDefs] = useState<Object[]>([]);
    const gridRef: any = useRef(null);
    const agGridRef = useRef<AgGridReact<any>>(null);
    const detailsRef:React.MutableRefObject<any> = useRef(null);
    
    useEffect(() => {
        if (data) {
            let keySet = data.flatMap((item:any)=>{
                return _.keys(item);
            })
            updateColumnConfig(data[0],_.uniq(keySet));
            let clonedData = _.cloneDeep(data)
            clonedData.map((value:any)=>{
               for(let eachValue in value)
               {
                if(typeof value[eachValue]=='object')
                {
                    value[eachValue]=JSON.stringify(value[eachValue]);
                }
               }
            })
            setRowData(clonedData);
            updateTableHeight();
        }
    }, [data]);

    const updateTableHeight = () => {
        const calHeight = data.length * 30 + 40;
        const gridHeight = calHeight > MAX_GRID_HEIGHT ? MAX_GRID_HEIGHT : calHeight;
        if (gridRef.current) {
            gridRef.current.style.height = gridHeight + 'px';
        }
    };
    const hashValueGetter = (params: any) => {
        return params.node ? params.node.rowIndex + 1 : null;
    };
   
    const updateColumnConfig = (row: any,keySet:any) => {
        let fields: string[] = keySet;
        const config: Object[] = fields.map((field: string) => {
            return {
                field,
                filter: typeof row[field] == 'number' ? 'agNumberColumnFilter' : true,
                ...(cellClassRuleApplyAllField && cellClassRules
                    ? { cellClassRules }
                    : fieldWiseCellRules
                    ? { cellClassRules: fieldWiseCellRules[field] }
                    : {}),
                ...(fieldConfig && fieldConfig[field] ? fieldConfig[field] : {}),
                minWidth: 120
            };
        });
        
        const indexConfig = {
            field: '',
            valueGetter: hashValueGetter,
            width: 60,
            pinned: true,
            cellClassRules: { index: (params: any) => true },
            headerClass: 'index-header'
        };

        const customConfig = customColumns
            ? customColumns.map((fieldItem) => {
                  return {
                      field: fieldItem.field,
                      maxWidth: 120,
                      pinned: 'right',
                      cellRenderer: fieldItem.cellRenderer,
                      cellRendererParams: fieldItem.cellRendererParams,
                      autoHeight: true,
                      wrapText: true
                  };
              })
            : [];

            setColumnDefs([indexConfig, ...config, ...customConfig]);    
    };

    const onExportCsv = useCallback(() => {
        createExcel(data, title);
        // agGridRef.current!.api.exportDataAsCsv();
    }, []);

    const onCopyToClipBoard = async () => {
        let response = await Copy(data);
        copyToClipBoard && copyToClipBoard(response);
    };

    return (
        <div className="dynamic-grid-container">
            {/* {loading && refreshingData == id && (
                <div className="transparent-div">Loading...</div>
            )} */}
            <div className="head-section">
                <div className="title-section">
                    {showTitleSection ? (
                        <p>
                            {title}
                            <span>
                                {lastUpdatedTime ? ` ( Last updated: ${lastUpdatedTime} )` : ''}
                            </span>
                        </p>
                    ) : null}{' '}
                </div>
                {onSaveHandler ? (
                    <Button
                        buttonText="save snapshot"
                        onClick={onSaveHandler}
                        classList={['save-snapshot']}
                    />
                ) : null}
                {enableDataChange ? (
                    <div className="select-data-container">
                        <Select
                            options={dataChangeOptions}
                            value={onDataChangeSelectedOption}
                            onChangeHandler={onDataChangeHandler}
                            classList={['select-data']}
                        />
                    </div>
                ) : null}
                <div className="controller">
                    <IconButton type={BUTTON_TYPES.COPY} onClick={onCopyToClipBoard} />
                    <IconButton type={BUTTON_TYPES.DOWNLOAD} onClick={onExportCsv} />
                    {clearCacheAndRefresh ? (
                        <IconButton type={BUTTON_TYPES.REFRESH} onClick={clearCacheAndRefresh} />
                    ) : null}
                     { details ?<ShowDetails detailsRef={detailsRef}/>: null}
                </div>
            </div>
            <div className="inner-content">
                {/* details-section */}

                <div ref={detailsRef} className="details-section">{details ?? null}</div>
                {loading && refreshingData == id && (
                        <div className='loading'>Loading...</div>
                    )}
                <div ref={gridRef} className={`${loading && refreshingData == id?'blur-theme dynamic-grid':'dynamic-grid'} ag-theme-alpine`}>
                {/* {loading && refreshingData == id && (
                        <div className='loading'>Loading...</div>
                    )} */}
                   { rowData.length==1 && rowData[0]==''?<p className='empty-d-grid'>Empty data</p>
                        : rowData.length? <AgGridReact
                        ref={agGridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onGridReady={(event) => {
                            event.api.setHeaderHeight(30);
                        }}
                        getRowClass={getRowClass}
                        enableCellTextSelection={true}
                        rowHeight={35}
                        defaultColDef={{
                            resizable: true
                        }}
                        rowSelection={'multiple'}
                    /> : <p className='empty-d-grid'>No rows to show</p>}
                </div>
            </div>
        </div>
    );
};

export default DynamicGrid;
