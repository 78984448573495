import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import './style.scss';

type TProps = {
    data: any;
    collapse: boolean;
}
const ReferenceData = ({ data, collapse }: TProps) => {
    const [isRefDataCollapsed, setIsRefDataCollapsed] = useState(false);
    const referenceDataContainerRef: any = useRef(null);
    useEffect(()=>{
        if(collapse){
            collapseReferenceData();
        }else{
            expandReferenceData();
        }
    },[collapse])
    const collapseReferenceData = () => {
        if (referenceDataContainerRef.current) {
            referenceDataContainerRef.current.style.width = '4%';
            setIsRefDataCollapsed(true);
        }
    }
    const expandReferenceData = () => {
        if (referenceDataContainerRef.current) {
            referenceDataContainerRef.current.style.width = '20%';
            setIsRefDataCollapsed(false);
        }
    }

    return !_.isEmpty(data)?<div className='reference-data' ref={referenceDataContainerRef}>
        {!isRefDataCollapsed ? <div>
            <div className='close' onClick={collapseReferenceData}><AiOutlineDoubleRight /></div>
            <h3>Reference</h3>
            {
                _.keys(data)?.map((key: string) => {
                    return <div className="key-value">
                        <div className="key">{_.startCase(key)}:</div>
                        <div className="value">{typeof data[key] === 'string' || typeof data[key] === 'number' ? data[key] : JSON.stringify(data[key])}</div>
                    </div>
                })
            }
        </div> : <div onClick={expandReferenceData} className="collapsed-container">
            <AiOutlineDoubleLeft/>
            <div className='collapse-text'>Reference data</div>
        </div>}
    </div>:null
}

export default ReferenceData