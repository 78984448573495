import './style.scss';

type TProps = {
    message: string;
    icon?: JSX.Element;
    type: CARDTYPES;
    label?: string|JSX.Element;
    required?: boolean;
};

const InfoCard = (props: TProps) => {
    const { message, icon, type = CARDTYPES.INFO, label , required} = props;
    return (
        <div className={`info-tag`}>
          <span className={` ${required?"required":''}`}>{label}</span>  
            <div className={`${type}`}>
                {icon} &nbsp;
                {message}
            </div>
        </div>
    );
};

export enum CARDTYPES {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'info-error'
}

export default InfoCard;
