import { BiLoader } from 'react-icons/bi';
import './style.scss';

type TProps = {
    show: boolean;
};

const Loader = (props: TProps) => {
    const { show } = props;
    return show ? <div className="loader"><BiLoader className='spinner' size={20}/>Loading</div> : null;
};

export default Loader;
