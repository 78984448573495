import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getConsoleDashboardWrapper, getDashboardListWrapper } from 'store/actions';
import './style.scss';
import HeaderSection from './HeaderSection';
import EmptyDashboard from './EmptyDashboard';
import DashboardDisplay from './DashboardDisplay';
import React from 'react';
import { setReloadRequired, setSelectedDashboard } from 'store/reducers/consoleDashboardSlice';
import TabularDisplay from './TabularDisplay';
import { BiTable } from 'react-icons/bi';
import Toggle from 'components/common/Toggle';
import { BsCardList } from 'react-icons/bs';
import { getRole } from 'utils/helperFunctions';

const ConsoleDashboard = () => {
    const dashboardList = useSelector((state: any) => state.consoleDashboard.dashboardList);
    const dataLoaded = useSelector((state: any) => state.consoleDashboard.data);
    const isDashboardListLoaded = useSelector(
        (state: any) => state.consoleDashboard.isDashboardListLoaded
    );
    const selectedDashboard = useSelector((state: any) => state.consoleDashboard.selectedDashboard);
    const isReloadRequired = useSelector((state:any) => state.consoleDashboard.reloadRequired);
    const [isInitialDBDataLoaded, setIsInitialDBDataLoaded] = useState(false);
    const [view, setView] = useState(true);
    const userRole = getRole();
    const dispatch = useDispatch();


    const loadInitialDBData = async () => {
            if ((!isInitialDBDataLoaded && dashboardList.length) && !isReloadRequired ) {
                _.isEmpty(selectedDashboard) &&  dispatch(setSelectedDashboard(dashboardList[0]));
                await dispatch(
                    getConsoleDashboardWrapper({ dashboardId: selectedDashboard?.id, refresh: false })
                );
                setIsInitialDBDataLoaded(true);
            }
            else if(isReloadRequired)
            {
                _.isEmpty(selectedDashboard) &&  dispatch(setSelectedDashboard(dashboardList[0]))
                          await dispatch(
                            getConsoleDashboardWrapper({ dashboardId: selectedDashboard?selectedDashboard.id:dashboardList[0].id, refresh: false })
                        );
                        isReloadRequired && dispatch(setReloadRequired(false));
                        setIsInitialDBDataLoaded(true);
            }
        };

useEffect(() => {
        loadInitialDBData();
    }, [dashboardList,isReloadRequired]);


    const onDashboardChange = async (dashboard: any) => {
        try {
           
            dispatch(setReloadRequired(true));
            dispatch(setSelectedDashboard(dashboard));
        } catch (e) {}
    };

    const changeView = (index: number) => {
        if (index == 0) {
            setView(true);
        } else {
            setView(false);
        }
    };
    const data = [
        <BsCardList color={view?'#FFF':'#AEAEAE'} title={'Tab View'} />,
        <BiTable color={!view?'#FFF':'#AEAEAE'} title={'Tabular View'} />
    ];
    return (
        <div className="console-dashboard">
            {!isDashboardListLoaded ? (
                <div>Loading...</div>
            ) : dashboardList?.length === 0 ? (
                <EmptyDashboard />
            ) : (
                <div className="header-section">
                    <HeaderSection
                        selectedDashboard={selectedDashboard}
                        onDashboardChange={onDashboardChange}
                    />
                    <div className="toggle-component">
                        <Toggle data={data} changeView={changeView} />
                    </div>
                </div>
            )}

            {selectedDashboard && view && dataLoaded ? <DashboardDisplay /> : !view ? <TabularDisplay />: null}
        </div>
    );
};

export default ConsoleDashboard;
