import Button, { TYPES } from 'components/common/Button';
import InputField, { FIELD_TYPES } from 'components/common/InputField';
import Select, { FIELD_TYPES as SELECT_FIELD_TYPES } from 'components/common/Select';
import { useEffect, useState } from 'react';
import { COLOR_SCHEMA_TYPE, COLOR_CLASSES, PIN_OPTIONS } from 'utils/constants';
import { TValueColorSchema, TColorConfig, TRange } from 'utils/model';
import _ from 'lodash';
import ValueBasedColorSelector from './ValueBasedColorSelector';
import RangeCollector from './RangeCollector';

type TProps = {
    data?: TColorConfig;
    index?: number;
    onSubmit: Function;
};
const INITIAL_COLOR_CONFIG: TColorConfig = {
    columnName: '',
    type: '',
    colorClass: '',
    colorScheme: [],
    pinned: PIN_OPTIONS.DISABLE,
    range: {
        startValue: 0,
        endValue: 0
    }
};

const MANDATORY_FIELDS = ['type', 'columnName', 'colorClass', 'pinned'];
const CreateColorScheme = (props: TProps) => {
    const { data, index, onSubmit } = props;

    const [errorObj, setErrorObj] = useState<any>({});
    const [formData, setFormData] = useState<TColorConfig>({ ...INITIAL_COLOR_CONFIG });

    useEffect(() => {
        if (data) {
            setFormData(data);
        }
    }, []);

    const onFormDataChange = (
        key: keyof TColorConfig,
        value: string | TValueColorSchema[] | TRange
    ) => {
        const updatedFormData: any = _.cloneDeep(formData);
        updatedFormData[key] = value;
        if (key === 'type') {
            updatedFormData.colorScheme = [];
            if (value === COLOR_SCHEMA_TYPE.COLUMN_RANGE) {
                updatedFormData.range = {
                    startValue: 0,
                    endValue: 0
                };
            }
        }
        setFormData(updatedFormData);
    };

    const validate = (updatedFormData: TColorConfig) => {
        let isValid: boolean = true;
        const formDataTemp: any = _.cloneDeep(updatedFormData);
        const errorObjTemp: any = _.cloneDeep(errorObj);
        for (let key in formDataTemp) {
            if (MANDATORY_FIELDS.includes(key) && _.isEmpty(formDataTemp[key])) {
                errorObjTemp[key] = 'Invalid ' + key;
                isValid = false;
            } else {
                errorObjTemp[key] = '';
            }
        }
        setErrorObj(errorObjTemp);
        return isValid;
    };
    const onAddOrUpdateSchema = () => {
        const updatedFormData: TColorConfig = { ...formData };
        updatedFormData.colorScheme.pop();
        if (validate(updatedFormData)) {
            onSubmit(updatedFormData, index);
            setFormData({ ...INITIAL_COLOR_CONFIG });
        }
    };

    return (
        <div className="create-color-scheme">
            <div className="basic-config">
                <InputField
                    label="Field"
                    value={formData.columnName}
                    onChangeHandler={(e: any) => onFormDataChange('columnName', e.target.value)}
                    fieldType={FIELD_TYPES.TYPE_1}
                    error={errorObj.columnName}
                    type={'text'}
                    required={true}
                    classList={['field-color']}
                />
                <Select
                    label="Type"
                    value={formData.type}
                    onChangeHandler={(e: any, value: string) => onFormDataChange('type', value)}
                    options={Object.values(COLOR_SCHEMA_TYPE)}
                    fieldType={SELECT_FIELD_TYPES.TYPE_1}
                    error={errorObj.type}
                    required={true}
                />
                <Select
                    label="Color"
                    value={formData.colorClass}
                    onChangeHandler={(e: any, value: string) =>
                        onFormDataChange('colorClass', value)
                    }
                    options={COLOR_CLASSES}
                    fieldType={SELECT_FIELD_TYPES.TYPE_1}
                    error={errorObj.colorClass}
                    required={true}
                />
                <Select
                    label="Pinned"
                    value={formData.pinned}
                    onChangeHandler={(e: any, value: string) => onFormDataChange('pinned', value)}
                    options={_.values(PIN_OPTIONS)}
                    fieldType={SELECT_FIELD_TYPES.TYPE_1}
                    error={errorObj.pinned}
                    required={true}
                />
            </div>
            {formData.type === COLOR_SCHEMA_TYPE.VALUE ? (
                <ValueBasedColorSelector
                    list={formData.colorScheme}
                    setList={(value: TValueColorSchema[]) => onFormDataChange('colorScheme', value)}
                />
            ) : formData.type === COLOR_SCHEMA_TYPE.COLUMN_RANGE ? (
                <RangeCollector
                    rangeData={formData.range}
                    onChange={(value: TRange) => onFormDataChange('range', value)}
                />
            ) : null}
            <Button
                buttonText="Save Color Schema"
                onClick={onAddOrUpdateSchema}
                type={TYPES.PRIMARY_OUTLINED}
                classList={['add-config-btn']}
            />
        </div>
    );
};

export default CreateColorScheme;
