import InputField, { FIELD_TYPES } from 'components/common/InputField';
import Modal from 'components/common/Modal';
import { useEffect, useState } from 'react';
import './style.scss'

type TProps = {
    show: boolean;
    onClose: Function;
    onExecute: Function;
    name?:string;
};

const ConfirmDashboardDelete = (props: TProps) => {
    const { show, onClose, onExecute, name } = props;
    const [answer, setAnswer] = useState('');
    const [submit, setSubmit] = useState(false);
    useEffect(()=>{
        setAnswer('');
        setSubmit(false);
    },[show])

    return (
        <Modal
            title="Delete Dashboard"
            show={show}
            onClose={onClose}
            onSubmit={() => {
                setSubmit(true);
                parseInt(answer) == 10 && onExecute();
            }}
            submitText={'Delete'}
            showButton={true}>
            <div className="execute-delete">
                <p>{`Are you sure to delete ${name} ?`}</p>
                 <InputField
                    classList={['confirm-Input']}
                    label="5 + 5 = "
                    value={answer}
                    onChangeHandler={(e) => {
                        setAnswer(e.target.value);
                    }}
                    type={'text'}
                    fieldType={FIELD_TYPES.TYPE_2}
                    required={true}
                />
                {submit && parseInt(answer) != 10 ? <span>Invalid Value</span> : <div />}
            </div>
        </Modal>
    );
};

export default ConfirmDashboardDelete;
