import './style.scss';
import _ from 'lodash';
import { Autocomplete, Chip, TextField } from '@mui/material';

type TOption = {
    label: string;
    value: any;
    id: number;
};

type TProps = {
    label?: string|JSX.Element;
    onChangeHandler: any;
    value: any;
    options: any;
    classList?: string[];
    fieldType?: FIELD_TYPES;
    error?: string;
    placeholder?: string;
    multiple?: boolean;
    width?: string;
    required?: Boolean;
    getOptionLabel?: any;
    disabled?:boolean;
};

const Select = (props: TProps) => {
    const {
        options,
        label,
        classList,
        onChangeHandler,
        value,
        fieldType,
        error,
        placeholder,
        multiple,
        width,
        required,
        getOptionLabel,
        disabled
    } = props;
    return (
        <div
            className={`select-wrapper ${classList?.join(' ')} ${fieldType}`}
            style={{ width: width }}>
            {label ? (
                <label className={`label ${required ? 'required' : ''}`}>{label}</label>
            ) : null}
            <div className='inner-container'>
                
                <Autocomplete
                    multiple={multiple}
                    id="custom-input-demo"
                    options={options}
                    className={`input-container ${disabled?'disabled':''}`}
                    onChange={onChangeHandler}
                    getOptionLabel={getOptionLabel ? (option) => option[getOptionLabel] == undefined ? '' : option[getOptionLabel] : undefined}
                    value={value}
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    disabled={disabled}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="filled"
                            className={`input ${error ? 'has-error' : ''}`}
                            disabled={disabled}
                        />
                    )}
                />
                {error ? <small className="error">{error}</small> : null}
            </div>
        </div>
    );
};

export enum FIELD_TYPES {
    TYPE_1 = 'type1',
    TYPE_2 = 'type2'
}

export default Select;
